import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { SharingHoverableItem } from '../field/SharingHoverableItem.tsx'
import Iconify from '../../../Iconify.tsx'
import { AssetExternalShare } from '../../../../@types/asset-external-share.ts'
import { alpha, useTheme } from '@mui/material/styles'
import { useBaseUrls } from '../../../../hooks/useBootstrap.ts'
import { useSnackbar } from 'notistack'
import { useDeleteAssetExternalShare } from '../../../../hooks/useAssetExternalShares.ts'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { getPasswordForAssetExternalShare } from '../../../../clients/AssetExternalShareClient.ts'
import { fDateTime } from '../../../../utils/formatTime.ts'
import { AnimatePresence, motion } from 'framer-motion'
import { EditAssetExternalShareForm } from './EditAssetExternalShareForm.tsx'

export function ExternalShareItem({ share }: { share: AssetExternalShare }) {
  const theme = useTheme()
  const [editEnabled, setEditEnabled] = useState<boolean>(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { sharingBaseUrl } = useBaseUrls()
  const { mutateAsync: deleteExternalShare, isLoading } = useDeleteAssetExternalShare()

  // handlers
  const handleEdit = () => setEditEnabled((prev) => !prev)
  const handleShareUpdateCancelled = () => setEditEnabled(false)
  const handleShareUpdated = () => setEditEnabled(false)
  const handleRemove = () => setConfirmOpen(true)
  const handleRemoveConfirm = () => {
    deleteExternalShare({
      assetId: share.assetId,
      externalShareId: share.externalShareId,
    }).then(() => setConfirmOpen(false))
  }

  const handleLinkCopy = () => {
    const link = `${sharingBaseUrl}/cr/${share.shortCode}/a/${share.assetShortCode}`
    window.navigator.clipboard.writeText(link).then(() => {
      enqueueSnackbar((
        <Typography variant='standard'>Link copied!</Typography>
      ), { autoHideDuration: 2000 })
    })
  }

  const handlePasswordCopy = () => {
    getPasswordForAssetExternalShare(share.assetId, share.externalShareId).then(data => {
      window.navigator.clipboard.writeText(data.password || "").then(() => {
        enqueueSnackbar((
          <Typography variant='standard'>Password copied!</Typography>
        ), { autoHideDuration: 2000 })
      })
    })

  }

  return (
    <SharingHoverableItem sx={{
      // this matches the hover
      background: editEnabled ? alpha(theme.palette.bg.warmGreyLight, .025) : null,
    }}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Stack direction='column'>
            <Typography variant='h3'>{share.name}</Typography>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography variant='caption' color='text.deemphasized'>{fDateTime(share.createdAt)}</Typography>
            </Stack>

          </Stack>
        </Stack>
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
        >
          {share?.isPasswordProtected && (
            <Tooltip title={'Copy Password'}>
              <IconButton
                size='small'
                onClick={handlePasswordCopy}
                disabled={!share?.isPasswordProtected}
              >
                <Iconify icon={'ph:lock'} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title='Copy link'>
            <IconButton
              size='small'
              onClick={handleLinkCopy}
            >
              <Iconify
                icon='ph:link-simple-horizontal'

              />
            </IconButton>
          </Tooltip>

          <Tooltip title='Edit'>
            <IconButton
              size='small'
              onClick={handleEdit}
            >
              <Iconify
                icon='ph:pencil'
              />
            </IconButton>
          </Tooltip>

          <Tooltip title='Delete share'>
            <IconButton
              size='small'
              onClick={handleRemove}
            >
              <Iconify
                icon='ph:x'
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <AnimatePresence>
        {editEnabled && (
          <motion.div
            key='create-asset-content-room'
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: .2 }}
          >
            <Box sx={{mt: 2}}>
              <EditAssetExternalShareForm
                share={share}
                onCancel={handleShareUpdateCancelled}
                onUpdated={handleShareUpdated}
              />
            </Box>
          </motion.div>
        )}
      </AnimatePresence>

      <Dialog
        open={confirmOpen}
        maxWidth='xs'
        fullWidth={true}
        onClose={() => setConfirmOpen(false)}
      >
        <DialogTitle>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setConfirmOpen(false)}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Typography variant='h2' paragraph>Are you sure?</Typography>

        </DialogTitle>

        <DialogContent>
          <Typography variant='standard' mb={4} component='div'>
            If you delete this external share, users will no longer be able to
            access content using the associated link.
          </Typography>
          <Stack spacing={2}>
            <LoadingButton
              loadingPosition='start'
              loading={isLoading}
              fullWidth={true}
              variant='contained'
              color='error'
              size='large'
              onClick={handleRemoveConfirm}
            >
              Delete
            </LoadingButton>
            <Button
              fullWidth={true}
              color="inherit"
              variant='outlined'
              size='large'
              onClick={() => {
                setConfirmOpen(false)
              }}
            >
              Cancel
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </SharingHoverableItem>
  )
}