import SearchOptionsSidebar from '../components/asset-search/SearchOptionsSidebar'
import { SearchProvider } from '../contexts/SearchContext'
import SearchResults from '../components/asset-search/SearchResults'
import { Box } from '@mui/material'
import Page from '../components/Page'
import { SearchSelectedAssetsContextProvider } from '../components/asset-search/SearchSelectedAssetsContext.tsx'
import { SearchBulkActionBar } from '../components/asset-search/SearchBulkActionBar.tsx'

export default function AssetSearch() {
  return (
    <Page title='Search'>
      <SearchSelectedAssetsContextProvider>
        <SearchProvider>
          <Box sx={{ display: 'flex', height: 1 }}>
            <SearchOptionsSidebar />
            <SearchResults />
          </Box>
          <SearchBulkActionBar />
        </SearchProvider>
      </SearchSelectedAssetsContextProvider>
    </Page>
  )
}