import { useState } from 'react';
import * as React from 'react';
import { Button, Popover, Stack, Typography } from '@mui/material'
import Iconify from '../Iconify'
import { CategoryValueFilterMode } from '../../@types/analytics/analytics-asset'
import { TagPickerMenu } from './TagPickerMenu'

interface TagPickerProps {
  initialSelectedIds: string[]
  initialMode: CategoryValueFilterMode,
  onChange?: (categoryValueIds: string[], filterMode: CategoryValueFilterMode) => void
}

export function TagPicker({ initialSelectedIds, initialMode, onChange }: TagPickerProps) {
  // state
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  // handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleApply = (ids: string[], mode: CategoryValueFilterMode) => {
    handleClose()
    onChange && onChange(ids, mode)
  }

  const renderLabel = () => {
    const numItems = initialSelectedIds.length
    return numItems ? `${numItems} tag(s)` : 'Filter By Tag'
  }

  // calculated props
  const open = Boolean(anchorEl)
  const id = open ? 'tag-picker' : undefined

  return (
    <div>
      <Button
        color='inherit'
        disableRipple
        variant='text'
        onClick={handleClick}
        sx={{
          '& .MuiButton-endIcon': { ml: 0 },
          '& .MuiButton-startIcon': { ml: .5 },
        }}
        startIcon={
          <Iconify icon={'eva:pricetags-outline'} />
        }
        endIcon={
          <Iconify
            color='primary.main'
            icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          />
        }
      >
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography sx={{ color: 'primary.main' }} textTransform='none' variant='smallHighlight'>
            {renderLabel()}
          </Typography>
        </Stack>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: -4, horizontal: 0 }}
      >
        <TagPickerMenu
          initialSelectedIds={initialSelectedIds}
          initialMode={initialMode}
          onCancel={handleClose}
          onApply={handleApply}
        />
      </Popover>
    </div>
  )
}

