import { Task } from '../../../@types/task.ts'
import { alpha, useTheme } from '@mui/material/styles'
import Iconify from '../../Iconify.tsx'


export function CheckButton({ task, onClick }: { task: Task, onClick: () => void }) {
  const theme = useTheme()

  const handleOnClick = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation()
    onClick()
  }

  const isComplete = task.isClosed
  const checkIcon = isComplete ? 'ph:check-circle-fill' : 'ph:check-circle'
  const iconColor = isComplete ? theme.palette.success.main : 'inherit'
  return (
    <Iconify
      icon={checkIcon}
      height={20}
      width={20}
      onClick={handleOnClick}
      sx={{
        cursor: 'pointer',
        color: iconColor,
        '&:hover': {
          color: isComplete ? theme.palette.success.light : alpha(theme.palette.success.main, .8),
          background: isComplete ? 'transparent' : alpha(theme.palette.success.main, .15),
          borderRadius: '50%',
        },
      }}
    />
  )
}