import { useContext, useState } from 'react'
import { Button, MenuItem, Typography } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import MenuPopover from '../../MenuPopover.tsx'
import { ContentAnalysisFilterContext } from '../../../contexts/ContentAnalysisFilterContext.tsx'

export function HasFileAccessFilterDropdown() {
  const [open, setOpen] = useState<HTMLButtonElement | null>(null)
  const { hasFileAccess, setHasFileAccess } = useContext(ContentAnalysisFilterContext)

  const handleOpen = (currentTarget: HTMLButtonElement) => {
    setOpen(currentTarget)
  }

  const handleClose = () => setOpen(null)

  const handleValueSelected = (value: boolean | null) => {
    handleClose()
    setHasFileAccess(value, false)
  }

  return (
    <>
      <Button
        color='inherit'
        disableRipple
        variant='text'
        size="small"
        onClick={(e) => handleOpen(e.currentTarget)}
        sx={{ '& .MuiButton-endIcon': { ml: 0 } }}
        endIcon={
          <Iconify
            color='primary.main'
            icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          />
        }
      >
        <Typography component='span' variant='small' sx={{ color: 'text.deemphasized', mr: 1 }}>
          Has File Access:
        </Typography>
        <Typography component='span' variant='smallHighlight' sx={{ color: 'primary.main' }}>
          {renderLabel(hasFileAccess)}
        </Typography>
      </Button>

      <MenuPopover
        anchorEl={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(open)}
        onClose={handleClose}
        sx={{
          width: 'auto',
          '& .MuiMenuItem-root': { typography: 'body2', borderRadius: 0.75 },
        }}
      >
        {HAS_FILE_ACCESS_OPTIONS.map((option, idx) => (
          <MenuItem
            key={idx}
            selected={option.value === hasFileAccess}
            onClick={() => handleValueSelected(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  )
}

function renderLabel(hasFileAccess: boolean | null) {
  return HAS_FILE_ACCESS_OPTIONS.find(it => it.value === hasFileAccess)?.label
}


const HAS_FILE_ACCESS_OPTIONS = [
  { value: null, label: 'All' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]