import { Box, IconButton, Stack } from '@mui/material'
import { CompleteTaskButton } from './CompleteTaskButton.tsx'
import Iconify from '../../Iconify.tsx'
import { Task } from '../../../@types/task.ts'
import { useTheme } from '@mui/material/styles'
import { useTaskTableContext } from '../list/TaskTableContext.tsx'
import { useDeleteTask } from '../../../hooks/useTasks.ts'

export function TaskDetailsDrawerHeader({task} : {task?: Task}){
  // hooks
  const theme = useTheme()
  const { setDetailsTaskId } = useTaskTableContext()
  const { mutateAsync: deleteTask } = useDeleteTask()

  // handlers
  const handleDelete = () => {
    // we close first just so that we don't get a request for the task after we delete it
    // it should be quick enough that there isn't a perceptible difference between the two.
    // if it becomes a problem, we can do an eager remove from the queryClient data
    handleClose()
    deleteTask({taskId: task!!.taskId})
  }
  const handleClose = () => setDetailsTaskId(null)

  return (
    <Box>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        px={3}
        sx={{
          height: 64,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <CompleteTaskButton task={task} />
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
        >
          <IconButton
            size='small'
            onClick={handleDelete}
          >
            <Iconify icon='ph:trash' />
          </IconButton>
          <IconButton
            size='small'
            onClick={handleClose}
          >
            <Iconify icon='ph:x' />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  )
}