import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Scrollbar from "../components/Scrollbar";
import { styled } from "@mui/material/styles";
import { useContext } from "react";
import { AssetUploadContext } from "../contexts/AssetUploadContext";
import Page from "../components/Page";
import { useNavigate } from "react-router-dom";
import AssetUploadPreview from "../components/AssetUploadPreview";
import { useSnackbar } from "notistack";
import { AssetUpdateFormData, AssetUpdateFormProvider } from "../components/asset/form/AssetUpdateFormProvider";
import AssetUpdateForm from "../components/asset/form/AssetUpdateForm";
import AssetPalettes from "../components/asset-palette/AssetPalettes";
import AssetLabelsCmp from '../components/asset-labels/AssetLabels'
import { useUpdateAssetShares } from '../hooks/useSharing'
import Iconify from '../components/Iconify.tsx'

const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

export default function AssetUploadFinalizeSingle() {
  const navigate = useNavigate();
  const { finalizeAsset, retryTasks } = useContext(AssetUploadContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { uploads } = useContext(AssetUploadContext)
  const uploadUnderEdit = uploads[0]
  const assetUnderEdit = uploadUnderEdit.asset

  const onCancel = async () => navigate("/assets/upload")
  const onSubmit = async (data: AssetUpdateFormData) => {
    if (assetUnderEdit === null) return

    // update with the latest metadata to finalize the asset
    await finalizeAsset(assetUnderEdit.assetId, {
      ...data,
      assetType: assetUnderEdit.assetType, // not changeable yet
    })

    enqueueSnackbar((<>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="smallHighlight">HOORAY!</Typography>
        <Typography component="div" variant="small" noWrap>Your asset has been uploaded!</Typography>
        <Stack spacing={1} direction="row">
          <Button size="small" variant="contained" onClick={() => {
            navigate(`/assets/detail/${assetUnderEdit.assetId}`)
            closeSnackbar()
          }}>View Asset</Button>
        </Stack>
        <Stack sx={{cursor: 'pointer'}} onClick={() => closeSnackbar()}><Iconify icon="eva:close-fill"/></Stack>
      </Stack>
    </>), { autoHideDuration: 5000 })
    navigate("/assets/upload")
  }

  return (
    <AssetUpdateFormProvider assetUnderEdit={assetUnderEdit}>
      <Page title="Asset Upload" mb={12}>
        <RootStyle>
          <Scrollbar sx={{ flexGrow: 1 }}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6"> Preview </Typography>
                <AssetUploadPreview upload={uploadUnderEdit} compact={false}/>
                <Box mt={2}>
                  <AssetLabelsCmp asset={uploadUnderEdit.asset} />
                </Box>
                <Box mt={2}>
                  <AssetPalettes asset={uploadUnderEdit.asset} />
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <AssetUpdateForm
                  assetUnderEdit={assetUnderEdit}
                  onCancel={onCancel}
                  onSubmit={onSubmit}
                />
              </Grid>
            </Grid>
          </Scrollbar>
        </RootStyle>
      </Page>
    </AssetUpdateFormProvider>
  )
}