import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import keyBy from 'lodash/keyBy'
import {
  createGroup,
  deleteGroup,
  getGroup,
  getGroupAssetShares,
  getGroupListItems,
  getGroups,
  updateGroup,
} from '../clients/GroupClient'
import { Group, GroupListItem, UpdateGroupRequest } from '../@types/group'

export const DEFAULT_GROUPS_DATA = { groups: [] as Group[], groupsById: {} as { [key: string]: Group } }
export const DEFAULT_GROUP_LIST_ITEMS_DATA = {
  groups: [] as GroupListItem[],
  groupsById: {} as { [key: string]: GroupListItem },
}


export function useGroups() {
  return useQuery({
    queryKey: ['groups'],
    queryFn: async () => getGroups(),
    select: data => ({
      groups: data.groups || [],
      groupsById: keyBy(data.groups, 'groupId') || {},
    }),
  })
}

export function useGroupsList(enabled: boolean = true) {
  return useQuery({
    enabled: enabled,
    queryKey: ['group-list-items'],
    queryFn: async () => getGroupListItems(),
    select: data => ({
      groups: data.groups || [],
      groupsById: keyBy(data.groups, 'groupId') || {},
    }),
  })
}

export function useGroup(groupId?: string) {
  return useQuery({
    enabled: !!groupId,
    queryKey: ['groups', groupId],
    queryFn: async () => getGroup(groupId!!),
  })
}

export function useGroupAssetShares(groupId: string, enabled: boolean) {
  return useQuery({
    enabled: enabled,
    queryKey: ['groups', groupId, 'sharing', 'assets'],
    queryFn: async () => getGroupAssetShares(groupId),
  })
}


type GroupDeleteParams = {
  groupId: string
}

export function useGroupDeleteMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ groupId }: GroupDeleteParams) => deleteGroup(groupId),
    onSuccess: async _ => {
      queryClient.invalidateQueries({ queryKey: ['groups'] })
      queryClient.invalidateQueries({ queryKey: ['group-list-items'] })
    },
  })
}

interface UpsertGroupDto extends UpdateGroupRequest {
  groupId?: string | null
}

export function useUpsertGroupMutation() {
  return useMutation({
    mutationFn: async (params: UpsertGroupDto) => {
      const { groupId, ...dto } = params
      return groupId ? updateGroup(groupId, dto) : createGroup(dto)
    },
  })
}