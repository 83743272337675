import { useContext, MouseEvent } from 'react'
import { SearchContext, SearchViewMode } from '../../contexts/SearchContext.tsx'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import Iconify from '../Iconify.tsx'

export function SearchViewModeSelector() {
  const { viewMode, setViewMode } = useContext(SearchContext)
  const handleViewChange = (_: MouseEvent<HTMLElement>, viewMode: SearchViewMode) => {
    if(viewMode != null){
      setViewMode(viewMode)
    }
  }
  return (
    <ToggleButtonGroup
      size='small'
      value={viewMode}
      exclusive={true}
      onChange={handleViewChange}
    >
      <ToggleButton
        value={SearchViewMode.LIST}
      >
        <Iconify icon='ph:list-bullets' />
      </ToggleButton>
      <ToggleButton
        value={SearchViewMode.CARDS}
      >
        <Iconify icon='ph:squares-four' />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}