import { OAUTH_PROVIDER_NAMES, OauthProvider } from '../../../@types/oauth.ts'
import { useQueryClient } from '@tanstack/react-query'
import { useOauthPopup } from '../../../hooks/useOauth.ts'
import { Button } from '@mui/material'

export function ConnectAccountButton({provider}: {provider: OauthProvider}){
  const providerName = OAUTH_PROVIDER_NAMES[provider] || ''
  const queryClient = useQueryClient()

  const handleOauthSuccess = (_: string) => {
    queryClient.invalidateQueries(['accounts'])
  }
  const { trigger } = useOauthPopup({ onSuccess: handleOauthSuccess })
  const handleConnectClick = () => trigger(provider)
  return (
    <Button
      variant='outlined'
      size='small'
      onClick={handleConnectClick}
      color='primary'
    >Connect your {providerName} account</Button>
  )
}