import { Box, Divider, IconButton, Stack, Typography } from '@mui/material'
import { Fragment, useMemo } from 'react'
import AssetCategory from './AssetCategory'
import NewCategoryDialog from './NewCategoryDialog'
import LoadingScreen from '../../components/LoadingScreen'
import { Category } from '../../@types/category'
import { useSelectedCategoryValues } from './hooks'
import { useCategories } from '../../hooks/useCategories'
import { useLocalStorage } from 'usehooks-ts'
import {
  CategorySortButton,
  PREFS_GLOBAL_CATEGORIES_SORT_KEY,
} from '../../components/admin/category/CategorySortButton.tsx'
import { CategorySort } from '../../contexts/SearchContext.tsx'
import { orderCategories } from '../../utils/category.ts'

type UpdateAssetTagsProps = {
  subtext?: string
}

export default function UpdateAssetTags({ subtext }: UpdateAssetTagsProps) {
  const [categorySort] = useLocalStorage(PREFS_GLOBAL_CATEGORIES_SORT_KEY, CategorySort.USAGE)
  const { data: categories = [] } = useCategories()
  const { selectCategoryValues } = useSelectedCategoryValues()

  if (categories == null) {
    return <LoadingScreen />
  }

  subtext = subtext || 'Select all that apply'

  const onCategoryAdded = (category: Category, shouldSelect: boolean) => {
    if (shouldSelect) {
      selectCategoryValues(category.values.map(it => it.categoryValueId))
    }
  }

  const orderedCategories = useMemo(() => orderCategories(categories || [], categorySort), [categories, categorySort])

  return (
    <Box>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='start'
      >
        <Stack direction='column'>
          <Typography variant='h3'> Set Tags </Typography>
          <Typography variant='small' color='text.deemphasized'>{subtext}</Typography>
        </Stack>

        <NewCategoryDialog onCategoryAdded={onCategoryAdded} />
      </Stack>
      <Divider sx={{ mt: 2, mb: 1 }} />
      <CategorySortButton />
      <Stack>
        {orderedCategories.map((it) => {
          return (
            <Fragment key={it.categoryId}>
              <Box my={2}><AssetCategory category={it} sort={categorySort} /></Box>
            </Fragment>
          )
        })}
      </Stack>
    </Box>
  )
}