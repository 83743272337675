import { Alert, Box, Button, Dialog, Divider, IconButton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import Iconify from '../../components/Iconify'
import { FormProvider, RHFCheckbox, RHFTextField } from '../../components/hook-form'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { CategoryValue } from '../../@types/category'
import { useAddCategoryValuesMutation } from '../../hooks/useCategories'

type AddTagsToCategoryFormProps = {
  defaultAssign: boolean
  categoryValues: { value: string }[]
}

type AddTagsToCategoryDialogProps = {
  categoryId: string
  showAutoAssignOption?: boolean
  onValuesAdded?: (values: CategoryValue[], shouldSelect: boolean) => void
}

export default function AddTagsToCategoryDialog({
                                                  categoryId,
                                                  onValuesAdded,
                                                  showAutoAssignOption = true,
                                                }: AddTagsToCategoryDialogProps) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { mutateAsync: addCategoryValues } = useAddCategoryValuesMutation()


  const methods = useForm<AddTagsToCategoryFormProps>({
    mode: 'onTouched',
    resolver: yupResolver(Yup.object().shape({
      categoryValues: Yup.array().of(Yup.object().shape({
        value: Yup.string().required().label('Tag Name'),
      })).test({
        message: 'A category requires at least one Tag',
        test: arr => arr?.filter(x => !!x.value).length !== 0,
      }),
    })),
    defaultValues: {
      defaultAssign: true,
      categoryValues: [{ value: '' }],
    },
  })

  const { control, handleSubmit, reset, formState: { isValid, errors } } = methods

  const { fields, append } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'categoryValues', // unique name for your Field Array
  })

  useEffect(() => {
    if (!open) {
      setTimeout(() => reset(), 250)
    }
  }, [open])

  const onSubmit = async (data: AddTagsToCategoryFormProps) => {
    setLoading(true)
    try {
      const params = { categoryId, values: data.categoryValues.map(it => it.value) }
      const categoryValues = await addCategoryValues(params)
      onValuesAdded && onValuesAdded(categoryValues, data.defaultAssign)
      setOpen(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button
        size='small'
        color='primary'
        variant='text'
        startIcon={<Iconify icon='eva:plus-fill' />}
        onClick={() => setOpen(true)}
      >
        New Tag
      </Button>

      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth={true}
        onClose={() => setOpen(false)}
        sx={{zIndex: 1402}}
      >
        <Box p={7}>
          <FormProvider methods={methods}>
            <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
              <IconButton onClick={() => setOpen(false)}>
                <Iconify icon='eva:close-fill' />
              </IconButton>
            </Box>
            <Typography variant='h4' mb={5}>Create New Tag</Typography>

            {!!errors.categoryValues && (
              <Alert variant='outlined' severity='error' sx={{ mb: 4 }}>
                A category requires at least one Tag
              </Alert>
            )}

            {/* loop over tags in a form field */}
            <Stack direction='column' spacing={4} sx={{ mb: fields.length ? 4 : 0 }}>
              {fields.map((field, index) => (
                <RHFTextField
                  key={field.id}
                  label='Tag Name'
                  variant='filled'
                  name={`categoryValues.${index}.value`}
                />
              ))}
            </Stack>

            <Button
              size='small'
              color='primary'
              variant='text'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => append({ value: '' })}
            >
              Add Another
            </Button>
            <Divider sx={{ my: 3 }} />

            <Stack spacing={3}>
              {showAutoAssignOption &&
                <RHFCheckbox name='defaultAssign' label='Use this tag for the current asset upload.' />}

              <LoadingButton
                loadingPosition='start'
                loading={loading}
                fullWidth={true}
                startIcon={<Iconify icon='eva:arrow-ios-forward-fill' />}
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                Create Tag
              </LoadingButton>
              <Button
                fullWidth={true}
                variant='text'
                sx={{ bgcolor: 'white' }}
                onClick={() => {
                  setOpen(false)
                }}
              >
                Cancel
              </Button>
            </Stack>
          </FormProvider>
        </Box>
      </Dialog>
    </>

  )
}