import { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import Iconify from '../../../Iconify.tsx'
import { AssignUsersToGroups } from './groups/AssignUsersToGroups.tsx'
import { LoadingButton } from '@mui/lab'
import {
  BulkUserGroupSelectedGroupsContextProvider,
  useSelectedGroupsLocalContext,
} from './groups/BulkUserGroupSelectedGroupsContext.tsx'
import { useBulkUpdateUserGroups } from '../../../../hooks/useUsers.ts'

type Props = {
  selectedUserIds: Set<string>,
  onUpdate?: () => void
}

export function BulkEditGroupsButton({ selectedUserIds, onUpdate }: Props) {
  const [open, setOpen] = useState<boolean>(false)
  const handleUpdateClick = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleSave = () => {
    onUpdate && onUpdate()
    handleClose()
  }

  return (
    <>
      <Tooltip title='Edit Groups'>
        <IconButton onClick={handleUpdateClick}>
          <Iconify icon={'ph:users'} width={20} height={20} />
        </IconButton>
      </Tooltip>


      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth={true}
        onClose={handleClose}
        sx={{ zIndex: 1401 }}
      >
        <BulkUserGroupSelectedGroupsContextProvider
          enabled={open}
          userIds={selectedUserIds}
        >
          <DialogTitle>
            <Typography variant='h2'>Edit Assigned Groups</Typography>
            <Typography variant='caption' color='text.deemphasized'>
              Select groups to add or remove users from them. Any unchanged group selection will be preserved.
            </Typography>
            <Divider sx={{ mt: 2, mb: 4 }} />

            <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
              <IconButton onClick={handleClose}>
                <Iconify icon='eva:close-fill' />
              </IconButton>
            </Box>
          </DialogTitle>


          <DialogContent>
            <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
              <AssignUsersToGroups />
            </Box>
          </DialogContent>

          <Actions
            userIds={selectedUserIds}
            onCancel={handleClose}
            onSave={handleSave}
          />

        </BulkUserGroupSelectedGroupsContextProvider>

      </Dialog>
    </>
  )
}

/*
 * this is a dumb workaround to be able to access the selected group ids context in a hook
 * Since it's declared in the root component, we can't use the hook,
 * even though it's in scope. :eyeroll:
 *
 * This way we can get access to the selected group ids
 */
function Actions({ userIds, onCancel, onSave }: { userIds: Set<string>, onCancel?: () => void, onSave?: () => void }) {
  const { selectedGroupIds, partialSelectedGroupIds } = useSelectedGroupsLocalContext()
  const { mutateAsync: bulkUpdateUserGroups, isLoading: saving } = useBulkUpdateUserGroups()
  const handleClose = () => onCancel && onCancel()
  const handleSave = () => {
    bulkUpdateUserGroups({
      userIds: Array.from(userIds),
      selectedGroupIds: Array.from(selectedGroupIds),
      preservedGroupIds: Array.from(partialSelectedGroupIds),
    }).then(() => {
      onSave && onSave()
    })
  }
  return (
    <DialogActions>
      <Button
        variant='outlined'
        onClick={handleClose}
      >
        Cancel
      </Button>
      <LoadingButton
        loading={saving}
        variant='contained'
        color='primary'
        onClick={handleSave}
      >
        Save Groups
      </LoadingButton>

    </DialogActions>
  )
}