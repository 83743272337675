import { Asset, AssetPermission } from '../../../@types/asset'
import { AssetContentState, AssetContentStateStatus } from '../../../@types/asset-content'
import { ReadinessContainer } from '../ReadinessContainer'
import { ViewGeneratedQuestionsPopover } from './ViewGeneratedQuestionsPopover'
import { VisibleForPermission } from '../../../guards/VisibleForPermission.tsx'
import { LoadingButton } from '@mui/lab'
import Iconify from '../../Iconify.tsx'
import { useRetryGenerateQuestions } from '../../../hooks/useAssetContent.ts'

export function GeneratedQuestionsReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const { mutateAsync: retryQuestions, isLoading: questionGenerationInProcess } = useRetryGenerateQuestions()
  const handleRetry = () => retryQuestions({ assetId: asset.assetId })
  const isReady = state.status === AssetContentStateStatus.SUCCESS
  const buttonsDisabled = questionGenerationInProcess
  return (
    <ReadinessContainer
      icon="eva:message-square-outline"
      state={state}
      title="Questions"
      subtitle="Predict what questions employees and prospects will ask that are answered by this asset."
    >
      {isReady && (<ViewGeneratedQuestionsPopover asset={asset} />)}

      <VisibleForPermission permission={AssetPermission.EDIT_METADATA} level={asset.accessLevel}>
        <LoadingButton
          disabled={buttonsDisabled}
          variant='text'
          size='small'
          startIcon={<Iconify icon='ph:arrow-clockwise' />}
          loading={questionGenerationInProcess}
          loadingPosition='start'
          onClick={handleRetry}
        >
          Rerun
        </LoadingButton>
      </VisibleForPermission>
    </ReadinessContainer>
  )
}