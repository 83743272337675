import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { DEFAULT_GROUP_LIST_ITEMS_DATA, useGroupsList } from '../../../../../hooks/useGroups.ts'
import { GroupListItem, HierarchyGroupItem } from '../../../../../@types/group.ts'
import { useBulkUserGroupState } from '../../../../../hooks/useUsers.ts'
import { toGroupItems } from '../../../../../utils/groupUtils.ts'

type State = {
  selectedGroupIds: Set<string>,
  partialSelectedGroupIds: Set<string>,
  groups: GroupListItem[],
  groupsById: Dictionary<GroupListItem>,
  rootGroups: HierarchyGroupItem[]
}

type Actions = {
  setGroupSelected: (groupId: string, selected: boolean) => void,
}

type ContextType = State & Actions

const BulkUserGroupSelectedGroupsContext = createContext<ContextType>({} as ContextType)

export function BulkUserGroupSelectedGroupsContextProvider(
  {
    children,
    enabled,
    userIds,
  }: {
    children: ReactNode,
    enabled: boolean,
    userIds: Set<string>,
  }) {
  const groupsQuery = useGroupsList(enabled)
  const { data: currentState } = useBulkUserGroupState(Array.from(userIds), enabled)
  const [selectedGroupIds, setSelectedGroupIds] = useState<Set<string>>(new Set<string>())
  const [partialSelectedGroupIds, setPartialSelectedGroupIds] = useState<Set<string>>(new Set<string>())

  // update the state when the hook is done
  useEffect(() => {
    setSelectedGroupIds(new Set(currentState?.selectedGroupIds))
    setPartialSelectedGroupIds(new Set(currentState?.partialSelectedGroupIds))
  }, [currentState])

  const addToSet = (set: Set<string>, groupId: string) => new Set([...set, groupId])
  const removeFromSet = (set: Set<string>, groupId: string) => new Set(Array.from(set).filter(it => it != groupId))

  function setGroupSelected(groupId: string, selected: boolean) {
    if (selected) {
      setSelectedGroupIds(prevState => addToSet(prevState, groupId))
      setPartialSelectedGroupIds(prevState => removeFromSet(prevState, groupId))
    } else {
      setSelectedGroupIds(prevState => removeFromSet(prevState, groupId))
      setPartialSelectedGroupIds(prevState => removeFromSet(prevState, groupId))
    }
  }

  const { groups, groupsById } = groupsQuery.data || DEFAULT_GROUP_LIST_ITEMS_DATA
  const rootGroups = useMemo(() => toGroupItems(groups).filter(it => !it.parentGroupId), [groups])

  return (
    <BulkUserGroupSelectedGroupsContext.Provider
      value={{
        groups,
        groupsById,
        rootGroups,
        selectedGroupIds,
        partialSelectedGroupIds,
        setGroupSelected,
      }}
    >
      {children}
    </BulkUserGroupSelectedGroupsContext.Provider>
  )
}

export function useSelectedGroupsLocalContext() {
  return useContext(BulkUserGroupSelectedGroupsContext)
}
