import { useState } from 'react'
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import Iconify from '../../../Iconify.tsx'
import { LoadingButton } from '@mui/lab'
import { useBulkDeleteUsers } from '../../../../hooks/useUsers.ts'
import { useIsScimEnabled } from '../../../../hooks/useScim.ts'

type Props = {
  selectedUserIds: Set<string>,
  onDelete?: () => void
}
export function BulkUsersDeleteButton({ selectedUserIds, onDelete }: Props) {
  const scimEnabled = useIsScimEnabled()
  const [open, setOpen] = useState<boolean>(false)
  const { mutateAsync: deleteUsers, isLoading: deleting } = useBulkDeleteUsers()
  const handleDeleteClick = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleConfirmation = () => {
    deleteUsers({ userIds: Array.from(selectedUserIds) } )
      .then(() => {
        handleClose()
        onDelete && onDelete()
      })
  }

  // do not show the bulk delete button if scim is enabled
  if(scimEnabled) return <></>

  return (
    <>
      <Tooltip title='Delete users'>
        <IconButton
          onClick={handleDeleteClick}
        >
          <Iconify icon={'ph:trash'} width={20} height={20} />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        maxWidth='xs'
        fullWidth={true}
        onClose={handleClose}
      >

        <DialogTitle>
          <Typography variant='h2' paragraph>
            {
              selectedUserIds.size == 1 ?
                'Are you sure you want to delete this user?' :
                `Are you sure you want to delete these ${selectedUserIds.size} users?`
            }
          </Typography>

          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={handleClose}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>
        </DialogTitle>


        <DialogContent>
          <Typography variant='standard' color='text.secondary' component='div' mb={4}>
            This action is permanent and cannot be undone.
          </Typography>
          <Stack spacing={2}>
            <LoadingButton
              loading={deleting}
              fullWidth={true}
              variant='contained'
              color='primary'
              size='large'
              onClick={handleConfirmation}
            >
              Delete
            </LoadingButton>
            <Button
              fullWidth={true}
              variant='outlined'
              size='large'
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}