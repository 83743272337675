import { Theme } from '@mui/material/styles';
//
import { InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
      },

      styleOverrides: {
        root: {
          "&.MuiInputBase-sizeXs": {
            fontSize: "12px", // Smaller font
            minHeight: "28px", // Reduce height
            "& .MuiSelect-select": {
              padding: "4px 12px", // Adjust padding
            },
            "& .MuiNativeSelect-select": {
              padding: "4px 12px", // Adjust padding
            },
          },
        },
      },
    },
  };
}
