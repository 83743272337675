import Page from '../../components/Page'
import { Stack } from '@mui/material'
import { BrandCustomization } from '../../components/company/BrandCustomization'
import { SharingSettings } from '../../components/company/SharingSettings'
import { GeneralSettings } from '../../components/company/GeneralSettings'
import { ImageFormatCustomization } from '../../components/company/ImageFormatCustomization.tsx'


export default function AdminCompanySettings() {
  return (
    <Page title='Company Info'>
      <Stack spacing={12} mb={20}>
        <GeneralSettings />
        <BrandCustomization />
        <ImageFormatCustomization />
        <SharingSettings />
      </Stack>
    </Page>
  )
}

