import { getAssetTaskStatus } from "./AssetClient";
import { Asset, AssetTasksResponseDto, isTaskStatusRunning, AssetProcessingTaskStatus } from "../@types/asset";

const INTERVALS = [1000, 3000, 5000, 10000]

export const pollForCompletion = async (assetId: string, existingAsset?: Asset) => {
  return new Promise<AssetTasksResponseDto>((resolve, reject) => {

    // if we have an existing asset and its already done, don't do the polling
    if(existingAsset && !isTaskStatusRunning(existingAsset.labels.status)){
      resolve({
        assetId: assetId,
        labels: existingAsset.labels,
        palettes: existingAsset.palettes
      })
      return
    }

    const poller = new AssetTaskPoller()
    poller.start(assetId)
      .then((dto) => {
        resolve(dto)
      })
      .catch(() => {
        const response = {
          assetId: assetId,
          labels: { status: AssetProcessingTaskStatus.FAILED },
          palettes: { status: AssetProcessingTaskStatus.FAILED }
        }
        reject(response)
      })
  })
}

export class AssetTaskPoller {
  private curIdx = 0

  private assetId = ""

  private deferred = new Deferred();

  start(assetId: string) {
    this.curIdx = 0
    this.assetId = assetId
    this.deferred = new Deferred()

    this.poll()

    return this.deferred.promise
  }

  private async poll() {
    if (this.curIdx >= INTERVALS.length) {
      this.deferred.reject("intervals complete")
      return
    }

    // get current delay
    const delay = INTERVALS[this.curIdx]

    // increment the delay
    this.curIdx++

    // check for completion after the given delay
    setTimeout(() => {
      getAssetTaskStatus(this.assetId)
        .then((taskDto) => this.processPollResult(taskDto))
        .catch(() => {
          this.deferred.reject("request failed")
        })
    }, delay)
  }

  private processPollResult(taskDto: AssetTasksResponseDto) {
    if (!isTaskStatusRunning(taskDto.labels.status)) {
      this.deferred.resolve(taskDto)
    } else {
      this.poll()
    }
  }
}

class Deferred {

  public promise: Promise<any>;

  // @ts-ignore
  public reject: ((reason?: any) => void);

  // @ts-ignore
  public resolve: ((value: any) => void);

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject
      this.resolve = resolve
    })
  }
}