import { ContentAuditEntry, ContentGrade } from '../../@types/content-audit.ts'
import { useState } from 'react';
import { srcForPreviewItem } from '../asset-search/images.tsx'
import { AssetPreviewType } from '../../@types/asset.ts'
import { StripedTableRow } from '../analytics/common/StripedTableRow.tsx'
import { Box, Collapse, Link, Stack, TableCell, Tooltip, Typography } from '@mui/material'
import Iconify from '../Iconify.tsx'
import { Link as RouterLink } from 'react-router-dom'
import Image from '../Image.tsx'
import { AnalysisRowDetails } from './AnalysisRowDetails.tsx'
import { styled } from '@mui/material/styles'
import { GRADE_COLORS, GRADE_NAMES } from './ContentAnalysisConstants.tsx'

export const ContentAnalysisTableRow = ({ row, rowNum }: { row: ContentAuditEntry, rowNum: number }) => {
  const [open, setOpen] = useState(false)
  const handleExpand = () => setOpen(!open)
  const imgSrc = srcForPreviewItem(row.assetPreviewId, AssetPreviewType.THUMBNAIL_SM)
  const iconRotation = open ? '90deg' : '0deg'
  const isStriped = rowNum % 2 == 0
  return (
    <>
      <StripedTableRow isStriped={isStriped}>
        <TableCell>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Iconify
              onClick={handleExpand}
              icon='eva:chevron-right-fill'
              sx={{
                fontSize: 20,
                transition: '100ms all ease-in-out',
                rotate: iconRotation,
                cursor: 'pointer',
              }}
            />
            <Link component={RouterLink} to={`/assets/detail/${row.assetId}`} target='_blank'>
              <PreviewContainer>
                <Image alt={row.name} src={imgSrc} ratio='1/1' backgroundType='contain' />
              </PreviewContainer>
            </Link>
          </Stack>
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell sx={{ textAlign: 'center' }}>{row.hasRawFile ? <SuccessfulCheck /> : <FileMissingIcon />}</TableCell>
        <TableCell>{row.hasChunks ? <SuccessfulCheck/> : <NotInConversationIcon />}</TableCell>
        <TableCell><AuditGrade grade={row.grade} /></TableCell>
      </StripedTableRow>
      <StripedTableRow isStriped={isStriped}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box pl={4} pb={6}>
              <AnalysisRowDetails assetId={row.assetId} />
            </Box>
          </Collapse>
        </TableCell>
      </StripedTableRow>
    </>
  )
}

const SuccessfulCheck = () => <Iconify icon='ph:check-circle' height={24} width={24} color='primary.main' />
const FileMissingIcon = () => <Iconify icon='ph:file-x' height={24} width={24} color='warning.main' />
const NotInConversationIcon = () => <Iconify icon='ph:chat-circle-slash' height={24} width={24} color='warning.main' />

const PreviewContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.bg.warmGreyLight : theme.palette.background.neutral,
  borderRadius: 8,
  overflow: 'hidden',
  height: 48,
  width: 48,
}))


function AuditGrade({ grade }: { grade: ContentGrade }) {
  const name = GRADE_NAMES[grade] || 'Uncertified'
  const color = GRADE_COLORS[grade] || GRADE_COLORS[ContentGrade.UNCERTIFIED]
  return (
    <Stack direction='row' spacing={1} alignItems='center' sx={{ color: color }}>
      <Iconify icon='ph:medal-fill' sx={{ width: 24, height: 24 }} />
      <Typography variant='standard'>{name}</Typography>
    </Stack>
  )
}
