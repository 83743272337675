import { IconButton, Paper, Snackbar, Stack, Tooltip, Typography } from '@mui/material'
import { BulkUsersDeleteButton } from './action-buttons/BulkUsersDeleteButton.tsx'
import Iconify from '../../Iconify.tsx'
import { BulkEditGroupsButton } from './action-buttons/BulkEditGroupsButton.tsx'

type Props = {
  selectedItems: Set<string>
  onActionCompleted?: (action: string) => void
}
export function AdminUsersActionBar({ selectedItems, onActionCompleted }: Props) {
  const actionBarOpen = selectedItems.size > 0
  const handleActionBarClose = () => onActionCompleted && onActionCompleted("close")
  const handleDeleteCompleted = () => onActionCompleted && onActionCompleted("delete")
  const handleUpdateCompleted = () => onActionCompleted && onActionCompleted("update")
  return (<>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={actionBarOpen}
        key='mutli-select-action-bar'
      >
        <Paper elevation={2} sx={{ px: 2, py: 1, mb: 2, backgroundColor: "background.neutral" }}>
          <Stack
            direction='row'
            spacing={4}
            alignItems='center'
          >
            <Typography variant='standardHighlight'>{selectedItems.size} users selected</Typography>
            <Stack
              direction='row'
              alignItems='center'
            >
              <BulkEditGroupsButton
                selectedUserIds={selectedItems}
                onUpdate={handleUpdateCompleted}
              />
              <BulkUsersDeleteButton
                selectedUserIds={selectedItems}
                onDelete={handleDeleteCompleted}
              />
              <Tooltip title='Deselect Items'>
                <IconButton
                  onClick={handleActionBarClose}
                >
                  <Iconify icon={'ph:x'} width={20} height={20} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Paper>
      </Snackbar>

    </>
  )
}