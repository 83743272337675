import Iconify from '../../Iconify'
import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import { GroupListItem } from '../../../@types/group'
import { useGroupDeleteMutation } from '../../../hooks/useGroups'
import { fNumber } from '../../../utils/formatNumber'

type GroupDeleteButtonProps = {
  group: GroupListItem,
  children: GroupListItem[]
}

export function GroupDeleteButton({ group, children }: GroupDeleteButtonProps) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { mutateAsync: deleteGroup } = useGroupDeleteMutation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleGroupDelete = async () => {
    setLoading(true)
    await deleteGroup({ groupId: group.groupId })
    setLoading(false)
    handleClose()
  }

  const canDelete = children.length == 0

  return (
    <>
      <IconButton
        size='small'
        onClick={handleOpen}
      >
        <Iconify color='text.red' icon='eva:trash-2-outline' />
      </IconButton>

      <Dialog
        open={open}
        maxWidth='xs'
        fullWidth={true}
        onClose={handleClose}
      >

        <Box p={7} sx={{ textAlign: 'center' }}>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={handleClose}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Iconify sx={{ mb: 2, fontSize: 48 }} icon='eva:alert-circle-outline' color='primary.main' />

          {
            canDelete && (<>
                <Typography variant='h2' mb={4}>
                  Are you sure you want to delete this group?
                </Typography>

                <Typography variant='standard' color='text.secondary' component='div' mb={4}>
                  Deleting this group will permanently remove it from Masset. Members of this group
                  may lose access to shared assets.
                </Typography>
              </>
            )
          }

          {
            children.length != 0 && (<>
                <Typography variant='h2' mb={4}>
                  Unable to delete this group
                </Typography>

                <Typography variant='standard' color='text.secondary' component='div' mb={4}>
                  This group is associated with {fNumber(children.length)} sub-groups. To delete this
                  group, you must delete or reassign the sub-groups.
                </Typography>
              </>
            )
          }


          <Stack spacing={2}>
            <LoadingButton
              loading={loading}
              fullWidth={true}
              variant='contained'
              color='primary'
              size='large'
              onClick={handleGroupDelete}
              disabled={!canDelete}
            >
              Delete Group
            </LoadingButton>
            <Button
              fullWidth={true}
              variant='outlined'
              size='large'
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  )
}