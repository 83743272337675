import { Asset } from '../../../../@types/asset.ts'
import { Box, Button, CircularProgress, InputAdornment, Stack, TextField } from '@mui/material'
import { ASSET_SHARE_PADDING_X } from '../field/AssetShareField.tsx'
import { DEFAULT_ASSET_EXTERNAL_SHARES_DATA, useAssetExternalShares } from '../../../../hooks/useAssetExternalShares.ts'
import Iconify from '../../../Iconify.tsx'
import { useState } from 'react'
import { ExternalSharesList } from './ExternalSharesList.tsx'
import { CreateNewAssetExternalShareForm } from './CreateNewAssetExternalShareForm.tsx'
import { AnimatePresence, motion } from 'framer-motion'
import { ExternalSharesSearchResults } from './ExternalSharesSearchResults.tsx'

const SEARCH_CONTENT_HEIGHT = 350


export function AssetShareExternalTab({ asset }: { asset: Asset }) {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const { data, isFetching } = useAssetExternalShares(asset.assetId)

  // handlers
  const handleSearchTermClear = () => setSearchTerm('')
  const handleAddShareClick = () => setIsCreating(true)
  const handleCreateCancel = () => setIsCreating(false)
  const handleOnCreated = () => setIsCreating(false)

  // calculated props
  const { shares } = data || DEFAULT_ASSET_EXTERNAL_SHARES_DATA

  if (isFetching) {
    return <Box px={ASSET_SHARE_PADDING_X} mb={4}><CircularProgress size={24} /></Box>
  }

  return (
    <Stack>
      <Stack
        px={ASSET_SHARE_PADDING_X}
        mb={2}
        direction='row'
        spacing={2}
      >
        <TextField
          fullWidth
          size='small'
          value={searchTerm}
          onChange={(ev) => setSearchTerm(ev.target.value)}
          placeholder='Search...'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Iconify icon='eva:search-outline' />
              </InputAdornment>
            ),
            endAdornment: !searchTerm ? undefined : (
              <InputAdornment position='end'>
                <Iconify icon='eva:close-outline' sx={{ cursor: 'pointer' }} onClick={handleSearchTermClear} />
              </InputAdornment>
            ),
          }}
          sx={{
            flex: 1,
          }}
        />
        <Button
          variant='contained'
          color='primary'
          size='small'
          startIcon={<Iconify icon={'eva:plus-outline'} />}
          disabled={isCreating}
          onClick={handleAddShareClick}
        >
          New Share Link
        </Button>
      </Stack>

      <Box
        sx={{
          minHeight: SEARCH_CONTENT_HEIGHT,
          maxHeight: "calc(100vh - 480px)",
          overflowY: 'auto',
        }}
      >
        <AnimatePresence>
          {isCreating && (
            <motion.div
              key='create-asset-content-room'
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: .2 }}
            >
              <Box sx={{mt: 2}}>
              <CreateNewAssetExternalShareForm
                asset={asset}
                onCancel={handleCreateCancel}
                onCreated={handleOnCreated}
              />
              </Box>
            </motion.div>
          )}
        </AnimatePresence>

        <ExternalSharesList shares={shares} searchTerm={searchTerm} />
        <ExternalSharesSearchResults shares={shares} searchTerm={searchTerm} />
      </Box>

    </Stack>

  )
}