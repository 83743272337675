import { Box, Stack, Typography } from '@mui/material'
import Image from '../../Image.tsx'
import { useTheme } from '@mui/material/styles'
import { useOauthPopup } from '../../../hooks/useOauth.ts'
import { useQueryClient } from '@tanstack/react-query'

export function GoogleDriveFilePickerNewAccount(){
  const theme = useTheme()
  const queryClient = useQueryClient()
  const handleClick = () =>  trigger('google-drive')
  const handleOauthSuccess = () => {
    queryClient.invalidateQueries(['accounts'])
  }
  const { trigger } = useOauthPopup({ onSuccess: handleOauthSuccess })
  return (
    <Box
      onClick={handleClick}
      sx={{
        border: `1px dashed ${theme.palette.divider}`,
        borderRadius: 1,
        p: 1,
        width: 360,
        cursor: "pointer",
      }}
    >
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
      >
        <Box>
          <Image
            src={`/assets/integrations/google-drive.png`}
            ratio='1/1'
            backgroundType='contain'
            sx={{ width: 36, height: 36 }}
          />
        </Box>
        <Stack direction='column'>
          <Typography variant='smallHighlight'>Connect Google Drive Account</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}