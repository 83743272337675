import { Task } from '../../../@types/task.ts'
import { useTheme } from '@mui/material/styles'
import { TextField } from '@mui/material'
import { useSetTaskName } from '../../../hooks/useTasks.ts'

export function InlineNameUpdateField({ task }: { task?: Task }) {
  const theme = useTheme()
  const { mutateAsync: setTaskName } = useSetTaskName()
  const handleNameChange = (ev: React.FocusEvent<HTMLTextAreaElement>) => {
    if(task?.taskId){
      setTaskName({taskId: task.taskId, name: ev.currentTarget.value})
    }
  }
  return (
    <TextField
      id='name'
      multiline
      fullWidth
      minRows={1}
      defaultValue={task?.name}
      onBlur={handleNameChange}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'transparent', // hides border initially
          },
          '&:hover fieldset': {
            borderColor: theme.palette.grey[500_32], // show border on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main, // optional: keep border on focus
          },
        },
        '& .MuiInputBase-input': {
          ...theme.typography.h2,
        },
      }}
    />
  )
}