import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  bulkDeleteAssets,
  bulkUpdateAssetTags,
  getAsset,
  getAssetBulkTagState,
  getAssetTaskStatus,
  recordAssetView,
  retryTasks,
  setIsFavorite,
  uploadNewFileVersion,
} from '../clients/AssetClient'
import { Asset, isTaskStatusRunning, NewVersionDto } from '../@types/asset'
import { getContentGPSForAsset, retryContentGPSCalculation } from '../clients/ContentGPSClient'

const STANDARD_POLL_INTERVALS = [1000, 1000, 1000, 3000, 3000, 5000, 10000]

export function useAsset(assetId: string | undefined) {
  return useQuery({
    enabled: !!assetId,
    queryKey: ['asset', assetId],
    queryFn: async () => getAsset(assetId!!),
  })
}

export function useAssetTasks(assetId: string | undefined, poll: boolean = true) {
  return useQuery({
    enabled: !!assetId,
    queryKey: ['asset-tasks', assetId],
    queryFn: async () => getAssetTaskStatus(assetId!!),
    refetchInterval: (data, query) => {
      if (!poll) return false
      const idx = Math.min(query.state.dataUpdateCount, STANDARD_POLL_INTERVALS.length - 1)
      return !data || isTaskStatusRunning(data.labels.status) ? STANDARD_POLL_INTERVALS[idx] : false
    },
  })
}

export function useContentGPS(assetId: string, poll: boolean = true) {
  return useQuery({
    enabled: !!assetId,
    queryKey: ['asset-contentgps', assetId],
    queryFn: async () => getContentGPSForAsset(assetId!!),
    refetchInterval: (data, query) => {
      if (!poll) return false
      const idx = Math.min(query.state.dataUpdateCount, STANDARD_POLL_INTERVALS.length - 1)
      return !data || isTaskStatusRunning(data.assetResults.status) ? STANDARD_POLL_INTERVALS[idx] : false
    },
  })
}

export function useBulkAssetTagState(assetIds: string[], enabled: boolean = true){
  return useQuery({
    enabled,
    queryKey: ['bulk-asset-tags', assetIds],
    queryFn: async() => getAssetBulkTagState(assetIds)
  })
}

type BulkUpdateAssetTagsParams = {
  assetIds: string[],
  selectedCategoryValueIds: string[],
  preservedCategoryValueIds: string[],
}
export function useBulkUpdateAssetTags(){
  return useMutation({
    mutationFn: async({assetIds, selectedCategoryValueIds, preservedCategoryValueIds}: BulkUpdateAssetTagsParams) =>
      bulkUpdateAssetTags(assetIds, selectedCategoryValueIds, preservedCategoryValueIds)
  })
}

type BulkDeleteAssetParams = { assetIds: string[] }
export function useBulkDeleteAssets(){
  return useMutation({
    mutationFn: async({assetIds}: BulkDeleteAssetParams) => bulkDeleteAssets(assetIds)
  })
}


type FavoriteMutationParams = { assetId: string, isFavorite: boolean }

export function useAssetFavoriteMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ assetId, isFavorite }: FavoriteMutationParams) => setIsFavorite(assetId, isFavorite),
    onSuccess: (_data, { assetId }) => queryClient.invalidateQueries(['asset', assetId]),
  })
}

export function useRetryTasksMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (assetId: string) => retryTasks(assetId),
    onSuccess: (data, assetId) => {
      queryClient.setQueryData(
        ['asset', assetId],
        (oldData?: Asset) => oldData ? { ...oldData, ...data } : oldData,
      )
    },
  })
}

export function useRetryContentGPSMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (assetId: string) => retryContentGPSCalculation(assetId),
    onSettled: (_data, assetId) => queryClient.invalidateQueries(['asset-contentgps', assetId]),
  })
}

type NewAssetRevisionMutationParams = {
  assetId: string,
  file: File,
  notifyOptions: NewVersionDto
}

export function useNewAssetRevisionMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: NewAssetRevisionMutationParams) => {
      return uploadNewFileVersion(params.assetId, params.file, params.notifyOptions)
    },
    onSuccess: (data, { assetId }) => {
      queryClient.setQueryData(
        ['asset', assetId],
        (oldData?: Asset) => oldData ? { ...oldData, ...data } : oldData,
      )
      queryClient.invalidateQueries(['asset-tasks', assetId])
      queryClient.invalidateQueries(['asset-contentgps', assetId])
    },
  })
}

type RecordAssetViewParams = {
  assetId: string,
}
export function useRecordAssetView(){
  return useMutation({
    mutationFn: async(params: RecordAssetViewParams) => recordAssetView(params.assetId)
  })
}