import { Fragment, useMemo } from 'react'
import Page from '../../components/Page'
import { Box, Divider, Stack, Typography } from '@mui/material'
import LoadingScreen from '../../components/LoadingScreen'
import EditableAssetCategory from '../../components/admin/category/EditableAssetCategory'
import NewCategoryDialog from '../asset/NewCategoryDialog'
import { useCategories } from '../../hooks/useCategories'
import { useLocalStorage } from 'usehooks-ts'
import { CategorySort } from '../../contexts/SearchContext.tsx'
import { orderCategories } from '../../utils/category.ts'
import {
  CategorySortButton,
  PREFS_GLOBAL_CATEGORIES_SORT_KEY,
} from '../../components/admin/category/CategorySortButton.tsx'


export default function AdminCategories(){
  const [categorySort] = useLocalStorage(PREFS_GLOBAL_CATEGORIES_SORT_KEY, CategorySort.USAGE)
  const { data: categories } = useCategories()

  const orderedCategories = useMemo(() => orderCategories(categories || [], categorySort), [categories, categorySort])

  if(!categories){
    return <LoadingScreen/>
  }

  return (
    <Page title="Category Management">
      <Stack direction="row" alignItems="start" justifyContent="space-between">
        <Typography variant="h2" >Category Management</Typography>
        <NewCategoryDialog showAutoAssignOption={false} />
      </Stack>

      <Box my={3}>
        <CategorySortButton />
      </Box>

      { orderedCategories.map((it, idx) => {
        return (
          <Fragment key={it.categoryId}>
            {!!idx && <Divider sx={{ my: 3 }} /> }
            <EditableAssetCategory category={it} sort={categorySort} />
          </Fragment>
        )
      }) }
    </Page>
  )
}