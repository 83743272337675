import { User } from '../../../../@types/user'
import { useTheme } from '@mui/material/styles'
import { HoverableItem } from './HoverableItem'
import { Avatar, Box, Button, Stack, Typography } from '@mui/material'
import { AVATAR_SIZE } from './GroupMembersField'
import { useFormContext } from 'react-hook-form'
import { internalGroupUserForUser } from './group-member-field-util'
import orderBy from 'lodash/orderBy'
import { ShareLevel } from '../../../../@types/sharing'

export function UserToAddItem({ user, fieldName }: { user: User, fieldName: string }) {
  const theme = useTheme()
  const { getValues, setValue } = useFormContext()

  const handleAdd = () => {
    const values = getValues(fieldName)
    const newValue = orderBy([...values, internalGroupUserForUser(user, ShareLevel.VIEW)], 'name', 'asc')
    setValue(fieldName, newValue, { shouldDirty: true })
  }

  // calculated props
  const name = `${user.firstName} ${user.lastName}`
  const initials = name.charAt(0)

  return (
    <HoverableItem>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Avatar
            sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, bgcolor: theme.palette.primary.dark }}>{initials}</Avatar>
          <Stack direction='column'>
            <Typography>{name}</Typography>
            <Typography component='span' variant='small'>{user.email}</Typography>
          </Stack>
        </Stack>
        <Box>
          <Button
            size='small'
            variant='outlined'
            onClick={handleAdd}
          >
            Add
          </Button>
        </Box>
      </Stack>
    </HoverableItem>
  )
}
