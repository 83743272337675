import {
  Button,
  ClickAwayListener,
  FormControl,
  Grow,
  InputLabel,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material'
import { ImageFormatFileTypeSelect } from './ImageFormatFileTypeSelect.tsx'
import { ImageFormatSizeSelect } from './ImageFormatSizeSelect.tsx'
import { ImageDownloadFileType, ImageDownloadSize } from '../../../../@types/company.ts'
import { RefObject, useState } from 'react'
import { useImageFormatOptions } from '../../../../hooks/useBootstrap.ts'
import { ConsumeableAsset, useDownload } from '../../../../hooks/useDownload.tsx'
import Iconify from '../../../Iconify.tsx'

type CustomFormatPopperProps = {
  asset: ConsumeableAsset
  open: boolean,
  anchorRef: RefObject<HTMLElement>
  onClose?: () => void
  includeOriginal? : boolean
}

export function CustomFormatPopper({
                                     asset,
                                     open,
                                     anchorRef,
                                     onClose,
                                     includeOriginal
                                   }: CustomFormatPopperProps) {
  const { sizes, fileTypes } = useImageFormatOptions()
  const [fileType, setFileType] = useState<ImageDownloadFileType>(fileTypes[0])
  const [sizeId, setSizeId] = useState<string>(sizes[0]?.imageFormatSizeId)
  const [selectIsOpen, setSelectIsOpen] = useState<boolean>(false)
  const { consumeAsset } = useDownload()

  const handleClose = () => onClose && onClose()
  const handleClickAway = () => !selectIsOpen && handleClose()
  const handleSelectOpen = () => setSelectIsOpen(true)
  const handleSelectClose = () => setSelectIsOpen(false)
  const handleOnClickOriginal = () => consumeAsset(asset)
  const handleOnClickCustomized = () => consumeAsset(asset, { fileType, imageFormatSizeId: sizeId })
  const handleFileTypeChange = (fileType: ImageDownloadFileType) => setFileType(fileType)
  const handleSizeChange = (size: ImageDownloadSize) => setSizeId(size.imageFormatSizeId)


  return (
    <Popper
      sx={{ zIndex: 1 }}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      placement='bottom-end'
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper sx={{ p: 2 }} elevation={2}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Stack spacing={1}>
                <Typography variant="standardHighlight">Download Options</Typography>
                {fileTypes && fileTypes.length && (
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={6}>
                    <InputLabel id='file-type-label'><Typography variant='small'>File Type</Typography></InputLabel>
                    <FormControl>
                      <ImageFormatFileTypeSelect
                        value={fileType}
                        labelId='file-type-label'
                        onChange={handleFileTypeChange}
                        onOpen={handleSelectOpen}
                        onClose={handleSelectClose}
                      />
                    </FormControl>
                  </Stack>
                )}

                {sizes && sizes.length && (
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={6}>
                    <InputLabel id='size-label'><Typography variant='small'>Size</Typography></InputLabel>
                    <FormControl>
                      <ImageFormatSizeSelect
                        value={sizeId}
                        labelId='size-label'
                        onChange={handleSizeChange}
                        onOpen={handleSelectOpen}
                        onClose={handleSelectClose}
                      />
                    </FormControl>
                  </Stack>
                )}
              </Stack>
            </ClickAwayListener>

            <Stack spacing={1} sx={{mt:2}}>
              <Button
                variant='outlined'
                size='small'
                fullWidth
                onClick={handleOnClickCustomized}
              >
                Download Custom Format
              </Button>

              {
                includeOriginal && (
                  <Button
                    startIcon={<Iconify icon={'eva:download-fill'} />}
                    color='primary'
                    size='small'
                    variant='outlined'
                    onClick={handleOnClickOriginal}
                  >
                    Download Original
                  </Button>
                )
              }
            </Stack>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}