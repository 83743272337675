import { Box, Stack, Typography } from '@mui/material'
import { useAccounts } from '../../hooks/useAccounts.ts'
import { OauthProvider } from '../../@types/oauth.ts'
import { GoogleDriveUploadFilePicker } from './file-pickers/GoogleDriveUploadFilePicker.tsx'
import { GoogleDriveFilePickerNewAccount } from './file-pickers/GoogleDriveFilePickerNewAccount.tsx'

export function IntegrationFilePickers() {
  const { data: accounts } = useAccounts()
  const googleDriveAccounts = (accounts || []).filter(it => it.provider == OauthProvider.GOOGLE_DRIVE)
  return (
    <Box>
      <Box
        textAlign="center"
        mb={2}
      >
        <Typography variant="standard" color="text.deemphasized">Import using integrations</Typography>
      </Box>
      <Stack
        direction='column'
        spacing={1}
        alignItems="center"
      >
        {
          googleDriveAccounts.map(it => {
            return (
              <GoogleDriveUploadFilePicker key={it.accountId} account={it} />
            )
          })
        }
        <GoogleDriveFilePickerNewAccount />
      </Stack>
    </Box>
  )
}
