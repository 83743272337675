import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createCategory,
  createCategoryValues,
  deleteCategory,
  deleteCategoryValue,
  getCategories,
  reassignCategoryValue,
  renameCategory,
  editCategoryValue,
} from '../clients/CategoryClient'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'
import { CategoryValueWithCategory } from '../@types/category'

export function useCategories() {
  return useQuery({
    queryKey: ['categories'],
    queryFn: async () => getCategories(),
    select: data => orderBy(data, ['usage'], 'desc'),
    staleTime: 60_000
  })
}

export function useCategoryValuesWithCategories() {
  const { data: categories } = useCategories()
  return useMemo(() => {
    return (categories || []).reduce((memo, category) => {
      category.values.forEach(cv => memo[cv.categoryValueId] = { category, categoryValue: cv })
      return memo
    }, {} as { [k: string]: CategoryValueWithCategory })
  }, [categories])
}

// -----------------------------------
// Category mutations
// -----------------------------------

type AddCategoryParams = {
  name: string,
  values: string[]
}

export function useAddCategoryMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ name, values }: AddCategoryParams) => createCategory(name, values),
    onSuccess: async _ => {
      queryClient.invalidateQueries({ queryKey: ['categories'] })
    },
  })
}

type RenameCategoryParams = {
  categoryId: string,
  name: string
}

export function useRenameCategoryMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ categoryId, name }: RenameCategoryParams) => renameCategory(categoryId, name),
    onSuccess: async _ => {
      queryClient.invalidateQueries({ queryKey: ['categories'] })
    },
  })
}


type DeleteCategoryParams = {
  categoryId: string,
}

export function useDeleteCategoryMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ categoryId }: DeleteCategoryParams) => deleteCategory(categoryId),
    onSuccess: async _ => {
      queryClient.invalidateQueries({ queryKey: ['categories'] })
    },
  })
}

// -----------------------------------
// Category Value mutations
// -----------------------------------
type EditCategoryValueParams = {
  categoryId: string,
  categoryValueId: string,
  name: string,
  description: string | null
}

export function useEditCategoryValueMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: EditCategoryValueParams) =>
      editCategoryValue(params.categoryId, params.categoryValueId, params.name, params.description),
    onSuccess: async _ => {
      queryClient.invalidateQueries({ queryKey: ['categories'] })
    },
  })
}

type DeleteCategoryValueParams = {
  categoryId: string,
  categoryValueId: string,
}

export function useDeleteCategoryValueMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: DeleteCategoryValueParams) =>
      deleteCategoryValue(params.categoryId, params.categoryValueId),
    onSuccess: async _ => {
      queryClient.invalidateQueries({ queryKey: ['categories'] })
    },
  })
}

type MoveCategoryValueParams = {
  categoryValueId: string,
  originCategoryId: string,
  destinationCategoryId: string
}

export function useMoveCategoryValueMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: MoveCategoryValueParams) =>
      reassignCategoryValue(params.categoryValueId, params.originCategoryId, params.destinationCategoryId),
    onSuccess: async _ => {
      queryClient.invalidateQueries({ queryKey: ['categories'] })
    },
  })
}


type AddCategoryValuesParams = {
  categoryId: string,
  values: string[]
}

export function useAddCategoryValuesMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ categoryId, values }: AddCategoryValuesParams) => createCategoryValues(categoryId, values),
    onSuccess: async _ => {
      queryClient.invalidateQueries({ queryKey: ['categories'] })
    },
  })
}
