import { ReactNode, useRef } from 'react';
import { IconifyIcon } from '@iconify/react';
import { SnackbarProvider, SnackbarKey } from 'notistack';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, GlobalStyles, Collapse } from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------

function SnackbarStyles() {
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            width: '100%',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: "50vh",
            color: theme.palette.text.white,
            backgroundColor: theme.palette.primary.dark,

            '&.SnackbarItem-variantError': {
              color: theme.palette.text.white,
              backgroundColor: theme.palette.bg.red,
            },

            '&.SnackbarItem-variantWarning': {
              color: theme.palette.text.white,
              backgroundColor: theme.palette.warning.dark,
            },

            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantInfo':
              {
                // todo: for now, all variations look this
                color: theme.palette.text.white,
                backgroundColor: theme.palette.primary.darker,
              },
            [theme.breakpoints.up('md')]: {
              minWidth: 240,
            },
          },
            '& .SnackbarItem-message': {
              padding: 0
            },
          '& .SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            '& svg': { width: 20, height: 20 },
          },
        },
      }}
    />
  );
}

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function NotistackProvider({ children }: Props) {
  const { themeDirection } = useSettings();

  const isRTL = themeDirection === 'rtl';

  const notistackRef = useRef<any>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={10_000}
        TransitionComponent={isRTL ? Collapse : undefined}
        variant="success" // Set default variant
        hideIconVariant
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}