import { TaskGroup } from '../../../@types/task.ts'
import { Stack, TableCell, TableRow, Typography } from '@mui/material'
import Iconify from '../../Iconify.tsx'

type TaskListGroupHeaderRowProps = {
  taskGroup: TaskGroup,
  isCollapsed: boolean,
  onCollapsedChange: (isCollapsed: boolean) => void
}

export function TaskListGroupHeaderRow({ taskGroup, isCollapsed, onCollapsedChange }: TaskListGroupHeaderRowProps) {
  const handleCollapseClick = () => onCollapsedChange(!isCollapsed)
  return (
    <TableRow>
      <TableCell
        colSpan={5}
        sx={{
          pt: 4,
          pb: 2,
          paddingLeft: '0 !important',
        }}
      >
        <Stack direction='row' spacing={1} alignItems='center'>
          <Iconify
            icon='ph:caret-down-fill'
            height={18}
            width={18}
            sx={{
              cursor: 'pointer',
              transition: '100ms ease-in-out transform',
              transform: isCollapsed ? 'rotateZ(-90deg)' : '',
            }}
            onClick={handleCollapseClick}
          />
          <Typography variant='h3' sx={{ userSelect: 'none' }}>{taskGroup.displayName}</Typography>
        </Stack>
      </TableCell>
    </TableRow>
  )
}