import { styled } from '@mui/material/styles'
import { Box, Stack } from '@mui/material'
import { AdminAnalyticsContext, LocalCustomFilter } from '../../contexts/AdminAnalyticsContext'
import { useContext } from 'react';
import Iconify from '../Iconify'
import { AnalyticsFilterType } from '../../@types/analytics/analytics-asset'
import { CategoryValueCustomFilter } from './filters/CategoryValueCustomFilter'
import { OccurrenceCustomFilter } from './filters/OccurrenceCustomFilter'
import { SharingCustomFilter } from './filters/SharingCustomFilter'


export function AnalyticsCustomFilter({ filter }: { filter: LocalCustomFilter }) {
  const { removeCustomFilter } = useContext(AdminAnalyticsContext)
  const handleRemoveFilterClick = () => removeCustomFilter(filter.id)
  return (
    <FilterBox>
      <Stack direction='row' alignItems='center' spacing={1}>
        <Box><FilterContent filter={filter} /></Box>
        <Iconify icon='eva:close-fill' sx={{ cursor: 'pointer' }} onClick={handleRemoveFilterClick} />
      </Stack>
    </FilterBox>
  )
}


function FilterContent({ filter }: { filter: LocalCustomFilter }) {
  if (
    filter.filterType === AnalyticsFilterType.HAS_CATEGORY ||
    filter.filterType === AnalyticsFilterType.N_HAS_CATEGORY
  ) {
    return <CategoryValueCustomFilter filter={filter} />
  }

  if (filter.filterType === AnalyticsFilterType.OCCURRENCE) {
    return <OccurrenceCustomFilter filter={filter} />
  }

  if (filter.filterType === AnalyticsFilterType.SHARING) {
    return <SharingCustomFilter filter={filter} />
  }

  return <></>
}


const FilterBox = styled(Box)(({ theme }) => {
  const isLight = theme.palette.mode === 'light'
  const borderColor = isLight ? 'transparent' : theme.palette.grey[500_32]
  const bgColor = isLight ? theme.palette.primary.light : theme.palette.primary.dark
  return {
    borderRadius: theme.spacing(1),
    padding: '4px 8px',
    border: `0px solid ${borderColor}`,
    backgroundColor: bgColor,
    display: 'inline-block',
    cursor: 'default',
  }
})
