import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { useContext, useMemo } from 'react'
import { BulkAssetPermissionForm } from './permissions/BulkAssetPermissionForm.tsx'
import { useSearchSelectedAssetsContext } from '../SearchSelectedAssetsContext.tsx'
import { DEFAULT_ASSET_SHARES_DATA, useBulkAssetSharesState } from '../../../hooks/useSharing.ts'
import { DialogContext, DialogProvider } from '../../../contexts/DialogContext.tsx'

export function BulkEditPermissionsButton(){
 return (
   <DialogProvider>
     <TriggerButton />
     <PermissionDialog />
   </DialogProvider>
 )
}
function TriggerButton(){
  const { setOpen } = useContext(DialogContext)
  const handleUpdateClick = () => setOpen(true)
  return (
    <Tooltip title="Edit Permissions">
      <IconButton onClick={handleUpdateClick}>
        <Iconify icon={'ph:users'} width={20} height={20} />
      </IconButton>
    </Tooltip>
  )
}

function PermissionDialog(){
  const { open, setOpen } = useContext(DialogContext)
  const { selectedAssetIds } = useSearchSelectedAssetsContext()
  const selectedAssetIdsArr = useMemo(() => Array.from(selectedAssetIds), [selectedAssetIds])
  const { data: response } = useBulkAssetSharesState(selectedAssetIdsArr, open)

  const handleClose = () => setOpen(false)

  const { shares, generalAccessType, generalAccessShareLevel } = response || DEFAULT_ASSET_SHARES_DATA

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullWidth={true}
      onClose={handleClose}
      sx={{zIndex: 1401}}
    >
      <DialogTitle>
        <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
          <IconButton onClick={handleClose}>
            <Iconify icon='eva:close-fill' />
          </IconButton>
        </Box>

        <Typography variant='h2' paragraph mb={4}>Edit Permissions for {selectedAssetIds.size} Asset(s)</Typography>
      </DialogTitle>

      <DialogContent sx={{p: 0}}>
        <BulkAssetPermissionForm
          assetIds={selectedAssetIdsArr}
          shares={shares}
          generalAccessType={generalAccessType}
          generalAccessShareLevel={generalAccessShareLevel}
        />
      </DialogContent>
    </Dialog>
  )
}