import { Asset } from '../../../@types/asset.ts'
import { Stack, Tooltip, Typography } from '@mui/material'
import Iconify from '../../Iconify.tsx'

export function ThirdPartyPostfix({ asset }: { asset: Asset }) {
  if (!asset?.isThirdParty) return <></>
  return (

    <Typography variant='standard'>
      <Stack spacing={.5} direction='row' alignItems='center'>
        <i>&nbsp;- 3rd Party </i>
        <Tooltip title='3rd Party Assets are managed by others outside your company. Take caution when sharing.'>
          <Stack>
            <Iconify icon='eva:question-mark-circle-fill' />
          </Stack>
        </Tooltip>
      </Stack>
    </Typography>
  )
}