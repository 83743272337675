// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, Card, IconButton, InputAdornment, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import Iconify from "../Iconify";
import { styled } from "@mui/material/styles";
import { useState } from "react";

// ----------------------------------------------------------------------

const MAX_STRENGTH = 5
const SEED_ARRAY = Array(MAX_STRENGTH).fill(0).map((x,i) => i+1)


type IProps = {
  name: string;
};

type Props = IProps & TextFieldProps;

export default function RHFPasswordField({ name, ...other }: Props) {
  const { control } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const touched = fieldState.isDirty
        const hasUpper = touched && !fieldState.error?.types?.minUppercase
        const hasLower = touched && !fieldState.error?.types?.minLowercase
        const hasNumber = touched && !fieldState.error?.types?.minNumber
        const hasSymbol = touched && !fieldState.error?.types?.minSymbol
        const minLength = touched && !fieldState.error?.types?.min
        const strength = [hasUpper, hasLower, hasNumber, hasSymbol, minLength].filter(x => x).length
        return (
          <Stack direction="column" spacing={1}>
            <TextField
              {...field}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              value={field.value}
              {...other}
            />
            <StrengthIndicator strength={strength} />
            <Stack direction="column">
              <PasswordRequirement text={"Must have 1 uppercase character"} satisfied={hasUpper}/>
              <PasswordRequirement text={"Must have 1 lowercase character"} satisfied={hasLower}/>
              <PasswordRequirement text={"Must have 1 number"} satisfied={hasNumber}/>
              <PasswordRequirement text={"Must have 1 symbol"} satisfied={hasSymbol}/>
              <PasswordRequirement text={"Must be at least 12 characters"} satisfied={minLength}/>
            </Stack>
          </Stack>
        )
      }}
    />
  );
}

const StrengthBar = styled(Box)(({ theme }) => ({
  height: 2,
  background: theme.palette.action.disabledBackground,
  flex: 1
}));

function StrengthIndicator({strength} : {strength: number}){
  return (
    <Stack direction="row" spacing={.5}>
      {
        SEED_ARRAY.map(x => (
          <StrengthBar key={x} sx={{bgcolor: x <= strength ? "primary.main" : "bg.red" }}/>
        ))
      }
    </Stack>
  )
}

function PasswordRequirement({ text, satisfied }: { text: string, satisfied: boolean }) {
  return (
    <Typography
      sx={{color: satisfied ? "primary.main" : "text.red"}}
      variant="small"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Iconify icon={satisfied ? "eva:checkmark-circle-2-outline" : "eva:close-outline"}/>
        <Box>{text}</Box>
      </Stack>
    </Typography>
  )
}