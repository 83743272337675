import { Task } from '../../../@types/task.ts'
import { alpha, useTheme } from '@mui/material/styles'
import { Avatar, Box, Button, Stack, Typography } from '@mui/material'
import { FormProvider, RHFTextField } from '../../hook-form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useCreateTaskComment } from '../../../hooks/useTasks.ts'
import useAuth from '../../../hooks/useAuth.ts'

type AddCommentForm = { content: string }

export function DrawerAddCommentSection({ task }: { task?: Task }) {
  // hooks
  const theme = useTheme()
  const { mutateAsync: addComment } = useCreateTaskComment()
  const methods = useForm<AddCommentForm>({
    defaultValues: {content: ""},
    resolver: yupResolver(Yup.object().shape({
      content: Yup.string().required().max(2048),
    })),
    reValidateMode: 'onChange',
  })

  // handlers
  const handleSubmit = (data: AddCommentForm) => {
    addComment({ taskId: task!!.taskId, content: data.content })
    methods.reset({ content: "" })
  }

  const canSubmit = methods.formState.isValid

  return (
    <FormProvider methods={methods}>
      <Stack
        direction='column'
        spacing={1}
        pl={2}
        pr={2}
        py={2}
      >
        <Stack
          direction='row'
          spacing={1}
        >
          <Box>
            <CurrentUserAvatar />
          </Box>
          <RHFTextField
            name='content'
            multiline
            fullWidth
            minRows={4}
            placeholder='Add a comment'
            sx={{
              '& .MuiInputBase-input': {
                ...theme.typography.body2,
              },
            }}
          />
        </Stack>
        <Stack direction='row' justifyContent='end'>
          <Button
            variant='contained'
            disabled={!canSubmit}
            onClick={methods.handleSubmit(handleSubmit)}
          >
            Add Comment
          </Button>
        </Stack>

      </Stack>
    </FormProvider>
  )
}

function CurrentUserAvatar() {
  const theme = useTheme()
  const auth = useAuth()
  const currentUser = auth?.user
  const initial = (currentUser?.firstName || "Unknown")[0]
  return (
    <Avatar sx={{ height: 32, width: 32, bgcolor: alpha(theme.palette.primary.main, .35) }}>
      <Typography variant="small">{initial}</Typography>
    </Avatar>
  )
}