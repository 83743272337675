import { useParams } from 'react-router'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react';
import { AssetContext } from '../../contexts/AssetContext'
import LoadingScreen from '../../components/LoadingScreen'
import Page from '../../components/Page'
import Iconify from '../../components/Iconify'
import { AssetDetailsPreview } from '../../components/asset/preview/AssetDetailsPreview'
import { useNavigate } from 'react-router-dom'
import { AssetUpdateFormData, AssetUpdateFormProvider } from '../../components/asset/form/AssetUpdateFormProvider'
import AssetUpdateForm from '../../components/asset/form/AssetUpdateForm'
import { useSnackbar } from 'notistack'
import AssetPalettesCmp from '../../components/asset-palette/AssetPalettes'
import { OverridePreviewButton } from '../../components/asset/preview/OverridePreviewButton'
import { VisibleForPermission } from '../../guards/VisibleForPermission'
import { AssetPermission } from '../../@types/asset'
import AssetLabelsCmp from '../../components/asset-labels/AssetLabels'

export default function AssetEditSingle() {
  const { assetId } = useParams()
  const { loadAsset, updateAsset, retryTasks, assets } = useContext(AssetContext)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const assetUnderEdit = useMemo(() => assetId && assets[assetId], [assets])

  // trigger the initial load to make sure we have the latest asset
  useEffect(() => {
    assetId && loadAsset(assetId)
  }, [assetId])

  if (!assetUnderEdit) {
    return <LoadingScreen/>
  }

  const detailsRoute = `/assets/detail/${assetUnderEdit.assetId}`
  const onCancel = async () => navigate(detailsRoute)
  const onSubmit = async (data: AssetUpdateFormData) => {
    if (assetUnderEdit === null) return
    await updateAsset(assetUnderEdit.assetId, {
      ...data,
      assetType: assetUnderEdit.assetType, // not changeable yet
    })
    enqueueSnackbar((<>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="smallHighlight">HOORAY!</Typography>
        <Typography component="div" variant="small" noWrap>Your asset has been updated!</Typography>
        <Stack sx={{cursor: 'pointer'}} onClick={() => closeSnackbar()}><Iconify icon="eva:close-fill"/></Stack>
      </Stack>
    </>),  { autoHideDuration: 3000 })
    navigate(detailsRoute)
  }

  return (
    <AssetUpdateFormProvider assetUnderEdit={assetUnderEdit}>
      <Page title="Edit Asset">
        <Button
          variant="text"
          size={"small"}
          onClick={() => navigate(-1)}
          sx={{ mb: 1, "& .MuiButton-startIcon": { marginRight: "4px" } }}
          startIcon={<Iconify icon={"eva:chevron-left-fill"}/>}
        >Back</Button>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <Stack direction="column" spacing={2}>
              <AssetDetailsPreview asset={assetUnderEdit}/>
              <VisibleForPermission permission={AssetPermission.OVERRIDE_PREVIEW} level={assetUnderEdit.accessLevel}>
                <OverridePreviewButton asset={assetUnderEdit} />
              </VisibleForPermission>
              <Box mt={2}>
                <AssetLabelsCmp asset={assetUnderEdit} />
              </Box>
              <AssetPalettesCmp asset={assetUnderEdit} />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <AssetUpdateForm
              assetUnderEdit={assetUnderEdit}
              onCancel={onCancel}
              onSubmit={onSubmit}
            />
          </Grid>
        </Grid>

      </Page>
    </AssetUpdateFormProvider>
  )
}