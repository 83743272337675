import { Box, Divider, Typography } from '@mui/material'
import { useCompanyImageFormatCustomization } from '../../hooks/useCompanySettings.ts'
import { ImageFormats } from './ImageFormats.tsx'

export function ImageFormatCustomization(){
  const { data: settings } = useCompanyImageFormatCustomization()
  return (
    <Box>
      <Typography
        variant='h2'
      >
        Image Formats
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant='standard' paragraph mb={4}>
        Customize how images can be downloaded by users in Masset
      </Typography>

      <ImageFormats settings={settings} />
    </Box>
  )
}