import { FormProvider, RHFCheckbox } from '../hook-form'
import RHFColorField from '../hook-form/RHFColorField'
import { useForm } from 'react-hook-form'
import { CompanyBrandResponse } from '../../@types/company'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as Yup from 'yup'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ContentRoomPreview } from './preview/ContentRoomPreview'
import { useUpdateCompanyBranding } from '../../hooks/useCompanySettings'
import { useSnackbar } from 'notistack'
import { useIsFeatureEnabled } from '../../hooks/useFeatures'

export function BrandColors({ brandInfo }: { brandInfo?: CompanyBrandResponse }) {
  // hooks
  const { mutateAsync: updateBrand } = useUpdateCompanyBranding()
  const { enqueueSnackbar } = useSnackbar()
  const contentRoomsEnabled = useIsFeatureEnabled('content-rooms')
  const methods = useForm<BrandForm>({
    values: getDefaultValues(brandInfo),
    resolver: validator,
    mode: 'onChange',
  })

  // handlers
  const handleSave = async (data: BrandForm) => {
    await updateBrand({
      primary: data.primary,
      secondary: data.secondary,
      applyToInternal: data.applyToInternal,
      applyToContentRooms: data.applyToContentRooms
    })
    enqueueSnackbar(
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography component='div' variant='small' noWrap>Your company branding has been updated.</Typography>
      </Stack>,
    )
  }

  // calculated props
  const { handleSubmit, watch } = methods
  const { primary, secondary } = watch()

  return (
    <FormProvider methods={methods}>
      <Stack spacing={6}>
        <Box>
          <Typography variant='h4' mb={2}>Brand colors</Typography>
          <Stack spacing={2}>
            <RHFColorField
              name='primary'
              label={`Primary Brand Color`}
              placeholder='Select your primary brand color'
              size='small'
            />

            <RHFColorField
              name='secondary'
              label='Secondary Brand Color'
              placeholder='Select your secondary brand color'
              size='small'
            />
            <Box>
              <RHFCheckbox name="applyToInternal" label="Apply branding to Masset application for internal users" />
              <RHFCheckbox name="applyToContentRooms" label="Apply branding to external shares" />
            </Box>
          </Stack>
        </Box>



        <Box>
          <Button
            variant='contained'
            size='small'
            onClick={handleSubmit(handleSave)}
          >
            Update Brand Settings
          </Button>
        </Box>

        {
          contentRoomsEnabled && (
            <Box>
              <Typography variant='h4' mb={2}>Content Room Preview</Typography>
              <ContentRoomPreview primary={primary} secondary={secondary} />
            </Box>
          )
        }

      </Stack>

    </FormProvider>
  )
}

type BrandForm = {
  primary: string,
  secondary: string,
  applyToInternal: boolean,
  applyToContentRooms: boolean
}

function getDefaultValues(brand?: CompanyBrandResponse) {
  return {
    primary: brand?.primary || '',
    secondary: brand?.secondary || '',
    applyToInternal: brand?.applyToInternal || false,
    applyToContentRooms: brand?.applyToContentRooms || false
  }
}

const validator = yupResolver(Yup.object().shape({
  primary: Yup.string().nullable(),
  secondary: Yup.string().nullable(),
}))

