// @mui
import { styled } from '@mui/material/styles';
import { Alert, Box, Card, Container, Paper, Stack, Typography } from '@mui/material';
// routes
// import { PATH_AUTH } from '../../routes/paths';
// hooks
// components
import Page from '../../components/Page';
import { useEffect, useState } from "react";
import AcceptInviteForm from "./AcceptInviteForm";
import { useParams } from "react-router";
import { checkInviteToken } from "../../clients/InviteTokenClient";
import { useNavigate } from "react-router-dom";
import { InviteTokenStatus } from "../../@types/invite-token";
import MassetLogoFull from "../../components/MassetLogoFull.tsx";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  background: theme.palette.background.default
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 425,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AcceptInvite() {
  const { inviteToken = "" } = useParams()
  const [loading, setLoading] = useState(true)
  const [isValidToken, setIsValidToken] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (inviteToken) {
      checkInviteToken(inviteToken)
        .then((dto) => {
          setIsValidToken(dto.status === InviteTokenStatus.VALID)
          if(dto.status === InviteTokenStatus.USER_ALREADY_INITIALIZED){
            navigate("/login")
          }
        })
        .catch((e) => {
          setIsValidToken(false)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [inviteToken])


  return (
    <Page title="Account Setup">
      <RootStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            <Paper elevation={4} sx={{ p: 4 }}>
              <MassetLogoFull sx={{ mb: 4, px: 8 }} />

              {/* if the token is invalid */}
              {
                !isValidToken && !loading && (<>
                  <Alert severity="warning" variant="outlined">
                    <Typography> Your invite is invalid or expired. </Typography>
                    <Typography variant="small"> If you received your invite via email, it may need to be resent. </Typography>
                  </Alert>
                </>)
              }

              {/* if the token is valid */}
              {
                isValidToken && (<>
                  <Stack direction="column" alignItems="center" spacing={2} sx={{ mb: 3 }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h3" align="center">
                        Welcome to Masset!
                      </Typography>
                    </Box>

                    <Typography align="center">
                      To get started, enter your information below.
                    </Typography>
                  </Stack>

                  <AcceptInviteForm inviteToken={inviteToken}/>
                </>)
              }
            </Paper>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
