import { useForm } from 'react-hook-form'
import { FormProvider, RHFSwitch, RHFTextField } from '../../../hook-form'
import { Box, Button, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useUpdateAssetExternalShare } from '../../../../hooks/useAssetExternalShares.ts'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { AssetExternalShare } from '../../../../@types/asset-external-share.ts'

type AssetContentRoomFormValues = {
  name: string,
  passwordProtected: boolean
}

type Props = {
  share: AssetExternalShare,
  onCancel?: () => void,
  onUpdated?: () => void
}

const FormShape = Yup.object().shape({
  name: Yup.string().required(),
})

const formValidationResolver = yupResolver(FormShape)

export function EditAssetExternalShareForm({ share, onCancel, onUpdated }: Props) {
  const { mutateAsync: updateAssetContentRoom, isLoading } = useUpdateAssetExternalShare()
  const formMethods = useForm<AssetContentRoomFormValues>({
    defaultValues: { name: share.name, passwordProtected: share.isPasswordProtected },
    resolver: formValidationResolver,
    mode: 'onChange',
  })
  const { handleSubmit: rhfHandleSubmit, formState: { isValid } } = formMethods
  const handleSubmit = (formData: AssetContentRoomFormValues) =>
    updateAssetContentRoom({
      ...formData,
      assetId: share.assetId,
      externalShareId: share.externalShareId
    }).then(() => {
      onUpdated && onUpdated()
    })
  const handleCancel = () => onCancel && onCancel()

  return (
    <FormProvider methods={formMethods} onSubmit={rhfHandleSubmit(handleSubmit)}>
      <Box
        sx={{
          paddingY: 3,
          paddingX: 1,
        }}>
        <Stack spacing={1}>
          <RHFTextField name='name' size='small' label='Name' autoFocus />
          <RHFSwitch name='passwordProtected'
                     label={<Typography variant='small'>Password protected?</Typography>} />
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="end">
          <Button
            variant='text'
            onClick={handleCancel}
            size="small"
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            loadingPosition='start'
            loading={isLoading}
            variant='outlined'
            color='primary'
            size="small"
            onClick={rhfHandleSubmit(handleSubmit)}
            disabled={!isValid || isLoading}
          >
            Save
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  )
}