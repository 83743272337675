import {
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper, Tooltip,
} from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { useContext, useState } from 'react'
import { CategorySort, SearchContext } from '../../../contexts/SearchContext.tsx'

export function SearchCategorySortButton() {
  const { categorySort, setCategorySort } = useContext(SearchContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleAlphabeticalClick = () => {
    setCategorySort(CategorySort.ALPHABETICALLY)
    handleClose()
  }
  const handleUsageClick = () => {
    setCategorySort(CategorySort.USAGE)
    handleClose()
  }

  return (
    <>
      <IconButton
        size='small'
        onClick={handleButtonClick}
      >
        <Iconify icon='ph:sort-ascending' />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList dense>
                  <MenuItem
                    onClick={handleAlphabeticalClick}>
                    <ListItemIcon>
                      {categorySort == CategorySort.ALPHABETICALLY && (
                        <Iconify icon='ph:check' />
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      Alphabetical
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={handleUsageClick}

                  >
                    <ListItemIcon>
                      {categorySort == CategorySort.USAGE && (
                        <Iconify icon='ph:check' />
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      Usage
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}