import { AudienceType } from '../../@types/analytics/analytics-asset.ts'
import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import Iconify from '../Iconify.tsx'

type AudienceSelectProps = {
  value: AudienceType,
  onChange: (audience: AudienceType) => void
}

export function AudienceSelect({ value, onChange }: AudienceSelectProps) {
  const handleChange =
    (event: SelectChangeEvent) => onChange(event.target.value as unknown as AudienceType)
  const renderLabel = (value: AudienceType) => 'Audience: ' + DISPLAY_VALUES[value]
  return (
    <Select
      size='small'
      value={value + ''}
      defaultValue={value + ''}
      onChange={handleChange}
      sx={{
        '& fieldset': {
          border: 'none',
        },
        '& .MuiSvgIcon-root': {
          color: 'primary.main',
        },
      }}
      SelectDisplayProps={{
        style: {
          display: 'flex',
        },
      }}
      renderValue={(value: string) => {
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            <Iconify icon='ph:globe' fontSize={20} />
            <Typography sx={{ color: 'primary.main' }} variant='smallHighlight'>
              {renderLabel(value as unknown as AudienceType)}
            </Typography>
          </Stack>
        )
      }}

    >
      <MenuItem value={AudienceType.BOTH}>
        <Typography variant='small'>{DISPLAY_VALUES[AudienceType.BOTH]}</Typography>
      </MenuItem>
      <MenuItem value={AudienceType.INTERNAL}>
        <Typography variant='small'>{DISPLAY_VALUES[AudienceType.INTERNAL]}</Typography>
      </MenuItem>
      <MenuItem value={AudienceType.EXTERNAL}>
        <Typography variant='small'>{DISPLAY_VALUES[AudienceType.EXTERNAL]}</Typography>
      </MenuItem>
    </Select>
  )
}

const DISPLAY_VALUES = {
  [AudienceType.BOTH]: 'Everyone',
  [AudienceType.INTERNAL]: 'Employees',
  [AudienceType.EXTERNAL]: 'External Sharing',
}