import { useTheme } from '@mui/material/styles'
import { HostedContentRoomModalLayout } from '../../layouts/hosted-content-room/HostedContentRoomModalLayout.tsx'
import Page from '../../components/Page.tsx'
import { Paper, Stack, Typography } from '@mui/material'
import Iconify from '../../components/Iconify.tsx'
import { m } from 'framer-motion'
import { varBounce } from '../../components/animate'

export function HostedContentRoom429(){
  const theme = useTheme()
  return (
    <HostedContentRoomModalLayout>
      <Page title='Too Busy' >
        <Paper
          elevation={2}
          sx={{
            p: 6,
            borderRadius: 2,
            textAlign: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Iconify
            icon='ph:hand-palm'
            sx={{
              color: theme.palette.primary.main,
              height: 96,
              width: 96,
              position: 'absolute',
              top: -64,
              left: '50%',
              marginLeft: '-48px',
            }}
          />
          <Stack spacing={2}>
            <m.div variants={varBounce().in}>
              <Typography variant='h2' paragraph>
                Whoa, whoa. Let's take a pause!
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography textAlign="left" sx={{ color: 'text.secondary' }}>
                It looks like we're seeing elevated levels of traffic on this share.
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography textAlign="left" sx={{ color: 'text.secondary' }}>
                We've <Typography color="primary" component="span">temporarily locked it</Typography> until traffic returns to normal. Please try back in a few minutes.
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography textAlign="left" sx={{ color: 'text.secondary' }}>
                If you believe this has been done in error, please reach out to your sender.
              </Typography>
            </m.div>
          </Stack>
        </Paper>
      </Page>
    </HostedContentRoomModalLayout>
  )
}
