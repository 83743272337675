import { ImageDownloadSize } from '../../../../@types/company.ts'
import { useImageFormatOptions } from '../../../../hooks/useBootstrap.ts'
import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'

type ImageFormatSizeSelectProps = {
  value: string,
  labelId?: string,
  onChange?: (size: ImageDownloadSize) => void
  onOpen?: () => void
  onClose?: () => void
}

export function ImageFormatSizeSelect({
                                        value,
                                        labelId,
                                        onChange,
                                        onOpen,
                                        onClose,
                                      }: ImageFormatSizeSelectProps) {
  const { sizes } = useImageFormatOptions()

  const handleSizeIndexChange = (ev: SelectChangeEvent) => {
    const newSize = sizes.find(it => it.imageFormatSizeId == ev.target.value)!!
    onChange && onChange(newSize)
  }
  const handleSelectOpen = () => onOpen && onOpen()
  const handleSelectClose = () => onClose && onClose()

  if (!sizes || sizes.length == 0) return <></>
  return (
    <Select
      id='size'
      labelId={labelId}
      size='xs'
      variant='outlined'
      onChange={handleSizeIndexChange}
      onOpen={handleSelectOpen}
      onClose={handleSelectClose}
      value={value}
      renderValue={(selected: string) => {
        const size = sizes.find(it => it.imageFormatSizeId == selected)
        return (
          <Typography variant='inherit'>
            {size?.name} ({size?.width}x{size?.height})
          </Typography>
        )
      }}
      sx={{ minWidth: 100 }}
    >
      {
        sizes.map((it) => {
          return (
            <MenuItem
              key={it.imageFormatSizeId}
              value={it.imageFormatSizeId}
            >
              <Stack
                direction='row'
                spacing={4}
                justifyContent='space-between'
                alignItems='center'
                sx={{ width: '100%' }}
              >
                <Typography variant='inherit'>
                  {it.name}
                </Typography>
                <Typography variant='small' color='text.deemphasized'>
                  {it.width}x{it.height}
                </Typography>
              </Stack>
            </MenuItem>
          )
        })
      }
    </Select>
  )
}
