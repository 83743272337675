import {
  DOWNLOAD_FORMAT_LABELS,
  ImageDownloadFileType,
  ImageDownloadSize,
  ImageFormatSettingsResponse,
} from '../../@types/company.ts'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { useUpdateCompanyImageFormatSettings } from '../../hooks/useCompanySettings.ts'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { FormProvider, RHFCheckbox } from '../hook-form'
import RHFAutoComplete from '../hook-form/RHFAutocomplete.tsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { RHFSizesField } from './image-formats/RHFSizesField.tsx'


export function ImageFormats({ settings }: { settings?: ImageFormatSettingsResponse }) {
  const { mutateAsync: updateImageFormatSettings } = useUpdateCompanyImageFormatSettings()
  const { enqueueSnackbar } = useSnackbar()
  const methods = useForm<ImageFormatForm>({
    resolver: yupResolver(validationSchema),
    values: getDefaultValues(settings),
    mode: 'onChange',
  })

  useEffect(() => methods.reset(getDefaultValues(settings)), [settings])

  const handleSave = async (data: ImageFormatForm) => {
    const sizes = data.permittedSizes
      .map(it => ({ ...it, height: parseInt(it.height+"", 10), width: parseInt(it.width+"", 10) }))
      .filter(it => it.name || it.height || it.width)
      .map((it, idx) => ({...it, displayOrder: idx}))
    await updateImageFormatSettings({
      applyToInternal: data.applyToInternal,
      permittedFormats: data.permittedFormats.map(it => it.value),
      permittedSizes: sizes,
    })
    enqueueSnackbar(
      <Stack spacing={1} direction='row' alignItems='center'>
        <Typography component='div' variant='small' noWrap>Your company branding has been updated.</Typography>
      </Stack>,
    )
  }

  const { applyToInternal } = methods.watch()
  const isDisabled = !applyToInternal
  const disabledStyles = isDisabled ? { pointerEvents: 'none', opacity: .35 } : {}
  const formSubmitDisabled = !methods.formState.isValid

  return (
    <FormProvider methods={methods}>
      <Stack spacing={4}>
        <Box>
          <RHFCheckbox name='applyToInternal' label='Enable custom download formats for images' />
        </Box>

        <Box sx={{ ...disabledStyles }}>
          <Typography variant='h4' paragraph>Custom File Types</Typography>
          <RHFAutoComplete
            label='File Types'
            size='small'
            name='permittedFormats'
            options={DOWNLOAD_FORMAT_OPTIONS}
            variant='outlined'
            multiple={true}
            isOptionEqualToValue={(option: DownloadFormatOption, value: DownloadFormatOption) => {
              return option.value == value.value
            }}
            sx={{ width: 350 }}
          />
        </Box>


        <Box sx={{ ...disabledStyles }}>
          <Typography variant='h4' paragraph>Custom Resolutions</Typography>
          <RHFSizesField name='permittedSizes' />
        </Box>

        <Box>
          <Button
            variant='contained'
            size='small'
            onClick={methods.handleSubmit(handleSave)}
            disabled={formSubmitDisabled}
          >
            Update Format Settings
          </Button>
        </Box>

      </Stack>

    </FormProvider>

  )
}



type ImageFormatForm = {
  applyToInternal: boolean,
  permittedFormats: DownloadFormatOption[],
  permittedSizes: ImageDownloadSize[]
}

function getDefaultValues(settings?: ImageFormatSettingsResponse) {
  return {
    applyToInternal: settings?.applyToInternal || false,
    permittedFormats: (settings?.permittedFormats || []).map(it => {
      return { label: DOWNLOAD_FORMAT_LABELS[it], value: it }
    }),
    permittedSizes: settings?.permittedSizes || [],
  }
}

type DownloadFormatOption = {
  label: string,
  value: ImageDownloadFileType
}

const DOWNLOAD_FORMAT_OPTIONS = [
  { label: DOWNLOAD_FORMAT_LABELS[ImageDownloadFileType.WEBP], value: ImageDownloadFileType.WEBP },
  { label: DOWNLOAD_FORMAT_LABELS[ImageDownloadFileType.PNG], value: ImageDownloadFileType.PNG },
  { label: DOWNLOAD_FORMAT_LABELS[ImageDownloadFileType.JPG], value: ImageDownloadFileType.JPG },
] as DownloadFormatOption[]

const validationSchema = yup.object().shape({
  permittedFormats: yup.array().min(1, "At least one format must be selected"),
  permittedSizes: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Name is required"),
      height: yup
        .number()
        .typeError("Height must be a number")
        .min(1, "Height must be at least 1")
        .max(4096, "Height cannot exceed 4096"),
      width: yup
        .number()
        .typeError("Width must be a number")
        .min(1, "Width must be at least 1")
        .max(4096, "Width cannot exceed 4096"),
    })
  ),
});