import { Button } from '@mui/material'
import Iconify from '../../../Iconify.tsx'
import * as React from 'react'
import { Asset, AssetStorageType, AssetType } from '../../../../@types/asset.ts'
import { useImageFormatOptions } from '../../../../hooks/useBootstrap.ts'
import { ThirdPartyPostfix } from '../ThirdPartyPostfix.tsx'
import { UserType } from '../../../../@types/user.ts'
import useAuth from '../../../../hooks/useAuth.ts'
import { useDownload } from '../../../../hooks/useDownload.tsx'
import { AssetCustomFormatDownloadButton } from './AssetCustomFormatDownloadButton.tsx'

type AssetDownloadButtonProps = {
  asset: Asset
}

export function AssetDownloadButton({ asset }: AssetDownloadButtonProps) {
  const { user } = useAuth()
  const { enabled: customFormatsEnabled } = useImageFormatOptions()
  const { consumeAsset } = useDownload()

  const isAdmin = user?.userType == UserType.ADMIN
  const downloadDisabled = asset?.isInternal && !isAdmin
  const isExternal = asset.storageType === AssetStorageType.EXTERNAL_HTTP
  const isImage = asset.assetType == AssetType.IMAGE

  if (isExternal) return <></>
  if (isImage && customFormatsEnabled) return <AssetCustomFormatDownloadButton asset={asset} />

  return (
    <Button
      variant='contained'
      startIcon={<Iconify icon={'eva:download-fill'} />}
      fullWidth
      onClick={() => consumeAsset(asset)}
      disabled={downloadDisabled}
    >
      Download
      <ThirdPartyPostfix asset={asset} />
    </Button>
  )
}


