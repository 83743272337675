import React, { useState } from 'react'
import { IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import MenuPopover from '../../MenuPopover.tsx'
import { Task, TaskClosedReason } from '../../../@types/task.ts'
import { useSetTaskClosed } from '../../../hooks/useTasks.ts'


type MoreActionsButtonProps = {
  task: Task,
  onOpen: () => void
  onClose: () => void
}

export function MoreActionsButton({ task, onOpen, onClose }: MoreActionsButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { mutateAsync: setTaskClosed } = useSetTaskClosed()

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(ev.currentTarget)
    onOpen()
  }
  const handleClose = () => {
    setAnchorEl(null)
    onClose()
  }
  const handleIgnore = () => {
    setTaskClosed({ taskId: task.taskId, isClosed: true, closedReason: TaskClosedReason.IGNORED})
    handleClose()
  }
  const handleReject = () => {
    setTaskClosed({ taskId: task.taskId, isClosed: true, closedReason: TaskClosedReason.REJECTED})
    handleClose()
  }
  const handleReopen = () => {
    setTaskClosed({ taskId: task.taskId, isClosed: false, closedReason: null})
    handleClose()
  }

  const open = Boolean(anchorEl)
  const taskIsOpen = !task.isClosed
  return (
    <>
      <IconButton
        size='small'
        onClick={handleClick}
      >
        <Iconify icon='ph:dots-three' />
      </IconButton>

      <MenuPopover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          width: 'auto',
          '& .MuiMenuItem-root': { typography: 'small' },
        }}
      >
        {taskIsOpen && (<MenuItem onClick={handleIgnore}>
            <ListItemIcon>
              <Iconify icon='ph:bell-slash' height={16} width={16} />
            </ListItemIcon>
            <ListItemText>Ignore</ListItemText>
          </MenuItem>
        )}
        {taskIsOpen && (<MenuItem onClick={handleReject}>
            <ListItemIcon>
              <Iconify icon='ph:x-circle' height={16} width={16} />
            </ListItemIcon>
            <ListItemText>Dismiss</ListItemText>
          </MenuItem>
        )}
        {!taskIsOpen && (<MenuItem onClick={handleReopen}>
          <ListItemIcon>
            <Iconify icon='ph:arrow-bend-up-left' height={16} width={16} />
          </ListItemIcon>
          <ListItemText>Reopen</ListItemText>
        </MenuItem>)}
      </MenuPopover>
    </>
  )
}
