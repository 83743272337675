// @mui
import { styled } from '@mui/material/styles'
import { Box, Container, Paper, Stack, Typography } from '@mui/material'
// routes
// import { PATH_AUTH } from '../../routes/paths';
// hooks
// components
import Page from '../../components/Page'
// sections
import LoginForm from './LoginForm'
import MassetLogoFull from '../../components/MassetLogoFull.tsx'
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom'
import { LoginError } from './LoginError.tsx'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  background: theme.palette.background.default,
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 425,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------

export default function Login() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const errorCode = queryParams.get('code') ? parseInt(queryParams.get('code') + '', 10) : null
  return (
    <Page title='Login'>
      <RootStyle>

        <Container maxWidth='sm'>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.35 }}
          >
            <ContentStyle>
              <Paper elevation={4} sx={{ p: 4 }}>
                <MassetLogoFull sx={{ mb: 4, px: 8 }} />

                <Stack direction='row' alignItems='center' sx={{ mb: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant='h3' gutterBottom align='center'>
                      Sign in
                    </Typography>
                  </Box>

                </Stack>

                {errorCode && (
                  <Box mb={4}>
                    <LoginError errorCode={errorCode} />
                  </Box>
                )}

                <LoginForm />

              </Paper>

            </ContentStyle>
          </motion.div>
        </Container>
      </RootStyle>
    </Page>
  )
}
