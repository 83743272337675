import Page from '../../components/Page'
import { Stack, Typography } from '@mui/material'
import { NewConversationInput } from '../../components/conversations/NewConversationInput.tsx'
import { NewConversationSuggestions } from '../../components/conversations/NewConversationSuggestions.tsx'
import { useNavigate } from 'react-router-dom'
import { ContentConversation } from '../../@types/conversation.ts'
import * as React from 'react'

export function ConversationsHomePage() {
  const navigate = useNavigate()
  const handleConversationCreated = (conversation: ContentConversation) => {
    navigate(`/conversations/${conversation.contentConversationId}`)
  }
  return (
    <Page title='Conversations' sx={{ width: '100%' }}>
      <Stack
        spacing={8}
        sx={{
          paddingTop: 16,
          width: 640,
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <Stack direction='row' justifyContent='center'>
          <Typography variant='h1' fontWeight='lighter'>Get to know your content</Typography>
        </Stack>
        <NewConversationInput onConversationCreated={handleConversationCreated} />
        <NewConversationSuggestions />
        <Typography
          variant="small"
          color="text.deemphasized"
        >AI can make mistakes. Verify all important information.</Typography>
      </Stack>
    </Page>
  )
}
