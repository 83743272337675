import { Box, Skeleton, Stack } from '@mui/material'
import { MassetBotMessage, MassetBotMessageType } from '../../@types/massetbot'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'
import { useEffect, useRef } from 'react'
import { MassetBotAvatar } from './MassetBotAvatar.tsx'

interface MassetBotMessagesProps {
  messages: MassetBotMessage[],
  awaitingResponse: boolean
}

export function MassetBotMessages({ messages, awaitingResponse }: MassetBotMessagesProps) {
  const ref = useRef<HTMLElement>()
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" })
  }, [messages, awaitingResponse])

  return (
    <Stack direction='column' spacing={2}>
      {
        messages.map((it, idx) => {
          return (
            <MassetBotMessageCmp key={idx} message={it} />
          )
        })
      }
      {awaitingResponse && (
        <AwaitingResponse />
      )}
      <Box ref={ref}/>
    </Stack>
  )
}

function MassetBotMessageCmp({ message }: { message: MassetBotMessage }) {
  if (message.type == MassetBotMessageType.BOT) {
    return <MassetBotBotMessage text={message.text} />
  }
  if (message.type == MassetBotMessageType.USER) {
    return <MassetBotUserMessage text={message.text} />
  }
  return <></>
}

function MassetBotBotMessage({ text }: { text: string }) {
  const theme = useTheme()
  return (
    <Box sx={{
      background: alpha(theme.palette.primary.main, .1),
      borderRadius: 1,
      padding: 2,
    }}>
      <Stack direction='row' spacing={2}>
        <Box>
          <MassetBotAvatar />
        </Box>
        <Stack direction='column' spacing={1} alignItems='start' textAlign='left'>
          <Typography sx={{ fontWeight: 'bolder' }}>MassetBot</Typography>
          <Typography variant='standard' sx={{whiteSpace: "pre-line"}}>{text}</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}

function MassetBotUserMessage({ text }: { text: string }) {
  const theme = useTheme()
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'end',
    }}>
      <Box sx={{
        background: theme.palette.background.paper,
        borderRadius: 1,
        padding: 2,
        maxWidth: '70%',
        textAlign: 'right',
      }}>
        <Typography variant='standard'>{text}</Typography>
      </Box>
    </Box>
  )
}

function AwaitingResponse() {
  const theme = useTheme()
  return (
    <Box sx={{
      background: alpha(theme.palette.primary.main, .1),
      borderRadius: 1,
      padding: 2,
    }}>
      <Stack direction='row' spacing={2} alignItems="center">
        <Box>
          <MassetBotAvatar />
        </Box>
        <Stack direction='row' spacing={1}>
          <Skeleton variant='circular' width={12} height={12} />
          <Skeleton variant='circular' width={12} height={12} />
          <Skeleton variant='circular' width={12} height={12} />
        </Stack>

      </Stack>
    </Box>
  )
}