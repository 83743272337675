import { Stack } from '@mui/material'
import { TaskSortByDropdown } from '../TaskSortByDropdown.tsx'
import { TaskFilterCreatedAtDropdown } from '../TaskFilterCreatedAtDropdown.tsx'
import { TaskFilterClosedDropdown } from '../TaskFilterClosedDropdown.tsx'

export function TaskListTopBar(){
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <TaskFilterCreatedAtDropdown />
      <TaskFilterClosedDropdown />
      <TaskSortByDropdown />
    </Stack>
  )
}