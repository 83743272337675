import { IconButton, InputAdornment, Stack } from '@mui/material'
import Iconify from '../Iconify.tsx'
import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { FormProvider, RHFTextField } from '../hook-form'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCreateConversation } from '../../hooks/useConversations.ts'
import { ContentConversation } from '../../@types/conversation.ts'

const schema = yup.object({
  initialQuery: yup.string().required('Please provide your initial question'),
})

type Props = {
  onConversationCreated?: (conv: ContentConversation) => void
}
export function NewConversationInput({onConversationCreated}: Props) {
  const theme = useTheme()
  const { mutateAsync: createConversation } = useCreateConversation()
  const form = useForm({ defaultValues: { initialQuery: '' }, resolver: yupResolver(schema) })
  const handleFormSubmit = (data: { initialQuery: string }) => {
    createConversation({ message: data.initialQuery })
      .then(onConversationCreated)
  }

  // key down is required to block the addition of a new line on submit
  const handleTextAreaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      if(!e.repeat){
        handleSubmit(handleFormSubmit)()
      }
    }
  }

  const { handleSubmit, formState: { isValid } } = form
  return (
    <FormProvider
      methods={form}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Stack spacing={1}>
        <RHFTextField
          name='initialQuery'
          variant='outlined'
          autoFocus
          size='small'
          fullWidth
          multiline
          minRows={3}
          maxRows={5}
          placeholder={'Ask anything...'}
          InputProps={{
            onKeyDown: handleTextAreaKeyDown,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  size='small'
                  edge='end'
                  disabled={!isValid}
                  onClick={handleSubmit(handleFormSubmit)}
                  sx={{
                    backgroundColor: 'primary.main',
                    '&:disabled': {
                      backgroundColor: 'background.neutral',
                    },
                    '&:not(:disabled)': {
                      color: 'white !important',
                    },
                    '&:hover': {
                      backgroundColor: 'primary.main',
                    },
                  }}
                >
                  <Iconify
                    icon='ph:paper-plane-right'
                    sx={{ cursor: 'pointer' }}
                  />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              background: theme.palette.background.paper,
            },
          }}
        />
      </Stack>
    </FormProvider>
  )
}