import React, { useState } from 'react'
import Iconify from '../../Iconify.tsx'
import { Button, Divider, Popover, Stack, Typography } from '@mui/material'
import { UserSearchListSelect } from '../../user-select/search-list-select/UserSearchListSelect.tsx'

type ShareAnalyticsOwnerPickerProps = {
  ownerIds: string[],
  onChange?: (userIds: string[]) => void
}

export function ShareAnalyticsOwnerPicker({ownerIds, onChange} : ShareAnalyticsOwnerPickerProps){
  // state
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [userIds, setUserIds] = useState<string[]>(ownerIds)

  // handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleCancel = () => setAnchorEl(null)
  const handleApply = () => {
    handleClose()
    onChange && onChange(userIds)
  }

  const renderLabel = () => {
    const numItems = userIds.length
    return numItems ? `Filtered by ${numItems} owner(s)` : 'Filter By Owner'
  }

  // calculated props
  const open = Boolean(anchorEl)
  const id = open ? 'user-picker' : undefined

  return (
    <div>
      <Button
        color='inherit'
        disableRipple
        variant='text'
        onClick={handleClick}
        sx={{
          '& .MuiButton-endIcon': { ml: 0 },
          '& .MuiButton-startIcon': { ml: .5 },
        }}
        startIcon={
          <Iconify icon={'ph:user'} />
        }
        endIcon={
          <Iconify
            color='primary.main'
            icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          />
        }
      >
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography sx={{ color: 'primary.main' }} textTransform='none' variant='smallHighlight'>
            {renderLabel()}
          </Typography>
        </Stack>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: -4, horizontal: 0 }}
      >
        <UserSearchListSelect
          defaultUserIds={ownerIds}
          onChange={(userIds) => setUserIds(userIds)}
        />
        <Divider />
        <Stack p={1} direction='row' spacing={1} alignItems='center' justifyContent='end'>
          <Button color='inherit' variant='outlined' onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant='contained'
            size='small'
            color='primary'
            onClick={handleApply}
          >
            Apply
          </Button>
        </Stack>
      </Popover>
    </div>
  )
}
