import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  acceptTermsOfService,
  discoverLoginSettings,
  discoverSAMLRedirectUrl,
  getBootstrap,
  login,
  logout,
} from '../clients/AuthClient'
import { history } from '../utils/history'
import { AcceptTermsOfServiceResponse, AssetRequestMode, BootstrapResponse } from '../@types/auth'
import { useDebounceValue } from 'usehooks-ts'

export function useBootstrap() {
  return useQuery({
    queryKey: ['user-bootstrap'],
    queryFn: async () => getBootstrap(),
    refetchOnWindowFocus: 'always',
    staleTime: Infinity,
  })
}

export function useBaseUrls() {
  const { data } = useBootstrap()
  return {
    sharingBaseUrl: data?.sharingBaseUrl,
  }
}

export function useAssetRequestSettings(){
  const { data } = useBootstrap()
  return {
    assetRequestMode: data?.settings.assetRequestMode || AssetRequestMode.MASSET_SYSTEM
  }
}

export function useImageFormatOptions(){
  const { data } = useBootstrap()
  const enabled = data?.settings?.imageFormatsEnabled
  const fileTypes = enabled ? data?.settings?.imageFormatsFileTypes || [] : []
  const sizes = enabled ? data?.settings?.imageFormatsSizes || [] : []
  return {
    enabled: enabled,
    fileTypes: fileTypes,
    sizes: sizes
  }
}

type LoginParams = {
  username: string,
  password: string
}

export function useLogin() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ username, password }: LoginParams) => login(username, password),
    onSuccess: async _ => {
      queryClient.invalidateQueries({ queryKey: ['user-bootstrap'] })
    },
  })
}

export function useLogout() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => logout(),
    onSuccess: async _ => {
      queryClient.setQueryData(
        ['user-bootstrap'],
        () => ({} as BootstrapResponse),
      )
      history.replace('/login')
    },
  })
}

export function useLoginSettings(email: string) {
  const discoverableEmail = isDiscoverableEmail(email)
  const [deboundedEmail] = useDebounceValue(email, 350)
  return useQuery({
    enabled: discoverableEmail,
    queryKey: ['login-settings', deboundedEmail],
    queryFn: () => discoverLoginSettings(deboundedEmail),
    meta: { persist: discoverableEmail },
    initialData: {
      useSAML: false,
      useGoogleSocial: true,
      useUsernamePassword: true,
    },
  })
}

type GetSAMLRedirectParams = {
  email: string,
}

export function useGetSAMLRedirectUrl() {
  return useMutation({
    mutationFn: ({ email }: GetSAMLRedirectParams) => discoverSAMLRedirectUrl(email),
  })
}

export function useAcceptTermsOfService() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => acceptTermsOfService(),
    onSuccess: (data: AcceptTermsOfServiceResponse) => {
      queryClient.setQueryData(
        ['user-bootstrap'],
        (oldData?: BootstrapResponse) => oldData ? {
          ...oldData,
          user: { ...oldData.user, acceptedTermsOfServiceAt: data.acceptedTermsOfServiceAt },
        } : oldData,
      )
    },
  })
}

function isDiscoverableEmail(email: string) {
  const atIdx = email.indexOf('@')
  const periodIdx = email.lastIndexOf('.')
  return atIdx != -1 && periodIdx != -1 && periodIdx > atIdx
}
