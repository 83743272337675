import { alpha } from '@mui/material/styles';
import { darken, lighten, PaletteColorOptions } from "@mui/material";
import { common } from "@mui/material/colors";

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

interface MassetBGPaletteOptions {
  white: string;
  cream: string;
  warmGreyLight: string;
  warmGrey0: string;
  warmGrey2: string;
  warmGrey3: string;
  deemphasized: string;
  scrim: string;
  mint: string;
  mintHoverEmphasized: string;
  mintDark: string;
  mintLight: string;
  red: string;
  redHoverEmphasized: string;
}

interface MassetTablePaletteOptions {
  nonZebraRowBackground: string;
  zebraRowBackground: string;
}

declare module '@mui/material/styles' {
  interface CustomPalette {
    mint: PaletteColorOptions;
    red: PaletteColorOptions;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
    bg: MassetBGPaletteOptions;
    table: MassetTablePaletteOptions;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
  interface TypeText {
    primary: string;
    secondary: string;
    deemphasized: string;
    white: string;
    white64: string;
    mint: string;
    mintHoverEmphasized: string;
    mintLight: string;
    red: string;
    redHoverEmphasized: string;
    // for backward compatibility
    disabled: string;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#00AB55',
  dark: '#007B55',
  darker: '#005249',
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};
const SUCCESS = {
  lighter: '#e4f1ec',
  light: '#95c8b2',
  main: '#5DA283',
  dark: '#4a8567',
  darker: '#3b654d',
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  tag: {
    main: '#191615',
  },
  // todo: this should be removed!
  mint: { main: "#13AC7E" },
  red: { main: "#BC5741" },
  white: { main: "#FFFFFF"},
  bg: {
    white: "#FFFFFF",
    cream: "#FEFDF5",
    warmGreyLight: "#FBFAFA",
    warmGrey0: "#F9F7F7",
    warmGrey2: "#352F2D",
    warmGrey3: "#191615",
    deemphasized: "#C3BEBB",
    scrim: alpha('#000000', 0.8),
    mint: "#1FC090",
    mintHoverEmphasized: "#277D64",
    mintDark: "#094835",
    mintLight: "#F1FCF9",
    red: "#BC5741",
    redHoverEmphasized: "#703B30",
  },
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};


const TEXT_DEFAULT = {
  primary: "#191615",
  secondary: "#4A4340",
  deemphasized: "#8b8683",
  white: "#FFF",
  white64: "rgba(255, 255, 255, 0.64)",
  mint: "#13AC7E",
  mintHoverEmphasized: "#277D64",
  mintLight: "#DBFBF1",
  red: "#BC5741",
  redHoverEmphasized: "#703B30"
}

const TEXT_LIGHT = {
  ...TEXT_DEFAULT,
  disabled: GREY[500]
}

const TEXT_DARK = {
  ...TEXT_DEFAULT,
  // material recommends .87 for high emphasis text, but felt too punchy, so I bumped it down to .75
  primary: alpha("#fff", .75),
  secondary: GREY[400],
  disabled: GREY[600],
  deemphasized: darken(GREY[400], .3)
}


const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: TEXT_LIGHT,
    background: { paper: GREY[100], default: '#fff', neutral: GREY[300] },
    action: { active: GREY[600], ...COMMON.action },
    table: { nonZebraRowBackground: COMMON.bg.white, zebraRowBackground: COMMON.bg.warmGreyLight }
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: TEXT_DARK,
    background: { paper: GREY[800], default: lighten(GREY[900], .025), neutral: darken(GREY[700], .1) },
    action: { active: GREY[500], ...COMMON.action },
    table: { nonZebraRowBackground: GREY[900], zebraRowBackground: lighten(GREY[900],.025) }
  },
} as const;

export default palette;
