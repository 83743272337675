import { styled } from '@mui/material/styles'
import { Alert, AlertTitle, Box, Button, Container, Stack, Typography } from '@mui/material'
import Page from '../../../components/Page'
import Iconify from '../../../components/Iconify'
import { useNavigate } from 'react-router-dom'

const RootStyle = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 425,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

export function SlackOauthCompletion(){
  const navigate = useNavigate()
  return (
    <Page title='Slack Registration'>
      <RootStyle>
        <Container maxWidth='sm'>
          <ContentStyle>
            <Stack direction='column' spacing={4}>
              <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                    width: 64,
                    height: 64,
                    borderRadius: 1,
                    background: '#fcfcfc',
                  }}
                >
                  <Box
                    sx={{ width: 36, height: 36 }}
                    component='img'
                    src='/assets/icons/logos/slack_logo.png'
                  />
                </Stack>

                <Iconify icon='eva:plus-fill' sx={{ height: 24, width: 24 }} />

                <Box
                  sx={{ width: 64, height: 64, borderRadius: 1 }}
                  component='img'
                  src='/assets/icons/logos/masset_slack_logo_512x512.jpg'
                />
              </Stack>

              <Typography variant='h1' component='span' textAlign='center'>
                Masset was&nbsp;
                <Typography variant='h1' component='span' color='primary.main'>successfully installed</Typography>
                &nbsp;into Slack!
              </Typography>

              <Typography textAlign='center'>
                Users in your Slack workspace will now be able to use Masset inside of Slack! That's great!
              </Typography>

              <Alert variant="outlined" severity='info' icon={<Iconify icon='eva:info-outline' />}>
                <AlertTitle>User Authorization</AlertTitle>
                  Although the Masset app is installed, users will need to associate their Slack account with their Masset account. <br/><br/>
                  The first time that users attempt to use the Masset app inside of Slack, they will be prompted to authorize a connection between Masset and Slack.
              </Alert>

              <Button
                fullWidth
                variant="contained"
                color='primary'
                onClick={() => navigate('/')}
              >Go to Masset</Button>
            </Stack>

          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  )
}