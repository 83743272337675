import { queryClient } from '../utils/queryClient.ts'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { ReactNode, useState } from 'react'
import { HostedContentRoom429 } from '../pages/hosted-content-room/HostedContentRoom429.tsx'

export function HostedContentRoomRateLimitGuard({ children }: { children: ReactNode }) {
  const [isRateLimited, setIsRateLimited] = useState<boolean>(false)
  const overrideQueryClient = new QueryClient({
    defaultOptions: { ...queryClient.getDefaultOptions() },
    queryCache: new QueryCache({
      onError: (error) => {
        const isRequestError = error instanceof AxiosError
        const rateLimitedError = isRequestError && error?.response?.status === 429
        if (rateLimitedError && !isRateLimited) {
          setIsRateLimited(true)
        }
      },
    }),
  })

  if(isRateLimited){
    return (
      <HostedContentRoom429 />
    )
  }

  return <>
    <QueryClientProvider client={overrideQueryClient}>
      {children}
    </QueryClientProvider>
  </>
}