import { GroupShareItem, ShareType, SharingItem, UserShareItem } from '../../../../@types/sharing'
import { useTheme } from '@mui/material/styles'
import { Avatar, Stack, Typography } from '@mui/material'
import { AVATAR_SIZE } from '../../../admin/groups/field/GroupMembersField'
import Iconify from '../../../Iconify'

export function ShareItemDisplayInfo({ share }: { share: SharingItem }) {
  if(share.shareType == ShareType.GROUP || share.shareType == ShareType.BULK_GROUP){
    return <GroupDisplayInfo group={share as GroupShareItem} />
  }

  if(share.shareType == ShareType.USER || share.shareType == ShareType.BULK_USER){
    return <UserDisplayInfo user={share as UserShareItem} />
  }

  return <></>
}

function UserDisplayInfo({ user }: { user: UserShareItem }) {
  const theme = useTheme()
  const backgroundColor = theme.palette.mode == "light" ? theme.palette.primary.light : theme.palette.primary.dark
  const initials = user.name.charAt(0)
  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <Avatar
        sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, bgcolor: backgroundColor }}>{initials}</Avatar>
      <Stack direction='column'>
        <Typography>{user.name}</Typography>
        <Typography component='span' variant='small'>{user.email}</Typography>
      </Stack>
    </Stack>
  )
}

function GroupDisplayInfo({ group }: { group: GroupShareItem }) {
  const theme = useTheme()
  const backgroundColor = theme.palette.mode == "light" ? theme.palette.primary.lighter : theme.palette.primary.darker
  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <Avatar
        sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, bgcolor: backgroundColor }}>
        <Iconify icon="eva:people-outline" />
      </Avatar>
      <Stack direction='column'>
        <Typography>{group.name}</Typography>
        <Typography component='span' variant='small'>{group.numMembers} members</Typography>
      </Stack>
    </Stack>
  )
}