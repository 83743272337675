import { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import Iconify from '../../Iconify'
import { LoadingButton } from '@mui/lab'
import { CategoryValue } from '../../../@types/category'
import {
  useCategories,
  useCategoryValuesWithCategories,
  useMoveCategoryValueMutation,
} from '../../../hooks/useCategories'

export default function CategoryValueReassignButton({ categoryValue }: { categoryValue: CategoryValue }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [destinationCategoryId, setDestinationCategoryId] = useState<string>('')
  const { data: categories } = useCategories()
  const categoryValuesWithCategories = useCategoryValuesWithCategories()
  const { mutateAsync: moveCategoryValue } = useMoveCategoryValueMutation()
  const valueWithCategory = categoryValuesWithCategories[categoryValue.categoryValueId]
  const selectableCategories = (categories || []).filter(it => it.categoryId !== valueWithCategory?.category.categoryId)

  const onConfirmClick = async () => {
    setLoading(true)
    const originCategoryId = valueWithCategory.category.categoryId
    const categoryValueId = categoryValue.categoryValueId
    await moveCategoryValue({ categoryValueId, originCategoryId, destinationCategoryId })
    setLoading(false)
    setOpen(false)
  }

  const onDestinationChange = (ev: SelectChangeEvent) => {
    setDestinationCategoryId(ev.target.value)
  }

  return (
    <>
      <Tooltip title='Reassign category'>
        <IconButton
          size='small'
          onClick={() => setOpen(true)}
        >
          <Iconify icon='eva:swap-outline' />
        </IconButton>
      </Tooltip>


      <Dialog
        open={open}
        maxWidth='xs'
        fullWidth={true}
        onClose={() => setOpen(false)}
      >

        <Box p={7} sx={{ textAlign: 'center' }}>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Iconify sx={{ mb: 2, fontSize: 48 }} icon='eva:alert-circle-outline' color='primary.main' />

          <Typography variant='h2' mb={4}>
            Where would you like to reassign this tag?
          </Typography>

          <Typography variant='standard' color='text.secondary' component='div' mb={4}>
            Reassigning this tag will maintain it's current assocations. Any assets assigned this tag will still be
            associated after the move.
          </Typography>

          <FormControl fullWidth sx={{ mb: 4 }} size='small'>
            <InputLabel id='label'>Destination Category</InputLabel>
            <Select
              labelId='label'
              id='select'
              value={destinationCategoryId}
              label='Destination Category'
              onChange={onDestinationChange}
            >
              <MenuItem value=''>None</MenuItem>
              {selectableCategories.map(it => {
                return <MenuItem key={it.categoryId} value={it.categoryId}>{it.name}</MenuItem>
              })}

            </Select>
          </FormControl>

          <Stack spacing={2}>
            <LoadingButton
              loadingPosition='start'
              loading={loading}
              fullWidth={true}
              variant='contained'
              color='primary'
              size='large'
              onClick={onConfirmClick}
              disabled={!destinationCategoryId}
            >
              Reassign Tag
            </LoadingButton>
            <Button
              fullWidth={true}
              variant='outlined'
              size='large'
              onClick={() => {
                setOpen(false)
              }}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  )
}