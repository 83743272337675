import { Category } from '../@types/category.ts'
import { CategorySort } from '../contexts/SearchContext.tsx'
import orderBy from 'lodash/orderBy'

export function orderCategoryValues(category: Category, sort: CategorySort){
  return sort == CategorySort.ALPHABETICALLY ?
    orderBy(category.values, it => it.value, "asc") :
    orderBy(category.values, it => it.usage, "desc")
}


export function orderCategories(categories: Category[], sort: CategorySort){
  return sort == CategorySort.ALPHABETICALLY ?
    orderBy(categories, it => it.name, "asc") :
    orderBy(categories, it => it.usage, "desc")
}