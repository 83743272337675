import { FormProvider, RHFTextField } from "../hook-form";
import { Box, Button, InputAdornment, Stack } from "@mui/material";
import Iconify from "../Iconify";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { FormValuesProps } from "./AssetLink";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AssetUploadContext } from "../../contexts/AssetUploadContext";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const RFC3986_URL_REGEX = /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/

const resolver = yupResolver(Yup.object().shape({
  // location: Yup.string().matches(RFC3986_URL_REGEX).required()
  location: Yup.string().url().required()
}))

export function AssetLinkManual({setOpen} : {setOpen: (open: boolean) => void}){
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { createLinkedAsset } = useContext(AssetUploadContext)

  const methods = useForm<FormValuesProps>({
    resolver: resolver,
    defaultValues: { location: "" },
    mode: "onChange"
  });

  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    (data: FormValuesProps) => {
      setLoading(true)
      // trigger the server actions
      createLinkedAsset(data.location).then(() => {
        navigate("/assets/upload/finalize")
      })
    },
    []
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <RHFTextField
        name="location"
        placeholder="https://example.com/public/asset.jpg"
        label="Asset Url"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:link-outline"/>
            </InputAdornment>
          )
        }}
      />

      <Box sx={{ my: 6 }}/>

      <Stack spacing={2}>
        <LoadingButton
          loadingPosition="start"
          loading={loading}
          fullWidth={true}
          startIcon={<Iconify icon="eva:arrow-ios-forward-fill"/>}
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Link Asset
        </LoadingButton>
        <Button
          fullWidth={true}
          variant="text"
          sx={{ bgcolor: "white" }}
          onClick={() => {
            setOpen(false)
          }}
        >
          Cancel
        </Button>
      </Stack>
    </FormProvider>
  )
}