import { Alert, Box, Button, Container, Paper, Stack, Typography } from "@mui/material";
import MassetLogoFull from "../../components/MassetLogoFull.tsx";
import Page from "../../components/Page";
import { styled } from "@mui/material/styles";
import PasswordResetRequestForm from "./PasswordResetRequestForm";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex"
  },
  background: theme.palette.background.default
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 425,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0)
}));

export default function PasswordResetRequest() {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const onSubmitted = () => setSubmitted(true);
  const navigate = useNavigate();

  return (
    <Page title="Account Setup">
      <RootStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            <Paper elevation={4} sx={{ p: 4 }}>
              <MassetLogoFull sx={{ mb: 4, px: 8 }} />

              {submitted && (
                <Stack direction="column" spacing={4}>
                  <Alert severity="info" variant="outlined">
                    <Typography>Your password reset has been sent</Typography>
                    <Typography variant="small">Check your email for instructions on how to reset your
                      password.</Typography>
                  </Alert>
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => navigate("/login")}
                  >
                    Back to login
                  </Button>
                </Stack>
              )}

              {!submitted && (
                <>
                  <Stack direction="column" alignItems="center" spacing={2} sx={{ mb: 3 }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h3" align="center">
                        Forgot your password?
                      </Typography>
                    </Box>

                    <Typography align="center">
                      No worries, enter your email below and we'll help you get it sorted.
                    </Typography>
                  </Stack>

                  <PasswordResetRequestForm onSubmitted={onSubmitted} />
                </>
              )}


            </Paper>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}