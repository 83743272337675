import Scrollbar from '../Scrollbar'
import { Box, Button, Divider, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { Fragment, useContext, useMemo } from 'react'
import SearchAssetCategory from './SearchAssetCategory'
import { SearchContext } from '../../contexts/SearchContext'
import Iconify from '../Iconify'
import SearchOnlyFavoritesToggle from './SearchOnlyFavoritesToggle'
import { useCategories } from '../../hooks/useCategories'
import { SearchCategorySortButton } from './categories/SearchCategorySortButton.tsx'
import { orderCategories } from '../../utils/category.ts'

const SIDEBAR_WIDTH = 200 + (32 * 2);

function toSet(items: string[]) {
  const set = new Set<string>();
  items.forEach(it => set.add(it));
  return set;
}

export default function SearchOptionsSidebar() {
  const { data: categories = [] } = useCategories()
  const {
    filters: { searchTerm, categoryValueIds },
    deselectAllCategoryValues,
    setSearchTerm,
    categorySort
  } = useContext(SearchContext);
  const selectedCategoryValues = useMemo(() => toSet(categoryValueIds), [categoryValueIds]);
  const orderedCategories = useMemo(() => orderCategories(categories, categorySort), [categories, categorySort])
  return (
    <Box sx={{ width: SIDEBAR_WIDTH, pr: 4 }}>
      <Scrollbar>
        <Box mb={2}>
          <TextField
            fullWidth
            size="small"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search..."
            defaultValue={searchTerm}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-outline"/>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box mb={2}>
          <SearchOnlyFavoritesToggle />
        </Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography component="div" variant="smallHighlight"> Select Tags </Typography>
            <SearchCategorySortButton />
          </Stack>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={() => deselectAllCategoryValues()}>
            Deselect All
          </Button>
        </Stack>
        <Stack>
          {orderedCategories.map((it, idx) => {
            const isFirst = idx === 0
            return (
              <Fragment key={it.categoryId}>
                <Divider sx={{ mt: isFirst ? 1 : 3, mb: 2 }} />
                <SearchAssetCategory category={it} selectedCategoryValues={selectedCategoryValues} />
              </Fragment>
            );
          })}
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    </Box>

  )
    ;
}
