import Iconify from '../Iconify'
import { Checkbox, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { VisibleForRole } from '../../guards/VisibleForRole.tsx'
import { UserType } from '../../@types/user.ts'
import { useSearchSelectedAssetsContext } from './SearchSelectedAssetsContext.tsx'
import { useContext } from 'react'
import { SearchContext, SearchViewMode } from '../../contexts/SearchContext.tsx'

export function SearchSelectionMode() {
  // hooks
  const { selectionModeEnabled, setSelectionModeEnabled, selectedAssetIds, setSelectedAssets } = useSearchSelectedAssetsContext()
  const { results, viewMode } = useContext(SearchContext)

  // handlers
  const handleEnableSelection = () => setSelectionModeEnabled(true)
  const handleDisableSelection = () => {
    setSelectionModeEnabled(false)
    setSelectedAssets(new Set<string>())
  }
  const handleHeaderCheckboxClick = () => {
    const newValues = selectedAssetIds.size > 0 ? new Set<string>() : new Set(results.map(it => it.assetId))
    setSelectedAssets(newValues)
  }

  // calculated props
  const isHeaderCheckboxIndeterminate = selectedAssetIds.size > 0 && selectedAssetIds.size != results.length
  const isHeaderCheckboxSelected = selectedAssetIds.size > 0 && selectedAssetIds.size == results.length

  // early returns
  if(viewMode !== SearchViewMode.CARDS) return <></>

  return (
    <VisibleForRole roles={[UserType.ADMIN]}>
      <>
        {selectionModeEnabled && (
          <Tooltip title='Toggle Selection'>
            <Checkbox
              size='small'
              sx={{ p: 0, mr: 1 }}
              indeterminate={isHeaderCheckboxIndeterminate}
              checked={isHeaderCheckboxSelected}
              onClick={handleHeaderCheckboxClick}
            />
          </Tooltip>
        )}

        {selectionModeEnabled && (
          <IconButton
            size='small'
            onClick={handleDisableSelection}
          >
            <Iconify icon='ph:cards-three' color='primary.main' />
          </IconButton>
        )}

        {!selectionModeEnabled && (
          <Tooltip title='Enter multi-select mode'>
            <IconButton
              size='small'
              onClick={handleEnableSelection}
            >
              <Iconify icon='ph:cards-three' color='text.deemphasized' />
            </IconButton>
          </Tooltip>
        )}
      </>
    </VisibleForRole>)

}