import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room.ts'
import { useEffect } from 'react'
import { Box, LinearProgress, Link, Typography } from '@mui/material'

export function ExternalLinkAutoRedirect({ asset }: { asset: HostedContentRoomAsset }) {
  const redirectUrl = `/api/v1/cr/${asset.roomShortCode}/assets/${asset.assetId}/link-redirect`
  useEffect(() => {
    setTimeout(() => {
      window.location.href = redirectUrl
    }, 6000)
  })
  return (
    <Box textAlign="center" >
      <Typography variant="h2">You're being redirected to another page...</Typography>
      <Box sx={{maxWidth: 380, margin: "0 auto", mb: 4, mt: 1}}><LinearProgress sx={{height: 3}}/></Box>
      <Typography variant="standard" color="text.deemphasized">
        If you are not redirected within 5 seconds, you can
        <Link href={redirectUrl}> click here to manually go to your link.</Link>
      </Typography>
    </Box>
  )
}