import Typography from '@mui/material/Typography'
import { Box, Stack } from '@mui/material'
import { alpha, useTheme } from '@mui/material/styles'
import { MassetBotAvatar } from './MassetBotAvatar.tsx'

export function MassetBotIntro(){
  const theme = useTheme()
  return (
    <Box sx={{
      background: alpha(theme.palette.primary.main, .1),
      borderRadius: 1,
      padding: 2,
    }}>
      <Stack direction="row" spacing={2} >
        <Box>
          <MassetBotAvatar />
        </Box>
        <Stack direction="column" spacing={1} alignItems="start" textAlign="left">
          <Typography sx={{fontWeight: "bolder"}}>MassetBot</Typography>
          <Typography variant="standard">Hey there! The crazy people at Masset just brought me on board!</Typography>
          <Typography variant="standard">I'm still learning, but I'm excited to help you out!</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}