import { HostedContentRoomAsset, HostedContentRoomAssetPreview } from '../../../../@types/hosted-content-room.ts'
import { srcForHostedContentRoomPreviewItem } from '../../../asset-search/images.tsx'
import { AssetPreviewType } from '../../../../@types/asset.ts'
import { Box } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ReactEventHandler, useRef, useState } from 'react'

export function DocumentPreviewItem({ asset, preview }: {
  asset: HostedContentRoomAsset,
  preview: HostedContentRoomAssetPreview
}) {
  const ref = useRef<HTMLElement | undefined>()
  const [dimensions, setDimensions] = useState<{ width: number, height: number } | null>(null)

  const handleOnLoad: ReactEventHandler<HTMLImageElement> = (event) => {
    const { naturalWidth, naturalHeight } = event.currentTarget
    setDimensions({ width: naturalWidth, height: naturalHeight })
  }
  // we don't provide the real img src until we have the metadata data and are ready to render
  // before that, we'll provide a fake url "" and use the default 1:1 aspect ratio.
  // this allows us to trigger the intersection logic but not load a bunch of stuff
  const imgSrc = srcForHostedContentRoomPreviewItem(
    asset.roomShortCode,
    asset.assetId,
    preview.assetPreviewId,
    AssetPreviewType.THUMBNAIL_XL
  )

  // for 16:9 (1.7777), maxHeight 80vh is good
  // for 4:3 (1.33333),
  // for 1:1 (1), maxHeight 65vh is good
  // for 9:11 (.8181), maxWidth 90vh is good
  const ratio = dimensions ? dimensions.width / dimensions.height : 1
  const constraints =
      ratio >= 1.77 ? { maxHeight: "80vh" } : //16:9
      ratio >= 1.33 ? { maxHeight: "75vh" } : //4:3
      ratio >= 1.00 ? { maxHeight: "65vh" } : //1:1
      ratio >= 0.81 ? { maxWidth : "60vw" } : //9:11
                      { maxWidth : "60vw" } // anything else really tall
  const opacity = !dimensions ? 0 : 1

  return (
    <Box
      ref={ref}
      sx={{opacity: opacity}}
      textAlign="center"
    >
      <Box
        component={LazyLoadImage}
        wrapperClassName="wrapper"
        effect="blur"
        placeholderSrc="/assets/placeholder.svg"
        sx={{
          width: 1,
          height: 1,
          objectFit: 'contain',
          minWidth: {
            xs: 240,
            sm: 360,
            md: 480
          },
          ...constraints
        }}
        src={imgSrc}
        onLoad={handleOnLoad}
      />
    </Box>

  )
}