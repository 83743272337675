import { useState } from 'react'
import { Box, Button, Dialog, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import Iconify from '../../Iconify'
import { LoadingButton } from '@mui/lab'
import { Category } from '../../../@types/category'
import { useDeleteCategoryMutation } from '../../../hooks/useCategories'

export default function CategoryDeleteButton({ category }: { category: Category }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { mutateAsync: deleteCategory } = useDeleteCategoryMutation()


  const onDeleteClick = async () => {
    setLoading(true);
    await deleteCategory({categoryId: category.categoryId});
    setLoading(false);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Delete category">
        <IconButton
          size="small"
          onClick={() => setOpen(true)}
        >
          <Iconify color="text.red" icon="eva:trash-2-outline" />
        </IconButton>
      </Tooltip>


      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth={true}
        onClose={() => setOpen(false)}
      >

        <Box p={7} sx={{ textAlign: "center" }}>
          <Box sx={{ position: "absolute", right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Box>

          <Iconify sx={{ mb: 2, fontSize: 48 }} icon="eva:alert-circle-outline" color="primary.main" />

          <Typography variant="h2" mb={4}>
            Are you sure you want to delete this category?
          </Typography>

          <Typography variant="standard" color="text.secondary" component="div" mb={4}>
            Deleting this category will delete its tags and any usages on assets. This cannot be undone.
          </Typography>

          <Stack spacing={2}>
            <LoadingButton
              loadingPosition="start"
              loading={loading}
              fullWidth={true}
              variant="contained"
              color="error"
              size="large"
              onClick={onDeleteClick}
            >
              Delete category and associated tags
            </LoadingButton>
            <Button
              fullWidth={true}
              variant="outlined"
              size="large"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  );
}