import {useContext, useState} from "react";
import {SearchContext} from "../../contexts/SearchContext";
import {Button, MenuItem, Typography} from "@mui/material";
import {SearchSortBy, SORT_BY_OPTIONS} from "../../@types/search";
import MenuPopover from "../MenuPopover";
import Iconify from "../Iconify";

const renderLabel = (sortBy: SearchSortBy) => SORT_BY_OPTIONS.find(it => it.value === sortBy)?.label

export default function SearchSortDropdown() {
    const [open, setOpen] = useState<HTMLButtonElement | null>(null);
    const {filters: {sortBy}, setSortBy} = useContext(SearchContext)

    const handleOpen = (currentTarget: HTMLButtonElement) => {
        setOpen(currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleSortBy = (value: SearchSortBy) => {
        handleClose();
        setSortBy(value)
    };

    return (
        <>
            <Button
                color="inherit"
                disableRipple
                variant="text"
                onClick={(e) => handleOpen(e.currentTarget)}
                sx={{"& .MuiButton-endIcon": {ml: 0}}}
                endIcon={<Iconify color="primary.main" icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}/>}
            >
                <Typography component="span" variant="small" sx={{color: 'text.deemphasized', mr: 1}}>
                    Sort By:
                </Typography>
                <Typography component="span" variant="smallHighlight" sx={{color: 'primary.main'}}>
                    {renderLabel(sortBy)}
                </Typography>
            </Button>

            <MenuPopover
                anchorEl={open}
                open={Boolean(open)}
                onClose={handleClose}
                sx={{
                    width: 'auto',
                    '& .MuiMenuItem-root': {typography: 'body2', borderRadius: 0.75},
                }}
            >
                {SORT_BY_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === sortBy}
                        onClick={() => handleSortBy(option.value)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </MenuPopover>
        </>
    )
}