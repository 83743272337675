import { Box, Stack } from '@mui/material'
import ToggleTag from '../../../../tags/ToggleTag.tsx'
import { useSelectedGroupsLocalContext } from './BulkUserGroupSelectedGroupsContext.tsx'
import { HierarchyGroupItem } from '../../../../../@types/group.ts'

type Props = {}

export function AssignUsersToGroups({}: Props) {
  const { rootGroups } = useSelectedGroupsLocalContext()
  return (
    <Stack spacing={2}>
      {rootGroups.map(it => {
        return (
          <SelectableGroup
            group={it}
            depth={1}
          />
        )
      })}
    </Stack>
  )
}

function SelectableGroup({ group, depth }: {
  group: HierarchyGroupItem,
  depth: number
}) {
  const { selectedGroupIds, partialSelectedGroupIds, setGroupSelected } = useSelectedGroupsLocalContext()
  const handleTagChange = (groupId: string, selected: boolean) => setGroupSelected(groupId, selected)
  const isPartiallySelected = partialSelectedGroupIds.has(group.groupId)
  const isSelected = selectedGroupIds.has(group.groupId) || isPartiallySelected
  return (
    <Stack
      sx={{
        pl: depth <= 1 ? 0 : 3,
      }}
      spacing={1}
      direction='column'
    >
      {/*  ITEM */}
      <Box>
        <ToggleTag
          selected={isSelected}
          partial={isPartiallySelected}
          value={group.groupId}
          displayText={group.name}
          onChange={handleTagChange}
        />
      </Box>

      {/* CHILDREN ITEMS */}
      {
        group.children.map((it) => {
          return <SelectableGroup key={it.groupId} group={it} depth={depth + 1} />
        })
      }
    </Stack>
  )
}


