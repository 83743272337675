import { TaskClosedState, TaskCreatedAtFilter, TaskGroupBy, TaskSortBy } from '../@types/task.ts'
import { createContext, ReactNode, useContext, useState } from 'react'

type TaskSearchState = {
  groupBy: TaskGroupBy,
  sortBy: TaskSortBy,
  quickFilters: {
    createdAt: TaskCreatedAtFilter,
    closed: TaskClosedState
  }
}

type TaskSearchActions = {
  setGroupBy: (groupBy: TaskGroupBy) => void
  setSortBy: (sortBy: TaskSortBy) => void
  setCreatedAtFilter: (filter: TaskCreatedAtFilter) => void
  setClosedFilter: (filter: TaskClosedState) => void
}

type TaskSearchContextType = TaskSearchState & TaskSearchActions
const TaskSearchContext = createContext<TaskSearchContextType>({} as TaskSearchContextType)

export const TaskSearchProvider = ({ children }: { children: ReactNode }) => {
  const [groupBy, setGroupBy] = useState<TaskGroupBy>(TaskGroupBy.TASK_TYPE)
  const [sortBy, setSortBy] = useState<TaskSortBy>(TaskSortBy.NAME)
  const [createdAtFilter, setCreatedAtFilter] = useState<TaskCreatedAtFilter>(TaskCreatedAtFilter.THIRTY_DAYS)
  const [closedFilter, setClosedFilter] = useState<TaskClosedState>(TaskClosedState.OPEN)
  return (
    <TaskSearchContext.Provider
      value={{
        groupBy,
        sortBy,
        quickFilters: {
          createdAt: createdAtFilter,
          closed: closedFilter
        },
        setGroupBy,
        setSortBy,
        setCreatedAtFilter,
        setClosedFilter
      }}
    >
      {children}
    </TaskSearchContext.Provider>
  )
}

export function useTaskSearchContext(){
  return useContext(TaskSearchContext)
}