import { Box, Checkbox, Stack, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import Iconify from '../../../Iconify.tsx'
import { useGroupsTableSelectedItemsContext } from './GroupsTableSelectedItemsContext.tsx'
import { GroupListItem } from '../../../../@types/group.ts'

export function HierarchyTableHeader({groups} : {groups: GroupListItem[]}){
  const { selectedGroupIds, setSelectedGroups } = useGroupsTableSelectedItemsContext()

  const handleHeaderCheckboxClick = () => {
    const isHeaderCheckboxActive = selectedGroupIds.size > 0

    // if everything is selected, then deselect everything
    if(isHeaderCheckboxActive){
      setSelectedGroups(new Set())
    }
    // otherwise, select everything
    else{
      setSelectedGroups(new Set(groups.map(it => it.groupId)))
    }
  }

  const isHeaderCheckboxSelected = selectedGroupIds.size > 0 && selectedGroupIds.size == groups.length
  const isHeaderCheckboxIndeterminate = selectedGroupIds.size > 0 && !isHeaderCheckboxSelected
  return (
    <TableHead>
      <TableRow>
        {/*<TableCell sx={{width: 50}}>*/}
        {/*  <Checkbox*/}
        {/*    size="small"*/}
        {/*    sx={{p: 0}}*/}
        {/*    indeterminate={isHeaderCheckboxIndeterminate}*/}
        {/*    checked={isHeaderCheckboxSelected}*/}
        {/*    onClick={handleHeaderCheckboxClick}*/}
        {/*  />*/}
        {/*</TableCell>*/}
        <TableCell width={'100%'} >Name</TableCell>
        {/* direct user counts */}
        <TableCell>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Iconify icon='eva:people-fill' sx={{ height: 18, width: 18 }} />
            <Typography variant='small'>Direct</Typography>
            <Tooltip title='Members assigned directly to this group'>
              <Box>
                <Iconify icon='eva:question-mark-circle-outline' sx={{ color: 'text.deemphasized' }} />
              </Box>
            </Tooltip>
          </Stack>
        </TableCell>

        {/* indirect user counts */}
        <TableCell>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Iconify icon='eva:people-outline' sx={{ height: 18, width: 18 }} />
            <Typography variant='small'>All</Typography>
            <Tooltip title='All group members (including sub groups)'>
              <Box>
                <Iconify icon='eva:question-mark-circle-outline' sx={{ color: 'text.deemphasized' }} />
              </Box>
            </Tooltip>
          </Stack>
        </TableCell>

        {/* actions */}
        <TableCell />
      </TableRow>
    </TableHead>

  )
}