import Page from '../../../components/Page'
import {
  useCreateSlackUserRegistration, useDeleteSlackUserRegistration,
  useSlackUserRegistration,
} from '../../../hooks/integrations/useSlackUserRegistrations'
import { Alert, AlertTitle, Avatar, Box, Button, Container, Divider, Stack, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { alpha, styled, useTheme } from '@mui/material/styles'
import Iconify from '../../../components/Iconify'
import { AxiosError } from 'axios'
import useAuth from '../../../hooks/useAuth'
import { AVATAR_SIZE, ICON_SIZE } from '../../../components/admin/groups/field/GroupMembersField'

const RootStyle = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 425,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))


export default function SlackUserRegistration() {
  const [searchParams, _] = useSearchParams()
  const teamId = searchParams.get('teamId')
  const userId = searchParams.get('userId')
  const { data: registration, isError, error } = useSlackUserRegistration(teamId, userId)
  const isRequestError = error instanceof AxiosError
  const notFound = isError && isRequestError && error?.response?.status === 404

  return (
    <Page title='Slack Registration'>
      <RootStyle>
        <Container maxWidth='sm'>
          <ContentStyle>
            <Stack direction='column' spacing={4}>
              <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                    width: 64,
                    height: 64,
                    borderRadius: 1,
                    background: '#fcfcfc',
                  }}
                >
                  <Box
                    sx={{ width: 36, height: 36 }}
                    component='img'
                    src='/assets/icons/logos/slack_logo.png'
                  />
                </Stack>

                <Iconify icon='eva:plus-fill' sx={{ height: 24, width: 24 }} />

                <Box
                  sx={{ width: 64, height: 64, borderRadius: 1 }}
                  component='img'
                  src='/assets/icons/logos/masset_slack_logo_512x512.jpg'
                />
              </Stack>

              {(!userId || !teamId) && (
                <Alert severity='error' variant='outlined' icon={<Iconify icon='eva:error-outline' />}>
                  <AlertTitle>Hmmm... that's not right</AlertTitle>
                  It looks like something was mapped incorrectly from Slack. Trying clicking the Authorize button again!
                </Alert>
              )}

              {notFound && <GrantAccess teamId={teamId} userId={userId} />}
              {registration && !notFound && <RevokeAccess registrationId={registration?.slackUserRegistrationId} />}

            </Stack>

          </ContentStyle>
        </Container>
      </RootStyle>

    </Page>
  )
}

function GrantAccess({ teamId, userId }: { teamId: string | null, userId: string | null }) {
  const navigate = useNavigate()
  const { user } = useAuth()
  const theme = useTheme()
  const { mutateAsync: createRegistration } = useCreateSlackUserRegistration()
  const handleCreateRegistration = () => {
    if (teamId && userId) {
      createRegistration({ teamId, userId })
    }
  }
  return (
    <>
      <Typography variant='h2' component='span' textAlign='center'>
        Slack is &nbsp;
        <Typography variant='h2' component='span' color='primary.main'>requesting access</Typography>
        &nbsp; to access Masset on behalf of &nbsp;
      </Typography>

      <Box sx={{
        border: `1px solid ${theme.palette.divider}`,
        padding: 2,
        borderRadius: 1,
      }}>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
          <Avatar sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, bgcolor: alpha(theme.palette.info.dark, .35) }}>
            <Iconify icon='eva:person-outline' sx={{ height: ICON_SIZE, width: ICON_SIZE }} />
          </Avatar>
          <Typography variant='h6'>{user?.email}</Typography>
        </Stack>
      </Box>


      <Typography variant='h6'>
        What will Slack be able to do?
      </Typography>

      <Permissions hasAccess={false} />

      <Stack spacing={2}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleCreateRegistration}
        >Grant Access</Button>
        <Button
          fullWidth
          color='inherit'
          onClick={() => navigate('/assets/search')}
        >Cancel</Button>
      </Stack>
    </>
  )
}

function RevokeAccess({ registrationId }: { registrationId: string }) {
  const navigate = useNavigate()
  const { user } = useAuth()
  const theme = useTheme()
  const { mutateAsync: deleteRegistration } = useDeleteSlackUserRegistration()
  const handleRevoke = () => deleteRegistration({ registrationId })
  return (
    <>

      <Typography variant='h2' component='span' textAlign='center'>
        Slack is &nbsp;
        <Typography variant='h2' component='span' color='primary.main'>currently authorized</Typography>
        &nbsp; to access Masset on behalf of
      </Typography>

      <Box sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        padding: 2,
        borderRadius: 1,
      }}>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
          <Iconify icon='eva:checkmark-circle-fill' sx={{ height: 40, width: 40, color: theme.palette.primary.main }} />
          <Typography variant='h6'>{user?.email}</Typography>
        </Stack>
      </Box>

      <Typography variant='h6'>
        What is Slack be able to do?
      </Typography>

      <Permissions hasAccess={true} />

      <Stack spacing={2}>
        <Button
          variant='outlined'
          color='red'
          fullWidth
          onClick={handleRevoke}
        >Revoke Access</Button>
        <Button
          fullWidth
          color='inherit'
          onClick={() => navigate('/assets/search')}
        >Cancel</Button>
      </Stack>
    </>
  )
}

function Permissions({ hasAccess }: { hasAccess: boolean }) {
  return (
    <Stack direction='column' spacing={2}>
      <Stack direction='row' alignItems='center' spacing={2}>
        {hasAccess && <Iconify icon='eva:checkmark-circle-2-fill' color='primary.main' sx={{ height: 24, width: 24 }} />}
        <Iconify icon='eva:search-fill' sx={{ height: 24, width: 24 }} />
        <Typography>Search assets that you have access to</Typography>
      </Stack>
      <Divider />
      <Stack direction='row' alignItems='center' spacing={2}>
        {hasAccess && <Iconify icon='eva:checkmark-circle-2-fill' color='primary.main' sx={{ height: 24, width: 24 }} />}
        <Iconify icon='eva:image-fill' sx={{ height: 24, width: 24 }} />
        <Typography>See previews of assets that you have access to</Typography>
      </Stack>
      <Divider />
      <Stack direction='row' alignItems='center' spacing={2}>
        {hasAccess && <Iconify icon='eva:checkmark-circle-2-fill' color='primary.main' sx={{ height: 24, width: 24 }} />}
        <Iconify icon='eva:link-fill' sx={{ height: 24, width: 24 }} />
        <Typography>Post links to assets in Slack channels</Typography>
      </Stack>
      <Divider />
    </Stack>
  )
}