import { Asset } from '../../../../@types/asset.ts'
import { useRef, useState } from 'react'
import useAuth from '../../../../hooks/useAuth.ts'
import { useDownload } from '../../../../hooks/useDownload.tsx'
import { UserType } from '../../../../@types/user.ts'
import { Button, ButtonGroup, Stack, Tooltip } from '@mui/material'
import Iconify from '../../../Iconify.tsx'
import { ThirdPartyPostfix } from '../ThirdPartyPostfix.tsx'
import { CustomFormatPopper } from './CustomFormatPopper.tsx'

export function AssetCustomFormatDownloadButton({ asset }: { asset: Asset }) {
  const [open, setOpen] = useState(false)

  const anchorRef = useRef<HTMLButtonElement>(null)
  const { user } = useAuth()
  const { consumeAsset } = useDownload()

  const handleToggle = () => setOpen(prevOpen => !prevOpen)
  const handlePopperClose = () => setOpen(false)
  const handleOnClickOriginal = () => consumeAsset(asset)

  const isAdmin = user?.userType == UserType.ADMIN
  const downloadDisabled = asset?.isInternal && !isAdmin
  return (
    <Stack direction='column' spacing={2}>
      <ButtonGroup
        variant='contained'
        disabled={downloadDisabled}
      >
        <Button
          startIcon={<Iconify icon={'eva:download-fill'} />}
          fullWidth
          onClick={handleOnClickOriginal}
        >
          Download Original
          <ThirdPartyPostfix asset={asset} />
        </Button>
        <Tooltip title='Download custom formats'>
          <Button
            ref={anchorRef}
            onClick={handleToggle}
          >
            <Iconify icon='ph:dots-three' height={24} width={24} />
          </Button>
        </Tooltip>
      </ButtonGroup>

      <CustomFormatPopper
        asset={asset}
        open={open}
        anchorRef={anchorRef}
        onClose={handlePopperClose}
      />

    </Stack>

  )
}