import { ShareLevel } from '../@types/sharing'
import { AssetPermission } from '../@types/asset'
import useAuth from './useAuth'
import { UserType } from '../@types/user'
import { useIsFeatureEnabled } from './useFeatures.ts'


export function useHasPermissionForAccessLevel(permission: AssetPermission, level: ShareLevel) {
  const { user } = useAuth();

  // special case 1: admins are permitted to do everything
  const isAdmin = user && user.userType == UserType.ADMIN
  if(isAdmin) return true

  return Boolean(PERMISSION_MAPPING[permission]?.[level])
}

export function useHasAnyPermissionForAccessLevel(permissions: AssetPermission[], level: ShareLevel) {
  const { user } = useAuth();

  // special case 1: admins are permitted to do everything
  const isAdmin = user && user.userType == UserType.ADMIN
  if(isAdmin) return true

  return permissions.reduce((acc, it) => acc || Boolean(PERMISSION_MAPPING[it]?.[level]), false)
}


const ALLOWED_FOR_NONE = {
  [ShareLevel.MIXED]: false,
  [ShareLevel.NONE]: false,
  [ShareLevel.VIEW]: false,
  [ShareLevel.EDIT]: false,
  [ShareLevel.OWNER]: false,
}

const ALLOWED_FOR_VIEW_AND_ABOVE = {
  [ShareLevel.MIXED]: false,
  [ShareLevel.NONE]: false,
  [ShareLevel.VIEW]: true,
  [ShareLevel.EDIT]: true,
  [ShareLevel.OWNER]: true,
}

const ALLOWED_FOR_EDIT_AND_ABOVE = {
  [ShareLevel.MIXED]: false,
  [ShareLevel.NONE]: false,
  [ShareLevel.VIEW]: false,
  [ShareLevel.EDIT]: true,
  [ShareLevel.OWNER]: true,
}

const ALLOWED_FOR_OWNER_AND_ABOVE = {
  [ShareLevel.MIXED]: false,
  [ShareLevel.NONE]: false,
  [ShareLevel.VIEW]: false,
  [ShareLevel.EDIT]: false,
  [ShareLevel.OWNER]: true,
}

const PERMISSION_MAPPING = {
  [AssetPermission.DOWNLOAD]: ALLOWED_FOR_VIEW_AND_ABOVE,
  [AssetPermission.SHARE]: ALLOWED_FOR_EDIT_AND_ABOVE,
  [AssetPermission.EXTERNAL_SHARE]: ALLOWED_FOR_VIEW_AND_ABOVE,
  [AssetPermission.REQUEST_CHANGE]: ALLOWED_FOR_VIEW_AND_ABOVE,
  [AssetPermission.GIVE_FEEDBACK]: ALLOWED_FOR_VIEW_AND_ABOVE,
  [AssetPermission.FLAG]: ALLOWED_FOR_VIEW_AND_ABOVE,
  [AssetPermission.EDIT_METADATA]: ALLOWED_FOR_EDIT_AND_ABOVE,
  [AssetPermission.UPLOAD_NEW_VERSION]: ALLOWED_FOR_EDIT_AND_ABOVE,
  [AssetPermission.DELETE]: ALLOWED_FOR_OWNER_AND_ABOVE,
  [AssetPermission.FAVORITE]: ALLOWED_FOR_VIEW_AND_ABOVE,
  [AssetPermission.OVERRIDE_PREVIEW]: ALLOWED_FOR_EDIT_AND_ABOVE,
  [AssetPermission.RETRY_TASKS]: ALLOWED_FOR_VIEW_AND_ABOVE,
}