import { TaskGroup } from '../../@types/task.ts'
import { TableBody } from '@mui/material'
import { useState } from 'react'
import { TaskListGroupHeaderRow } from './list/TaskListGroupHeaderRow.tsx'
import { TaskListNoTasksRow } from './list/TaskListNoTasksRow.tsx'
import { TaskTableRow } from './list/TaskTableRow.tsx'

type TaskGroupCmpProps = { taskGroup: TaskGroup }

export function ListTaskGroup({ taskGroup }: TaskGroupCmpProps) {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const handleOnCollapsedChange = (isCollapsed: boolean) => setCollapsed(isCollapsed)
  return (
    <TableBody>
      <TaskListGroupHeaderRow
        taskGroup={taskGroup}
        isCollapsed={collapsed}
        onCollapsedChange={handleOnCollapsedChange}
      />
      {!collapsed && (
        <>
          {taskGroup.tasks.map((task) => (
            <TaskTableRow key={task.taskId} task={task} />
          ))}
        </>
      )}

      {!collapsed && taskGroup.tasks.length == 0 && (
        <TaskListNoTasksRow />
      )}
    </TableBody>
  )
}

