import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createTaskComment,
  createTaskSection, deleteTask,
  deleteTaskSection, getTask, getTaskComments,
  getTaskSections,
  searchForTasks, setTaskDescription,
  setTaskIsClosed, setTaskName,
  updateTaskSectionDisplayOrder,
  updateTaskSectionName,
} from '../clients/TaskClient.ts'
import { TaskClosedReason, TaskSearchOptions } from '../@types/task.ts'
import { useTaskSearchContext } from '../contexts/TaskSearchContext.tsx'

export function useTaskSections() {
  return useQuery({
    queryKey: ['task-sections'],
    queryFn: () => getTaskSections(),
  })
}

export function useTaskSearchFromContext(){
  const { groupBy, sortBy, quickFilters } = useTaskSearchContext()
  const options = {
    groupBy,
    sortBy,
    quickFilters: {
      createdAt: quickFilters.createdAt,
      closed: quickFilters.closed
    }
  }
  return useTaskSearch(options)
}

export function useTaskSearch(options: TaskSearchOptions){
  return useQuery({
    queryKey: ['tasks', options],
    queryFn: () => searchForTasks(options)
  })
}

export function useTask(taskId: string | null){
  return useQuery({
    enabled: Boolean(taskId),
    queryKey: ['tasks', taskId],
    queryFn: () => getTask(taskId!!)
  })
}

export function useTaskComments(taskId?: string | null){
  return useQuery({
    enabled: Boolean(taskId),
    queryKey: ['tasks', taskId, "comments"],
    queryFn: () => getTaskComments(taskId!!)
  })
}

// --------------------------------------------
// Mutations
// --------------------------------------------
type SetTaskDescriptionParams = {
  taskId: string,
  description: string
}
export function useSetTaskDescription(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async(params: SetTaskDescriptionParams) => setTaskDescription(params.taskId, params.description),
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks'])
    }
  })
}

type SetTaskNameParams = {
  taskId: string,
  name: string
}
export function useSetTaskName(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async(params: SetTaskNameParams) => setTaskName(params.taskId, params.name),
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks'])
    }
  })
}

type SetTaskClosedParams = {
  taskId: string,
  isClosed: boolean,
  closedReason: TaskClosedReason | null
}
export function useSetTaskClosed(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async(params: SetTaskClosedParams) => setTaskIsClosed(params.taskId, params.isClosed, params.closedReason),
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks'])
    }
  })
}

type DeleteTaskParams = {
  taskId: string
}
export function useDeleteTask(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async(params: DeleteTaskParams) => deleteTask(params.taskId),
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks'])
    }
  })
}

type CreateTaskCommentParams = {
  taskId: string
  content: string
}
export function useCreateTaskComment() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: CreateTaskCommentParams) => createTaskComment(params.taskId, params.content),
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks'])
    }
  })
}

type CreateTaskSectionParams = {
  name: string | null,
  displayOrder: number | null
}
export function useCreateTaskSection() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: CreateTaskSectionParams) => createTaskSection(params.name, params.displayOrder),
    onSuccess: () => {
      queryClient.invalidateQueries(['task-sections'])
    }
  })
}


type SetTaskSectionDisplayOrderParams = {
  taskSectionId: string,
  displayOrder: number
}
export function useSetTaskSectionDisplayOrder() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: SetTaskSectionDisplayOrderParams) =>
      updateTaskSectionDisplayOrder(params.taskSectionId, params.displayOrder),
    onSuccess: () => {
      queryClient.invalidateQueries(['task-sections'])
    }
  })
}

type SetTaskSectionNameParams = {
  taskSectionId: string,
  name: string
}
export function useSetTaskSectionName() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: SetTaskSectionNameParams) =>
      updateTaskSectionName(params.taskSectionId, params.name),
    onSuccess: () => {
      queryClient.invalidateQueries(['task-sections'])
    }
  })
}

type DeleteTaskSectionParams = {
  taskSectionId: string
}
export function useDeleteTaskSection() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: DeleteTaskSectionParams) => deleteTaskSection(params.taskSectionId),
    onSuccess: () => {
      queryClient.invalidateQueries(['task-sections'])
    }
  })
}
