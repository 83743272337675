import { Button, Stack, Tooltip } from '@mui/material'
import { useDownload } from '../../../hooks/useDownload.tsx'
import { AssetStorageType, AssetType } from '../../../@types/asset.ts'
import { SearchAssetResult } from '../../../@types/search.ts'
import { UserType } from '../../../@types/user.ts'
import useAuth from '../../../hooks/useAuth.ts'
import { useImageFormatOptions } from '../../../hooks/useBootstrap.ts'
import { useRef, useState } from 'react'
import Iconify from '../../Iconify.tsx'
import { CustomFormatPopper } from '../../asset-detail/actions/download/CustomFormatPopper.tsx'

export function AssetCardDownloadButton({ asset }: { asset: SearchAssetResult }) {
  const { user } = useAuth()
  const { enabled: customFormatsEnabled } = useImageFormatOptions()
  const { consumeAsset } = useDownload()

  const consumeText = asset.storageType === AssetStorageType.EXTERNAL_HTTP ? 'Visit Link' : 'Download'
  const isAdmin = user?.userType == UserType.ADMIN
  const downloadDisabled = asset?.isInternal && !isAdmin
  const isImage = asset.assetType == AssetType.IMAGE

  if (isImage && customFormatsEnabled) return <AssetCardCustomFormatDownloadButton asset={asset} />

  return (
    <>
      {
        downloadDisabled && (
          <Tooltip
            title='This asset is for internal use only. To prevent accidental distribution, downloading has been disabled.'>
            <Stack alignItems='center' justifyContent='center'>
              <Iconify icon='eva:lock-outline' color='text.red' />
            </Stack>
          </Tooltip>
        )
      }

      <Button
        color='primary'
        size='small'
        variant='text'
        onClick={() => consumeAsset(asset)}
        disabled={downloadDisabled}
      >
        {consumeText}
      </Button>
    </>

  )
}


export function AssetCardCustomFormatDownloadButton({ asset }: { asset: SearchAssetResult }) {
  const [open, setOpen] = useState(false)

  const anchorRef = useRef<HTMLButtonElement>(null)
  const { user } = useAuth()

  const handleToggle = () => setOpen(prevOpen => !prevOpen)
  const handlePopperClose = () => setOpen(false)

  const isAdmin = user?.userType == UserType.ADMIN
  const downloadDisabled = asset?.isInternal && !isAdmin
  return (
    <>
      {
        downloadDisabled && (
          <Tooltip
            title='This asset is for internal use only. To prevent accidental distribution, downloading has been disabled.'>
            <Stack alignItems='center' justifyContent='center'>
              <Iconify icon='eva:lock-outline' color='text.red' />
            </Stack>
          </Tooltip>
        )
      }

      <Button
        ref={anchorRef}
        onClick={handleToggle}
        disabled={downloadDisabled}
      >
        Download
      </Button>

      <CustomFormatPopper
        asset={asset}
        open={open}
        anchorRef={anchorRef}
        onClose={handlePopperClose}
        includeOriginal
      />
    </>
  )
}