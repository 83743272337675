// @mui
import { Box, BoxProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function MassetLogoFull({ sx }: Props) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const logoFile = isLight ? "/logo/Massetai-Logo-Dark.png" : "/logo/Massetai-Logo-White.png"
  const logo = (
    <Box
      sx={{ ...sx }}
      component="img"
      src={logoFile}
    />
  );
  return <>{logo}</>;
}
