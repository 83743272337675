import { Box, Container } from '@mui/material'
import AssetHeader from '../asset/AssetHeader.tsx'
import { Outlet } from 'react-router-dom'
import { TaskSearchProvider } from '../../contexts/TaskSearchContext.tsx'

export default function TasksLayout() {
  return (
    <Container sx={{ p: 4 }}>
      <AssetHeader />
      <TaskSearchProvider>
        <Box sx={{ mt: 4 }}>
          <Outlet />
        </Box>
      </TaskSearchProvider>
    </Container>
  )
}