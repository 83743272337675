import { TableCell, TableHead, TableRow } from '@mui/material'

export function TaskListTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell width='65%'>Name</TableCell>
        <TableCell>Asset</TableCell>
        <TableCell sx={{whiteSpace: "nowrap"}}>Created By</TableCell>
        <TableCell width="100">Created At</TableCell>
        <TableCell  sx={{
          padding: '0px !important',
          minWidth: '36px',
        }}></TableCell>
      </TableRow>
    </TableHead>
  )
}