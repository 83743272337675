export type TaskGroup = {
  groupBy: TaskGroupBy,
  displayName: string,
  tasks: Task[]
}

export type Task = {
  taskId: string,
  name: string,
  description: string | null,
  taskSectionId: string | null,
  assigneeUserId: string | null,
  assigneeName: string | null,
  isClosed: boolean,
  createdAt: Date,
  closedReason: TaskClosedReason,
  closedAt: Date | null,
  dueAt: Date | null,
  createdByUserId: string | null,
  createdByName: string | null,
  assets: TaskAsset[],
  commentCount: number
}

export type TaskSection = {
  taskSectionId: string,
  name: string,
  displayOrder: number
}

export type TaskAsset = {
  taskAssetId: string,
  assetId: string,
  assetName: string,
  assetPreviewId: string | null
}

export type TaskComment = {
  taskCommentId: string,
  taskId: string,
  content: string,
  createdById: string | null,
  createdByName: string | null,
  createdAt: Date
}

export enum TaskClosedReason {
  COMPLETE = 1,
  REJECTED = 2,
  IGNORED = 3,
  OTHER = 100
}

export enum TaskGroupBy {
  NONE = 0,
  SECTION = 1,
  ASSIGNEE = 2,
  DUE_DATE = 3,
  CREATED_DATE = 4,
  TASK_TYPE = 5,
}

export enum TaskSortBy {
  NONE = 0,
  NAME = 1,
  CREATED_AT = 2
}

export type TaskSearchOptions = {
  groupBy: TaskGroupBy,
  sortBy: TaskSortBy,
  quickFilters: {
    createdAt: TaskCreatedAtFilter,
    closed: TaskClosedState
  }
}

export enum TaskCreatedAtFilter {
  ALL = 1,
  SEVEN_DAYS = 2,
  THIRTY_DAYS = 3,
  NINETY_DAYS = 4
}

export enum TaskClosedState {
  ALL = 1,
  CLOSED = 2,
  OPEN = 3,
}

export type GetTaskSectionsResponse = {
  sections: TaskSection[]
}

export type TaskSearchResponse = {
  groupBy: TaskGroupBy,
  groups: TaskSearchGroup[]
}
export type TaskSearchGroup = TaskGroup

export type GetTaskSectionResponse = TaskSection
export type CreateTaskSectionResponse = TaskSection
export type UpdateTaskSectionNameResponse = TaskSection
export type UpdateTaskSectionDisplayOrderResponse = TaskSection

export type GetTaskResponse = Task
export type UpdateTaskNameResponse = Task
export type UpdateTaskIsClosedResponse = Task
export type UpdateTaskDescriptionResponse = Task

export type GetTaskCommentsResponse ={
  comments: TaskComment[]
}
export type GetTaskCommentResponse = TaskComment
export type CreateTaskCommentResponse = TaskComment

export const TASK_SORT_BY_OPTIONS = [
  { value: TaskSortBy.NAME, label: 'Name' },
  { value: TaskSortBy.CREATED_AT, label: 'Created Date' },
]

export const TASK_DATE_FILTER_OPTIONS = [
  { value: TaskCreatedAtFilter.ALL, label: 'All Time' },
  { value: TaskCreatedAtFilter.SEVEN_DAYS, label: 'Last 7 Days' },
  { value: TaskCreatedAtFilter.THIRTY_DAYS, label: 'Last 30 Days' },
  { value: TaskCreatedAtFilter.NINETY_DAYS, label: 'Last 90 Days' },
]

export const TASK_CLOSED_FILTER_OPTIONS = [
  { value: TaskClosedState.ALL, label: 'All' },
  { value: TaskClosedState.CLOSED, label: 'Closed' },
  { value: TaskClosedState.OPEN, label: 'Open' },
]