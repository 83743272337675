import { AssetStorageType, AssetType } from './asset'

export enum SearchSortBy {
    MOST_RECENT = "MOST_RECENT",
    ALPHABETICAL = "ALPHABETICAL",
    HIGHEST_RATED = "HIGHEST_RATED",
    MOST_POPULAR = "MOST_POPULAR",
    FILE_TYPE = "FILE_TYPE",
    BEST_MATCH = "BEST_MATCH"
}

export enum SearchDateFilter {
    ALL = "ALL",
    SEVEN_DAYS = "SEVEN_DAYS",
    THIRTY_DAYS = "THIRTY_DAYS"
}

export type SearchAssetResult = {
    assetId: string,
    assetPreviewId: string | null,
    name: string,
    description: string,
    createdAt: string,
    storageType: AssetStorageType,
    isFavorite: boolean
    isInternal: boolean,
    assetType: AssetType
}

export type SearchAssetResults = {
    results: SearchAssetResult[],
    totalResultCount: number,
    limit: number,
    offset: number
}

export type SearchAssetOptions = {
    searchTerm?: string
    categoryValueIds: string[]
    sortBy: SearchSortBy
    dateUploadedFilter: SearchDateFilter
    onlyFavorites: boolean,
    offset: number,
    limit: number
}

export const SORT_BY_OPTIONS = [
    { value: SearchSortBy.BEST_MATCH, label: "Best Match" },
    { value: SearchSortBy.MOST_POPULAR, label: "Most Popular" },
    { value: SearchSortBy.MOST_RECENT, label: "Most Recent" },
    { value: SearchSortBy.ALPHABETICAL, label: "Name (alphabetical)" },
    // { value: SearchSortBy.HIGHEST_RATED, label: "Highest Rated" },
    { value: SearchSortBy.FILE_TYPE, label: "File Type" },
]

export const DATE_FILTER_OPTIONS = [
    { value: SearchDateFilter.ALL, label: "All Time" },
    { value: SearchDateFilter.SEVEN_DAYS, label: "Last Seven Days" },
    { value: SearchDateFilter.THIRTY_DAYS, label: "Last Thirty Days" },
]