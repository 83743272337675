import { TaskListTableHead } from './TaskListTableHead.tsx'
import { ListTaskGroup } from '../ListTaskGroup.tsx'
import { Table } from '@mui/material'
import { useTaskSearchFromContext } from '../../../hooks/useTasks.ts'
import { TaskTableProvider } from './TaskTableContext.tsx'
import { TaskDetailsDrawer } from '../drawer/TaskDetailsDrawer.tsx'

export function TaskListTable() {
  const { data: searchResult } = useTaskSearchFromContext()

  return (
    <TaskTableProvider>
      <Table
        stickyHeader
        size='small'
        sx={{
          borderCollapse: 'collapse',
          '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'transparent',
          },
          '& tbody tr:nth-of-type(even)': {
            backgroundColor: 'transparent',
          },
        }}>
        <TaskListTableHead />
        {searchResult?.groups.map((it, idx) => (
          <ListTaskGroup key={idx} taskGroup={it} />
        ))}
      </Table>
      <TaskDetailsDrawer />
    </TaskTableProvider>
  )
}

