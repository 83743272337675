import { Task, TaskComment } from '../../../@types/task.ts'
import { Avatar, Stack, Typography } from '@mui/material'
import { alpha, useTheme } from '@mui/material/styles'
import { fDateTime } from '../../../utils/formatTime.ts'
import { useTaskComments } from '../../../hooks/useTasks.ts'
import orderBy from 'lodash/orderBy'
import Iconify from '../../Iconify.tsx'

export function DrawerTaskComments({ task }: { task?: Task }) {
  const { data } = useTaskComments(task?.taskId)
  if(!task){ return <></> }
  const comments = [
    ...(data?.comments || []),
    ...(task.isClosed ? [{ isClosedByComment: true, createdAt: task.closedAt }] : [])
  ]
  const orderedComments = orderBy(comments, (it) => it.createdAt)
  return (
    <Stack
      direction='column'
      spacing={3}
    >
      <CreatedByComment task={task} />

      { orderedComments.map(it => {
        // @ts-ignore
        if(it.taskCommentId){
            const comment = it as TaskComment
            return <GeneralComment key={comment.taskCommentId} comment={comment} />
        }
        // @ts-ignore
        else if(it.isClosedByComment) {
            return <ClosedComment task={task} />
        }
        else {
          return <></>
        }
      })}
    </Stack>
  )
}

function CreatedByComment({ task }: { task: Task }) {
  const name = task.createdByName || "Unknown"
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CommentAvatar name={name} />
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="standardHighlight" color="text.deemphasized">
          {name} <Typography variant="standard">created this task </Typography>
        </Typography>
        <Typography variant="standard" color="text.deemphasized">
          •
        </Typography>
        <Typography variant="small" color="text.deemphasized">
          {fDateTime(task.createdAt)}
        </Typography>
      </Stack>

    </Stack>
  )
}

function GeneralComment({ comment }: { comment: TaskComment }) {
  const name = comment.createdByName || "Unknown"

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CommentAvatar name={name} />
      <Stack direction="column" spacing={.25}>

        {/* header */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="standardHighlight">
            {name}
          </Typography>

          {/* date */}
          <Typography variant="standard" color="text.deemphasized">
            •
          </Typography>
          <Typography variant="small" color="text.deemphasized">
            {fDateTime(comment.createdAt)}
          </Typography>
        </Stack>

        {/* content */}
        <Typography variant="standard" whiteSpace="pre-wrap">
          {comment.content}
        </Typography>

      </Stack>


    </Stack>
  )
}

function CommentAvatar({ name }: { name?: string | null }) {
  const theme = useTheme()
  const resolvedName = name || "Unknown"
  return (
    <Avatar sx={{ height: 32, width: 32, bgcolor: alpha(theme.palette.primary.main, .35) }}>
      <Typography variant="small">{resolvedName[0]}</Typography>
    </Avatar>
  )
}

function CompleteAvatar() {
  const theme = useTheme()
  return (
    <Avatar sx={{ height: 32, width: 32, bgcolor: theme.palette.success.main }}>
      <Iconify icon="ph:check"/>
    </Avatar>
  )
}

function ClosedComment({ task }: { task: Task }) {
  if(!task.closedAt) return <></>
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CompleteAvatar />
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="standard" color="success.main">Marked as completed</Typography>
        <Typography variant="standard" color="text.deemphasized">
          •
        </Typography>
        <Typography variant="small" color="text.deemphasized">
          {fDateTime(task.closedAt)}
        </Typography>
      </Stack>

    </Stack>
  )
}