import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { AnalyticType } from '../../@types/analytics/analytics-asset'
import { UserType } from '../../@types/user.ts'
import { useRole } from '../../hooks/useAuth.ts'

type AnalyticsGraphTypeSelectProps = {
  type: AnalyticType,
  onTypeChange: (type: AnalyticType) => void
}
export function AnalyticsGraphTypeSelect({type, onTypeChange} : AnalyticsGraphTypeSelectProps){
  const role = useRole()
  const handleMetricChange =
    (event: SelectChangeEvent) => onTypeChange(event.target.value as unknown as AnalyticType)
  const shouldIncludeFavorites = role == UserType.ADMIN
  return (
    <Select
      size='small'
      value={type + ''}
      defaultValue={type + ''}
      onChange={handleMetricChange}
      sx={{ height: 24, fontSize: 11 }}
      margin='dense'
    >
      {shouldIncludeFavorites && (<MenuItem value={AnalyticType.FAVORITE}>Favorites</MenuItem>) }
      <MenuItem value={AnalyticType.DOWNLOAD}>Downloads</MenuItem>
      <MenuItem value={AnalyticType.SHARE}>Shares</MenuItem>
      <MenuItem value={AnalyticType.VIEW}>Views</MenuItem>
    </Select>
  )
}