import { useState } from 'react'
import { Button, MenuItem, Typography } from '@mui/material'
import Iconify from '../Iconify.tsx'
import { useTaskSearchContext } from '../../contexts/TaskSearchContext.tsx'
import MenuPopover from '../MenuPopover.tsx'
import { TASK_CLOSED_FILTER_OPTIONS, TaskClosedState } from '../../@types/task.ts'

const renderLabel = (dateFilter: TaskClosedState) => TASK_CLOSED_FILTER_OPTIONS.find(it => it.value === dateFilter)?.label

export function TaskFilterClosedDropdown() {
  const { quickFilters: { closed }, setClosedFilter } = useTaskSearchContext()
  const [open, setOpen] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (currentTarget: HTMLButtonElement) => setOpen(currentTarget);
  const handleClose = () => setOpen(null)

  const handleFilterChange = (value: TaskClosedState) => {
    handleClose();
    setClosedFilter(value)
  };

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        variant="text"
        onClick={(e) => handleOpen(e.currentTarget)}
        sx={{"& .MuiButton-endIcon": {ml: 0}}}
        endIcon={<Iconify color="primary.main" icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}/>}
      >
        <Typography component="span" variant="small" sx={{color: 'text.deemphasized', mr: 1}}>
          Status:
        </Typography>
        <Typography component="span" variant="smallHighlight" sx={{color: 'primary.main'}}>
          {renderLabel(closed)}
        </Typography>
      </Button>

      <MenuPopover
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        sx={{
          width: 'auto',
          '& .MuiMenuItem-root': {typography: 'body2', borderRadius: 0.75},
        }}
      >
        {TASK_CLOSED_FILTER_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === closed}
            onClick={() => handleFilterChange(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  )
}