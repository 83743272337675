export enum ShareLevel {
  NONE = 0,
  VIEW = 1,
  EDIT = 2,
  OWNER = 3,
  MIXED = 100
}

export enum GeneralAccessType {
  RESTRICTED = 1,
  ORGANIZATION = 2,
  MIXED = 100
}

export enum ShareType {
  USER = 1,
  GROUP = 2,
  BULK_USER = 3,
  BULK_GROUP = 4
}

export type SharingSearchResponse = {
  shares: SharingSearchResult[]
}

export type GroupShareItem = {
  shareType: ShareType.GROUP,
  shareLevel: ShareLevel,
  uniqueId: string,
  assetId: string,
  groupId: string,
  name: string,
  numMembers: number,
  isMulti: boolean
}

export type UserShareItem = {
  shareType: ShareType.USER,
  shareLevel: ShareLevel,
  uniqueId: string,
  assetId: string,
  userId: string,
  name: string,
  email: string
  isMulti: boolean
}

export type BulkGroupShareItem = {
  shareType: ShareType.BULK_GROUP,
  shareLevel: ShareLevel,
  uniqueId: string,
  assetIds: string[],
  groupId: string,
  name: string,
  numMembers: number,
  isMulti: boolean
}

export type BulkUserShareItem = {
  shareType: ShareType.BULK_USER,
  shareLevel: ShareLevel,
  uniqueId: string,
  assetIds: string[],
  userId: string,
  name: string,
  email: string
  isMulti: boolean
}

export type SharingItem = UserShareItem | GroupShareItem | BulkUserShareItem | BulkGroupShareItem

export type SharingSearchUserResult = {
  shareType: ShareType.USER,
  uniqueId: string,
  userId: string,
  name: string,
  email: string
}

export type SharingSearchGroupResult = {
  shareType: ShareType.GROUP,
  uniqueId: string,
  groupId: string,
  name: string,
  numMembers: string
}

export type SharingSearchResult = SharingSearchUserResult | SharingSearchGroupResult


export type AssetSharesResponse = {
  shares: SharingItem[],
  generalAccessType: GeneralAccessType,
  generalAccessShareLevel: ShareLevel
}

export type BulkAssetsSharesStateResponse = {
  shares: SharingItem[],
  generalAccessType: GeneralAccessType,
  generalAccessShareLevel: ShareLevel,
}
export type BulkUpdateAssetsSharesResponse = BulkAssetsSharesStateResponse

export type UpdateAssetSharesRequest = {
  shares: UpdateAssetShareItem[]
}

export type UpdateAssetShareItem = {
  groupId?: string,
  userId?: string,
  shareLevel: ShareLevel
}