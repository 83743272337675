import { Box, Button, Dialog, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import Iconify from '../Iconify.tsx'
import { useState } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import { useDeleteConversation } from '../../hooks/useConversations.ts'
import { ContentConversation } from '../../@types/conversation.ts'
import { LoadingButton } from '@mui/lab'
import { OverridableStringUnion } from '@mui/types'
import { IconButtonPropsSizeOverrides } from '@mui/material/IconButton/IconButton'

type Props = {
  conversation: ContentConversation | undefined
  size?: OverridableStringUnion<'small' | 'medium' | 'large', IconButtonPropsSizeOverrides>,
  id?: string,
  onMenuOpen?: () => void,
  onMenuClose?: () => void
}

export function ConversationActionsButton({
                                            conversation,
                                            size = 'medium',
                                            id,
                                            onMenuOpen,
                                            onMenuClose,
                                          }: Props) {
  const navigate = useNavigate()
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false)
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const { mutateAsync: deleteConversation, isLoading: deletionInProcess } = useDeleteConversation()

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
    onMenuOpen && onMenuOpen()
  }
  const handleMenuClose = () => {
    setMenuAnchorEl(null)
    onMenuClose && onMenuClose()
  }
  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setDeleteConfirmationOpen(true)
  }
  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setDeleteConfirmationOpen(false)
    handleMenuClose()
  }
  const handleDeleteConversationConfirmed = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (conversation?.contentConversationId) {
      navigate('/conversations')
      deleteConversation(conversation?.contentConversationId)
        .then(() => setDeleteConfirmationOpen(false))
    }
  }

  const open = Boolean(menuAnchorEl)
  const menuId = id || 'conversation-actions-positioned-menu'

  return (
    <>
      <IconButton
        onClick={handleMenuOpen}
        size={size}
      >
        <Iconify icon='ph:dots-three' />
      </IconButton>

      <Menu
        id={menuId}
        anchorEl={menuAnchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleDelete}>
          <Iconify icon='ph:trash' sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>

      <Dialog
        open={deleteConfirmationOpen}
        maxWidth='xs'
        fullWidth={true}
        onClose={() => setDeleteConfirmationOpen(false)}
      >

        <Box p={7} sx={{ textAlign: 'center' }}>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setDeleteConfirmationOpen(false)}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Iconify sx={{ mb: 2, fontSize: 48 }} icon='eva:alert-circle-outline' color='primary.main' />

          <Typography variant='h2' mb={4}>
            Are you sure you want to delete this conversation?
          </Typography>

          <Typography variant='standard' color='text.secondary' component='div' mb={4}>
            Deleting this conversation will permanently remove it from Masset.
            It will no longer have be accessible from the Masset portal.
          </Typography>

          <Stack spacing={2}>
            <LoadingButton
              loading={deletionInProcess}
              fullWidth={true}
              variant='contained'
              color='error'
              size='large'
              onClick={handleDeleteConversationConfirmed}
            >
              Delete
            </LoadingButton>
            <Button
              fullWidth={true}
              variant='outlined'
              size='large'
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  )
}