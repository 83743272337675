import {
  GeneralAccessType,
  GroupShareItem,
  ShareLevel,
  SharingItem,
  UserShareItem,
} from '../../../../@types/sharing.ts'
import { FormProvider } from '../../../hook-form'
import { useForm } from 'react-hook-form'
import { useContext, useEffect, useMemo } from 'react'
import { internalFormShareForSharingItem } from '../../../asset-detail/sharing/field/AssetShareFieldHooks.ts'
import { AssetShareField, InternalFormShareItem } from '../../../asset-detail/sharing/field/AssetShareField.tsx'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { GeneralAccessSelector } from '../../../asset-detail/sharing/internal/GeneralAccessSelector.tsx'
import { LoadingButton } from '@mui/lab'
import {
  AssetPermissionsDefaultsContextProvider,
} from '../../../asset-detail/sharing/field/AssetPermissionsDefaultsContext.tsx'
import { DialogContext } from '../../../../contexts/DialogContext.tsx'
import { useBulkUpdateAssetsShares } from '../../../../hooks/useSharing.ts'
import { SearchContext } from '../../../../contexts/SearchContext.tsx'

type BulkAssetPermissionFormProps = {
  assetIds: string[],
  shares: SharingItem[],
  generalAccessType: GeneralAccessType,
  generalAccessShareLevel: ShareLevel
}


type BulkAssetPermissionFormValues = {
  shares: InternalFormShareItem[],
  generalAccessType: GeneralAccessType,
  generalAccessShareLevel: ShareLevel
}

export function BulkAssetPermissionForm({
                                          assetIds,
                                          shares,
                                          generalAccessType,
                                          generalAccessShareLevel,
                                        }: BulkAssetPermissionFormProps) {
  const { setOpen } = useContext(DialogContext)
  const { refreshAfterBulkAction } = useContext(SearchContext)
  const { mutateAsync: bulkUpdateShares, isLoading: isSaving } = useBulkUpdateAssetsShares()

  const defaultShares = useMemo(() => {
    return shares.map(it => internalFormShareForSharingItem(it, it.shareLevel))
  }, [shares])

  const methods = useForm<BulkAssetPermissionFormValues>({
    defaultValues: {
      shares: defaultShares,
      generalAccessType: generalAccessType,
      generalAccessShareLevel: generalAccessShareLevel,
    },
    mode: 'onChange',
  })

  const { formState: { isDirty } } = methods

  useEffect(() => {
    const newShares = shares.map(it => internalFormShareForSharingItem(it, it.shareLevel))
    methods.reset({
      shares: newShares,
      generalAccessType: generalAccessType,
      generalAccessShareLevel: generalAccessShareLevel,
    })
  }, [shares])

  // handlers
  const handleCancel = () => setOpen(false)
  const handleSubmit = async (data: BulkAssetPermissionFormValues) => {
    const toUpdate = data.shares.map(it => ({
      groupId: (it.share as GroupShareItem | null)?.groupId,
      userId: (it.share as UserShareItem | null)?.userId,
      shareLevel: it.shareLevel,
    }))
    const generalAccessType = data.generalAccessType
    const generalAccessShareLevel = generalAccessType == GeneralAccessType.RESTRICTED ? null : data.generalAccessShareLevel
    await bulkUpdateShares({ assetIds: assetIds, shares: toUpdate, generalAccessType, generalAccessShareLevel })
    await refreshAfterBulkAction()
  }

  return (
    <>
      {/*
        * the default values are provided in a separate context
        * because the formProvider values change. RHF recommends
        * using a separate context if you need the default values
        *
        * these defaults are used by the fields to determine if
        * entries should be allowed to use the "mixed" state.
        * The "mixed" state is only available if the initial state
        * was mixed. Otherwise it's not selectable
      */}
      <AssetPermissionsDefaultsContextProvider
        shares={defaultShares}
        generalAccessType={generalAccessType}
        generalAccessShareLevel={generalAccessShareLevel}
      >
        <FormProvider methods={methods}>
          <AssetShareField name='shares' />
          <Divider sx={{ my: 3 }} />
          <Typography variant='h4' my={2} px={5}>General Access</Typography>
          <Box px={5}>
            <GeneralAccessSelector
              accessTypeName='generalAccessType'
              accessLevelName='generalAccessShareLevel'
            />
          </Box>

          <Divider sx={{ my: 3 }} />

          <Stack
            direction='row'
            justifyContent='end'
            px={5}
            mb={3}
          >
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
            >
              {isDirty && (
                <Typography
                  variant='standard'
                  color='text.deemphasized'
                  sx={{ fontStyle: 'italic' }}
                >
                  Pending changes
                </Typography>
              )}
              <Button
                variant='text'
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isSaving}
                variant='contained'
                color='primary'
                onClick={methods.handleSubmit(handleSubmit)}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </FormProvider>
      </AssetPermissionsDefaultsContextProvider>
    </>
  )
}