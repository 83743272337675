import {
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { useState } from 'react'
import { CategorySort } from '../../../contexts/SearchContext.tsx'
import { useLocalStorage } from 'usehooks-ts'

export const PREFS_GLOBAL_CATEGORIES_SORT_KEY = '_prefs_global_categories_sort'

export function CategorySortButton() {
  const [categorySort, setCategorySort] = useLocalStorage(PREFS_GLOBAL_CATEGORIES_SORT_KEY, CategorySort.USAGE)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleAlphabeticalClick = () => {
    setCategorySort(CategorySort.ALPHABETICALLY)
    handleClose()
  }
  const handleUsageClick = () => {
    setCategorySort(CategorySort.USAGE)
    handleClose()
  }

  const renderLabel = (sortBy: CategorySort) => sortBy == CategorySort.ALPHABETICALLY ?
    "By Name" : "By Usage"

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        variant="text"
        size='small'
        sx={{"& .MuiButton-endIcon": {ml: 0}}}
        endIcon={<Iconify color="primary.main" icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}/>}
        onClick={handleButtonClick}
      >
        <Typography component="span" variant="small" sx={{color: 'text.deemphasized', mr: 1}}>
          Sort By:
        </Typography>
        <Typography component="span" variant="smallHighlight" sx={{color: 'primary.main'}}>
          {renderLabel(categorySort)}
        </Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        transition
        style={{zIndex: 1402}}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper elevation={4}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList dense>
                  <MenuItem
                    onClick={handleAlphabeticalClick}>
                    <ListItemIcon>
                      {categorySort == CategorySort.ALPHABETICALLY && (
                        <Iconify icon='ph:check' />
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      Alphabetical
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={handleUsageClick}

                  >
                    <ListItemIcon>
                      {categorySort == CategorySort.USAGE && (
                        <Iconify icon='ph:check' />
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      Usage
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}