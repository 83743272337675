import Page from '../../components/Page'
import { ContentRoomTable } from '../../components/content-room/ContentRoomTable'
import { Button, Stack, Typography } from '@mui/material'
import Iconify from '../../components/Iconify'
import { Link } from 'react-router-dom'

export function ContentRooms() {
  return (
    <Page title='Personal Content Rooms'>
      <Stack direction='row' justifyContent='space-between' mb={4}>
        <Typography variant='h2'>Personal Content Rooms</Typography>
        <Button
          component={Link}
          to='/content-rooms/new'
          variant='contained'
          color='primary'
          size='small'
          startIcon={<Iconify icon={'eva:plus-outline'} />}
        >
         Add New Room
        </Button>
      </Stack>
      <ContentRoomTable />
    </Page>
  )
}