import { GroupListItem, HierarchyGroupItem } from '../../../../@types/group.ts'
import { useGroupsTableSelectedItemsContext } from './GroupsTableSelectedItemsContext.tsx'
import { useState } from 'react'
import { Box, Checkbox, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material'
import Iconify from '../../../Iconify.tsx'
import { fNumber } from '../../../../utils/formatNumber.ts'
import { Link } from 'react-router-dom'
import { GroupDeleteButton } from '../GroupDeleteButton.tsx'
import { GroupAssignAssetsButton } from './GroupAssignAssetsButton.tsx'

export function TableRowWithDepth({ group, groupsById, depth }: {
  group: HierarchyGroupItem,
  groupsById: Dictionary<GroupListItem>,
  depth: number
}) {
  const { selectedGroupIds, setGroupSelected } = useGroupsTableSelectedItemsContext()
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const handleCollapse = () => setCollapsed(!collapsed)
  const handleCheckboxClick = () => setGroupSelected(group.groupId, !selectedGroupIds.has(group.groupId))
  return (
    <>
      <TableRow>
        {/*<TableCell*/}
        {/*  sx={{ cursor: 'pointer' }}*/}
        {/*  onClick={handleCheckboxClick}*/}
        {/*>*/}
        {/*  <Checkbox*/}
        {/*    size='small'*/}
        {/*    sx={{ p: 0 }}*/}
        {/*    checked={selectedGroupIds.has(group.groupId)}*/}
        {/*  />*/}
        {/*</TableCell>*/}
        <TableCell>
          <Stack direction='row' sx={{ pl: (depth - 1) * 3 }} spacing={.5} alignItems='center'>
            <Box sx={{ height: 16, width: 16 }}>
              {!!group.children.length && (
                <Iconify
                  icon='eva:chevron-right-fill'
                  sx={{
                    height: 16,
                    width: 16,
                    transform: collapsed ? '' : 'rotate(90deg)',
                    transition: 'transform 150ms ease-in-out',
                    cursor: 'pointer',
                  }}
                  onClick={handleCollapse}
                />
              )}
            </Box>
            <Typography variant='smallHighlight'>{group.name}</Typography>
          </Stack>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='small'>{fNumber(group.numSelfMembers)}</Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='small'>{
            group.children.length === 0 ? '--' : fNumber(group.numInclusiveMembers)
          }</Typography>
        </TableCell>
        <TableCell>
          <Stack
            spacing={1}
            direction='row'
            justifyContent='end'
          >
            {/*<GroupAssignAssetsButton group={group} />*/}
            <IconButton
              component={Link}
              to={`/admin/groups/${group.groupId}`}
              size='small'
              color='primary'
            ><Iconify color='primary.main' icon='eva:edit-outline' /></IconButton>
            <GroupDeleteButton group={group} children={group.children} />
          </Stack>
        </TableCell>
      </TableRow>

      {
        !collapsed && group.children.map(child => (
            <TableRowWithDepth key={child.groupId} group={child} groupsById={groupsById} depth={depth + 1} />
          ),
        )
      }
    </>
  )
}