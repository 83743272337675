import { GenericIntegrationDescription } from './GenericIntegrationDescription'
import { Account } from '../../../../@types/account'

export function GoogleDriveIntegrationDescription({account} : {account: Account}) {
  const name = `Google Drive ${account.name ? `(${account.name})` : ""}`.trim()
  return (
    <GenericIntegrationDescription
      name={name}
      description="Google Drive lets you store files securely online, access them from anywhere, and collaborate with others. The Google Drive app connects Google Drive files to Masset assets for analysis, processing, and syncing."
      createdDate={account.createdAt}
    />
  )
}