import { alpha, useTheme } from '@mui/material/styles'
import { TableCell, TableRow, Typography } from '@mui/material'

export function TaskListNoTasksRow() {
  const theme = useTheme()

  const borderColor = alpha(theme.palette.divider, .175)
  return (
    <TableRow
      sx={{
        '& td': {
          borderTop: `1px solid ${borderColor}`,
          borderBottom: `1px solid ${borderColor}`,
          py: 1.5,
          '&:not(:last-child)': { borderRight: `1px solid ${borderColor}` },
        },
      }}
    >
      <TableCell
        colSpan={5}
        sx={{
          paddingLeft: `${theme.spacing(6)} !important`,
        }}
      >
        <Typography variant='small'>No Tasks</Typography>
      </TableCell>
    </TableRow>
  )
}
