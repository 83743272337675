import { GroupListItem, HierarchyGroupItem } from '../@types/group.ts'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'

export function toGroupItems(groups: GroupListItem[]): HierarchyGroupItem[] {
  const byParentId = groupBy(groups, 'parentGroupId')
  return groups.map(it => toGroupItem(it, byParentId))
}

export function toGroupItem(group: GroupListItem, byParentId: Dictionary<GroupListItem[]>): HierarchyGroupItem {
  const children = (byParentId[group.groupId] || []).map(it => toGroupItem(it, byParentId))
  const sorted = orderBy(children, 'name', 'asc')
  return { ...group, children: sorted }
}