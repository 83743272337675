import api from '@api'
import {
  CreateTaskCommentResponse,
  CreateTaskSectionResponse, GetTaskCommentsResponse,
  GetTaskResponse,
  GetTaskSectionsResponse,
  TaskClosedReason,
  TaskSearchOptions,
  TaskSearchResponse,
  UpdateTaskDescriptionResponse,
  UpdateTaskIsClosedResponse, UpdateTaskNameResponse,
  UpdateTaskSectionDisplayOrderResponse,
  UpdateTaskSectionNameResponse,
} from '../@types/task.ts'


export async function getTask(taskId: string) {
  const response = await api.get<GetTaskResponse>(`/api/v1/tasks/${taskId}`)
  return response.data
}

export async function searchForTasks(options: TaskSearchOptions) {
  const response = await api.post<TaskSearchResponse>('/api/v1/tasks/search', options)
  return response.data
}

export async function setTaskIsClosed(taskId: string, isClosed: boolean, closedReason: TaskClosedReason | null) {
  const response = await api.put<UpdateTaskIsClosedResponse>(`/api/v1/tasks/${taskId}/is-closed`, {
    isClosed,
    closedReason,
  })
  return response.data
}

export async function setTaskName(taskId: string, name: string) {
  const response = await api.put<UpdateTaskNameResponse>(`/api/v1/tasks/${taskId}/name`, {
    name,
  })
  return response.data
}

export async function setTaskDescription(taskId: string, description: string) {
  const response = await api.put<UpdateTaskDescriptionResponse>(`/api/v1/tasks/${taskId}/description`, {
    description,
  })
  return response.data
}

export async function deleteTask(taskId: string) {
  const response = await api.delete(`/api/v1/tasks/${taskId}`)
  return response.data
}

// -------------------------------------------
// TASK COMMENTS
// -------------------------------------------

export async function getTaskComments(taskId: string) {
  const response = await api.get<GetTaskCommentsResponse>(`/api/v1/tasks/${taskId}/comments`)
  return response.data
}

export async function createTaskComment(taskId: string, content: string) {
  const response = await api.post<CreateTaskCommentResponse>(`/api/v1/tasks/${taskId}/comments`, {
    content,
  })
  return response.data
}

// -------------------------------------------
// TASK SECTIONS
// -------------------------------------------
export async function getTaskSections() {
  const response = await api.get<GetTaskSectionsResponse>('/api/v1/tasks/sections')
  return response.data
}

export async function createTaskSection(name: string | null, displayOrder: number | null) {
  const payload = { name, displayOrder }
  const response = await api.post<CreateTaskSectionResponse>('/api/v1/tasks/sections', payload)
  return response.data
}

export async function updateTaskSectionName(taskSectionId: string, name: string) {
  const response = await api.put<UpdateTaskSectionNameResponse>(
    `/api/v1/tasks/sections/${taskSectionId}/name`,
    { name },
  )
  return response.data
}

export async function updateTaskSectionDisplayOrder(taskSectionId: string, displayOrder: number) {
  const response = await api.put<UpdateTaskSectionDisplayOrderResponse>(
    `/api/v1/tasks/sections/${taskSectionId}/display-order`,
    { displayOrder },
  )
  return response.data
}

export async function deleteTaskSection(taskSectionId: string) {
  const response = await api.delete(`/api/v1/tasks/sections/${taskSectionId}`)
  return response.data
}