import { useMutation, useQuery } from '@tanstack/react-query'
import {
  bulkUpdateAssetsShares,
  getAssetBulkSharesState,
  getSharesForAsset,
  searchForShareableItems,
  updateAssetShares,
} from '../clients/SharingClient'
import {
  AssetSharesResponse,
  GeneralAccessType,
  ShareLevel,
  SharingSearchResponse,
  UpdateAssetShareItem,
} from '../@types/sharing'
import { DEFAULT_SHARING_ACCESS_TYPE, DEFAULT_SHARING_SHARE_LEVEL } from '../utils/appConstants'

export const DEFAULT_ASSET_SHARES_DATA = {
  shares: [],
  generalAccessType: DEFAULT_SHARING_ACCESS_TYPE,
  generalAccessShareLevel: DEFAULT_SHARING_SHARE_LEVEL,
} as AssetSharesResponse

export function useSharesForAsset(assetId: string) {
  return useQuery({
    queryKey: ['asset-shares', assetId],
    queryFn: async () => getSharesForAsset(assetId),
  })
}

export function useSharingSearch(term: string, limit: number = 10) {
  return useQuery({
    queryKey: ['sharing-search', term, limit],
    queryFn: async () => {
      const runQuery = Boolean(term)
      if (!runQuery) return Promise.resolve({ shares: [] } as SharingSearchResponse)
      return searchForShareableItems(term, limit)
    },
    keepPreviousData: true,
  })
}


export function useBulkAssetSharesState(assetIds: string[], enabled: boolean = true) {
  return useQuery({
    enabled,
    queryKey: ['bulk-asset-shares', assetIds],
    queryFn: async () => getAssetBulkSharesState(assetIds),
  })
}

// -------------------------------------------------
// Mutations
// -------------------------------------------------
export type UpdateAssetSharesParams = {
  assetId: string,
  shares: UpdateAssetShareItem[],
  generalAccessType: GeneralAccessType,
  generalAccessShareLevel: ShareLevel | null
}

export function useUpdateAssetShares() {
  return useMutation({
    mutationKey: ['update-asset-shares'],
    mutationFn: (p: UpdateAssetSharesParams) => updateAssetShares(p.assetId, p.shares, p.generalAccessType, p.generalAccessShareLevel),
  })
}

export type BulkUpdateAssetsSharesParams = {
  assetIds: string[],
  shares: UpdateAssetShareItem[],
  generalAccessType: GeneralAccessType,
  generalAccessShareLevel: ShareLevel | null
}

export function useBulkUpdateAssetsShares() {
  return useMutation({
    mutationFn: (p: BulkUpdateAssetsSharesParams) => bulkUpdateAssetsShares(p.assetIds, p.shares, p.generalAccessType, p.generalAccessShareLevel),
  })
}