import Iconify from '../../Iconify'
import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import { useAccountDelete } from '../../../hooks/useAccounts'
import { useNavigate } from 'react-router-dom'

type IntegrationAccountDeleteButtonProps = {
  accountId: string
}
export function IntegrationAccountDeleteButton({ accountId } : IntegrationAccountDeleteButtonProps){
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { mutateAsync: deleteAccount, isLoading } = useAccountDelete()

  const handleDeleteClick = async () => {
    await deleteAccount(accountId)
    setOpen(false)
    navigate("/profile/integrations/list")
  }

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="red"
        onClick={() => setOpen(true)}
        startIcon={ <Iconify color="text.red" icon='eva:trash-2-outline' /> }
        sx={{ "& .MuiButton-startIcon" : { marginRight: "4px", marginTop: "-2px" } }}
      >Delete</Button>

      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth={true}
        onClose={() => setOpen(false)}
      >

        <Box p={7} sx={{textAlign: "center"}}>
          <Box sx={{position: "absolute", right: 0, top: 0, mr: 1, mt: 1}}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon="eva:close-fill"/>
            </IconButton>
          </Box>

          <Iconify sx={{mb: 2, fontSize: 48}} icon="eva:alert-circle-outline" color="primary.main" />

          <Typography variant="h2" mb={4}>
            Are you sure you want to delete this integration?
          </Typography>

          <Typography variant="standard" color="text.secondary" component="div" mb={4}>
            Deleting the integration will disable all associated functionality. This could break
            functionality.
          </Typography>

          <Stack spacing={2} >
            <LoadingButton
              loadingPosition="start"
              loading={isLoading}
              fullWidth={true}
              variant="contained"
              color="primary"
              size="large"
              onClick={handleDeleteClick}
            >
              Delete Integration
            </LoadingButton>
            <Button
              fullWidth={true}
              variant="outlined"
              size="large"
              onClick={() => { setOpen(false) }}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  )
}