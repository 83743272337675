import { Category } from '../../@types/category'
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material'
import Iconify from '../Iconify'
import { useContext, useMemo } from 'react'
import { SearchContext } from '../../contexts/SearchContext'
import ToggleTag from '../tags/ToggleTag'
import { orderCategoryValues } from '../../utils/category.ts'

type SearchAssetCategoryProps = {
  category: Category,
  selectedCategoryValues: Set<string>
}


export default function SearchAssetCategory({ category, selectedCategoryValues }: SearchAssetCategoryProps) {
  const { addCategoryValues, removeCategoryValues, categorySort } = useContext(SearchContext);
  const toggleCategoryValue = (categoryValueId: string, isSelected: boolean) =>
    isSelected ? addCategoryValues([categoryValueId]) : removeCategoryValues([categoryValueId]);

  // calculated props
  const orderedCategoryValues = useMemo(() => orderCategoryValues(category, categorySort), [category, categorySort])
  const selectedNum = category.values.filter(cv => selectedCategoryValues.has(cv.categoryValueId)).length

  return (
    <>
      <Accordion
        defaultExpanded={false}
        disableGutters={true}
        sx={{
          "&&": { boxShadow: "none" },
          "&:before": { display: "none" },
          "background": "transparent"
        }}
      >
        <AccordionSummary
          expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
          sx={{
            p: 0,
            m: 0,
            "&&": { minHeight: 0 },
            "& .MuiAccordionSummary-content": { margin: 0 }
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="smallHighlight"> {category.name} </Typography>
            <Box>
              <Typography variant="smallHighlight" color="primary"
                          sx={{ display: "inline" }}>{selectedNum}</Typography>
              <Typography variant="smallHighlight"
                          sx={{ display: "inline" }}>/{category.values.length}</Typography>
            </Box>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Stack direction="column" spacing={1} mt={1} >
            {orderedCategoryValues.map(value => {
              const selected = selectedCategoryValues.has(value.categoryValueId);
              return (
                <Box key={value.categoryValueId}>
                  <ToggleTag
                    selected={selected}
                    value={value.categoryValueId}
                    displayText={value.value}
                    onChange={() => {
                      toggleCategoryValue(value.categoryValueId, !selected)
                    }}
                    helpText={value.description}
                  />
                </Box>
              );
            })}
          </Stack>
        </AccordionDetails>
      </Accordion>

    </>
  );
}
