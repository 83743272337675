import { Controller, useFormContext } from 'react-hook-form'
import { Autocomplete, Chip, TextField } from '@mui/material'
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete'
import * as React from 'react'
import { ChipTypeMap } from '@mui/material/Chip'
import { TextFieldVariants } from '@mui/material/TextField/TextField'


const defaultGetOptionLabel = (item: any) => {
  return item?.label || item
}


interface FormAutoCompleteProps<T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string,
  label?: string,
  placeholder?: string,
  variant?: TextFieldVariants
}

export default function RHFAutoComplete<T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
    name,
    options,
    variant = 'filled',
    ...props
  }: Omit<FormAutoCompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'error'>) {

  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Autocomplete
            filterSelectedOptions
            options={options}
            value={field.value}
            onChange={(e, newValue) => {
              field.onChange(newValue)
            }}
            renderTags={(value, getTagProps, ownerState) => {

              const getOptionLabel = ownerState.getOptionLabel || defaultGetOptionLabel
              return (
                <>
                  {value.map((item, i) => {
                    const { key, ...tagProps } = getTagProps({ index: i })
                    // @ts-ignore
                    const isError = error?.length && !!error[i]
                    return (
                      <Chip
                        key={key}
                        {...tagProps}
                        size='small'
                        label={getOptionLabel(item)}
                        color={isError ? 'error' : 'default'}
                      />
                    )
                  })}
                </>
              )
            }}
            renderInput={(params) => (
              <TextField
                variant={variant}
                label={props.label}
                placeholder={props.placeholder}
                {...params}
                error={!!error}
                helperText={error?.message}
              />
            )}
            {...props}
          />
        )
      }}
    />
  )
}