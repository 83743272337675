import { TaskAsset } from '../../../@types/task.ts'
import { Box, Typography } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'

export function DrawerAssetTag({ taskAsset }: { taskAsset?: TaskAsset }) {
  if (!taskAsset) {
    return (
      <Typography variant='small' color='text.deemphasized'>No Asset Association</Typography>
    )
  }

  return (
    <UndecoratedLink to={`/assets/detail/${taskAsset.assetId}`} target='_blank'>
      <StyleTagBox
        sx={{
          maxWidth: 250,
          overflow: 'hidden',
          verticalAlign: 'bottom',
        }}
        textOverflow='ellipsis'
      >
        <Typography variant='small' noWrap>{taskAsset.assetName}</Typography>
      </StyleTagBox>
    </UndecoratedLink>
  )
}

const UndecoratedLink = styled(RouterLink)(() => ({
  textDecoration: 'none!important',
  color: 'inherit',
}))


const StyleTagBox = styled(Box)(({ theme }) => {
  const isLight = theme.palette.mode == 'light'
  return {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(.25, 1),
    backgroundColor: isLight ? alpha(theme.palette.primary.lighter, .25) : alpha(theme.palette.primary.darker, .75),
    display: 'inline-block',
  }
})
