import { Stack, Typography } from "@mui/material";
import { Category } from "../../../@types/category";
import AddTagsToCategoryDialog from "../../../pages/asset/AddTagsToCategoryDialog";
import EditableCategoryValue from "./EditableCategoryValue";
import CategoryDeleteButton from "./CategoryDeleteButton";
import CategoryRenameButton from "./CategoryRenameButton";
import { CategorySort } from '../../../contexts/SearchContext.tsx'
import { useMemo } from 'react'
import { orderCategoryValues } from '../../../utils/category.ts'

type EditableAssetCategoryProps = {
  category: Category,
  sort : CategorySort
}


export default function EditableAssetCategory({ category, sort }: EditableAssetCategoryProps) {
  const orderedCategoryValues = useMemo(() => orderCategoryValues(category, sort), [category, sort])
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="start"
        sx={{ mb: 1 }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h3"> {category.name} </Typography>
          <Stack direction="row" alignItems="center">
            <CategoryRenameButton category={category} />
            <CategoryDeleteButton category={category} />
          </Stack>
        </Stack>
        <AddTagsToCategoryDialog
          categoryId={category.categoryId}
          showAutoAssignOption={false}
        />
      </Stack>
      <div>
        {orderedCategoryValues.map(value => {
          return (
            <EditableCategoryValue
              key={value.categoryValueId}
              categoryValue={value}
            />
          )
        })}
      </div>
    </>
  )
}