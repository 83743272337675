import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createAssetExternalShare,
  deleteAssetExternalShare,
  getAssetExternalShares,
  updateAssetExternalShare,
} from '../clients/AssetExternalShareClient.ts'
import { CreateAssetExternalShareRequest, GetAssetExternalSharesResponse } from '../@types/asset-external-share.ts'

export const DEFAULT_ASSET_EXTERNAL_SHARES_DATA = {
  shares: [],
} as GetAssetExternalSharesResponse


export function useAssetExternalShares(assetId: string) {
  return useQuery({
    queryKey: ['assets', assetId, 'external-shares'],
    queryFn: async () => getAssetExternalShares(assetId),
  })
}


type CreateAssetExternalShareParams = CreateAssetExternalShareRequest & { assetId: string }

export function useCreateAssetExternalShare() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: CreateAssetExternalShareParams) => createAssetExternalShare(params.assetId, params),
    onSuccess: (resp, { assetId }) => {
      queryClient.setQueryData(
        ['assets', assetId, 'external-shares'],
        (oldData?: GetAssetExternalSharesResponse) => oldData ? {
          ...oldData,
          shares: [...oldData.shares, resp],
        } : oldData,
      )
    },
  })
}

type EditAssetExternalShareParams = CreateAssetExternalShareRequest & { assetId: string, externalShareId: string }

export function useUpdateAssetExternalShare() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: EditAssetExternalShareParams) => updateAssetExternalShare(params.assetId, params.externalShareId, params),
    onSuccess: (resp, { assetId, externalShareId }) => {
      queryClient.setQueryData(
        ['assets', assetId, 'external-shares'],
        (oldData?: GetAssetExternalSharesResponse) => {
          if (!oldData) return oldData
          const mappedShares = oldData.shares
            .map(it => it.externalShareId == externalShareId ? { ...it, ...resp } : it)
          return { ...oldData, shares: mappedShares }
        },
      )
    },
  })
}

type DeleteAssetExternalShareParams = { assetId: string, externalShareId: string }

export function useDeleteAssetExternalShare() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: DeleteAssetExternalShareParams) => deleteAssetExternalShare(params.assetId, params.externalShareId),
    onSuccess: (_, { assetId, externalShareId }) => {
      queryClient.setQueryData(
        ['assets', assetId, 'external-shares'],
        (oldData?: GetAssetExternalSharesResponse) => oldData ? {
          ...oldData,
          shares: oldData.shares.filter(it => it.externalShareId != externalShareId),
        } : oldData,
      )
    },
  })
}