import { Alert, AlertTitle, Table, TableBody, TableCell } from '@mui/material'
import Iconify from '../../Iconify'
import { DEFAULT_GROUP_LIST_ITEMS_DATA, useGroupsList } from '../../../hooks/useGroups'
import { useMemo } from 'react'
import EmptyContent from '../../EmptyContent'
import { toGroupItems } from '../../../utils/groupUtils.ts'
import { HierarchyTableHeader } from './table/HierarchyTableHeader.tsx'
import { GroupsTableSelectedItemsContextProvider } from './table/GroupsTableSelectedItemsContext.tsx'
import { TableRowWithDepth } from './table/TableRowWithDepth.tsx'

export function HierarchyTable() {
  const query = useGroupsList()
  const { groups, groupsById } = query.data || DEFAULT_GROUP_LIST_ITEMS_DATA
  const rootGroups = useMemo(() => toGroupItems(groups).filter(it => !it.parentGroupId), [groups])

  if (query.isError) {
    return (
      <Alert severity='error' variant='outlined' icon={<Iconify icon='eva:error-outline' />}>
        <AlertTitle>Hmmm... that's not right</AlertTitle>
        Well, this is embarrassing. It looks like something went wrong trying to load groups. Go ahead and give
        the page a refresh to fix it. If this issue continues, please reach out and let us know at
        support@getmasset.com!
      </Alert>
    )
  }

  return (
    <GroupsTableSelectedItemsContextProvider>
      <Table>
        <HierarchyTableHeader groups={groups} />
        <TableBody>
          {
            rootGroups.map(group => (
              <TableRowWithDepth key={group.groupId} group={group} groupsById={groupsById} depth={1} />))
          }

          <TableCell colSpan={3}>
            {
              groups.length === 0 && (
                <EmptyContent
                  title='No groups'
                  description="Looks like you haven't created any groups. Click the Add button above to get started!"
                />
              )
            }
          </TableCell>

        </TableBody>
      </Table>
    </GroupsTableSelectedItemsContextProvider>
  )
}
