import { Task, TaskAsset, TaskClosedReason } from '../../../@types/task.ts'
import { alpha, styled, useTheme } from '@mui/material/styles'
import { useSetTaskClosed } from '../../../hooks/useTasks.ts'
import { useRef, useState } from 'react'
import { useHover } from 'usehooks-ts'
import { Avatar, Box, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { fDateTime } from '../../../utils/formatTime.ts'
import { MoreActionsButton } from './MoreActionsButtons.tsx'
import { CheckButton } from './CheckButton.tsx'
import { Link as RouterLink } from 'react-router-dom'
import { useTaskTableContext } from './TaskTableContext.tsx'

export function TaskTableRow({ task }: { task: Task }) {
  const theme = useTheme()
  const { setDetailsTaskId } = useTaskTableContext()
  const { mutateAsync: setComplete } = useSetTaskClosed()
  const [selected, setSelected] = useState<boolean>(false)
  const ref = useRef<HTMLTableRowElement>(null)
  const nameCellRef = useRef<HTMLTableCellElement>(null)
  const isRowActive = useHover(ref) || selected
  const isNameCellActive = useHover(nameCellRef)

  const handleCompleteClick = async () => {
    setComplete({
      taskId: task.taskId,
      isClosed: !task.isClosed,
      closedReason: task.isClosed ? null : TaskClosedReason.COMPLETE,
    })
  }

  const handleMoreActionsOpened = () => setSelected(true)
  const handleMoreActionsClosed = () => setSelected(false)
  const handleDetailsOnClick = () => setDetailsTaskId(task.taskId)

  const borderColor = alpha(theme.palette.divider, .175)
  const hasCreatedBy = Boolean(task.createdByUserId)
  const createdByName = task.createdByName || 'Unknown'
  const isComplete = task.isClosed
  const deemphasizedOpacity = isComplete ? .5 : 1

  return (
    <TableRow
      ref={ref}
      sx={{
        '&:hover td': {
          background: theme.palette.action.hover,
        },
        '& td': {
          borderTop: `1px solid ${borderColor}`,
          borderBottom: `1px solid ${borderColor}`,
          height: 45,
          '&:not(:last-child)': { borderRight: `1px solid ${borderColor}` },
        },
      }}
    >
      {/* name */}
      <TableCell
        ref={nameCellRef}
        onClick={handleDetailsOnClick}
        sx={{cursor: "pointer"}}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Stack direction='row' spacing={1} alignItems='center'>
            <CheckButton task={task} onClick={handleCompleteClick} />
            <Typography
              variant='smallHighlight'
              sx={{ opacity: deemphasizedOpacity }}
            >{task.name}</Typography>
            <CommentCount count={task.commentCount} />
          </Stack>
          {isNameCellActive && (
            <IconButton size="small">
              <Iconify icon='ph:caret-right-bold' height={14} width={14}/>
            </IconButton>
          )}
        </Stack>
      </TableCell>

      {/* asset */}
      <TableCell sx={{
        py: `${theme.spacing(.5)} !important`,
        px: `${theme.spacing(1)} !important`,
      }}>
        {/* currently we only show the first asset */}
        {task.assets[0] && (<AssetTag taskAsset={task.assets[0]} />)}
      </TableCell>

      {/* created by */}
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {
          hasCreatedBy && (
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              sx={{ opacity: deemphasizedOpacity }}
            >
              <Avatar sx={{ height: 18, width: 18, bgcolor: alpha(theme.palette.info.dark, .35) }}>
                <Iconify icon='eva:person-outline' sx={{ height: 14, width: 14 }} />
              </Avatar>
              <Typography variant='small' noWrap>{createdByName}</Typography>
            </Stack>
          )
        }
      </TableCell>

      {/* create at */}
      <TableCell
        sx={{
          borderRight: 'none !important',
        }}
      >
        <Typography
          variant='small'
          sx={{
            opacity: deemphasizedOpacity,
            textWrap: 'nowrap',
          }}
        >
          {fDateTime(task.createdAt)}
        </Typography>
      </TableCell>

      {/* actions button */}
      <TableCell
        sx={{
          padding: '0px !important',
          minWidth: '36px',
        }}>
        {
          isRowActive && (
            <MoreActionsButton
              task={task}
              onOpen={handleMoreActionsOpened}
              onClose={handleMoreActionsClosed}
            />
          )
        }
      </TableCell>
    </TableRow>
  )
}

function CommentCount({count} : {count: number}){
  if(count == 0){
    return <></>
  }
  return (
    <Stack
      direction="row"
      spacing={.5}
      alignItems="center"
      pl={1}
    >
      <Typography variant="small" color="text.deemphasized">{count}</Typography>
      <Iconify icon="ph:chats" color="text.deemphasized" height={16} width={16}/>
    </Stack>
  )
}

const UndecoratedLink = styled(RouterLink)(() => ({
  textDecoration: 'none!important',
  color: 'inherit',
}))

function AssetTag({ taskAsset }: { taskAsset: TaskAsset }) {
  return (
    <UndecoratedLink to={`/assets/detail/${taskAsset.assetId}`} target='_blank'>
      <StyleTagBox
        sx={{
          maxWidth: 150,
          overflow: 'hidden',
          verticalAlign: 'bottom',
        }}
        textOverflow='ellipsis'
      >
        <Typography variant='tiny' noWrap>{taskAsset.assetName}</Typography>
      </StyleTagBox>
    </UndecoratedLink>
  )
}

const StyleTagBox = styled(Box)(({ theme }) => {
  const isLight = theme.palette.mode == 'light'
  return {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(.25, 1),
    backgroundColor: isLight ? alpha(theme.palette.primary.lighter, .25) : alpha(theme.palette.primary.darker, .75),
    display: 'inline-block',
  }
})
