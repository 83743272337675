import { Account } from '../../../@types/account.ts'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AssetUploadContext } from '../../../contexts/AssetUploadContext.tsx'
import { useDrivePicker } from '../../google-picker/useDrivePicker.tsx'
import { OAUTH_PROVIDER_NAMES, OauthProvider } from '../../../@types/oauth.ts'
import { Box, Stack, Typography } from '@mui/material'
import Image from '../../Image.tsx'
import { useTheme } from '@mui/material/styles'

export function GoogleDriveUploadFilePicker({ account }: { account: Account }) {
  const theme = useTheme()
  const navigate = useNavigate()
  const { createLinkedAssetBatch } = useContext(AssetUploadContext)
  const { openPicker } = useDrivePicker()
  const handlePickerCallback = (data: any) => {
    if (data.action == 'picked') {
      const docs = data.docs || []
      // @ts-ignore
      const urls = docs.map(it => it.url)
      createLinkedAssetBatch(urls).then(() => {
        navigate('/assets/upload/finalize')
      })
    }
  }
  const handleClick = () => {
    if (account) {
      openPicker(account.accountId, {
        callback: handlePickerCallback,
        multiSelect: true,
        maxItems: 40,
      })
    }
  }
  if (account.provider != OauthProvider.GOOGLE_DRIVE) return <></>
  return (
    <Box
      onClick={handleClick}
      sx={{
        border: `1px dashed ${theme.palette.divider}`,
        borderRadius: 1,
        p: 1,
        width: 360,
        cursor: "pointer",
        background: theme.palette.background.paper,
      }}
    >
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
      >
        <Box>
          <Image
            src={`/assets/integrations/google-drive.png`}
            ratio='1/1'
            backgroundType='contain'
            sx={{ width: 36, height: 36 }}
          />
        </Box>
        <Stack direction='column'>
          <Typography variant='standardHighlight'>{account.name}</Typography>
          <Typography variant='caption' >{OAUTH_PROVIDER_NAMES[account.provider]}</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}