import { ChangeEvent, useState } from 'react'
import { Box, Button, Dialog, FormControl, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import Iconify from '../../Iconify'
import { LoadingButton } from '@mui/lab'
import { CategoryValue } from '../../../@types/category'
import { useCategoryValuesWithCategories, useEditCategoryValueMutation } from '../../../hooks/useCategories'

export default function CategoryValueEditButton({ categoryValue }: { categoryValue: CategoryValue }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>(categoryValue.value);
  const [description, setDescription] = useState<string>(categoryValue.description || "");
  const { mutateAsync: editCategoryValue } = useEditCategoryValueMutation()
  const categoryValuesWithCategories = useCategoryValuesWithCategories()
  const valueWithCategory = categoryValuesWithCategories[categoryValue.categoryValueId];

  const onConfirmClick = async () => {
    setLoading(true);
    const { categoryId } = valueWithCategory.category;
    const { categoryValueId } = categoryValue;
    await editCategoryValue({categoryId, categoryValueId, name, description})
    setLoading(false);
    setOpen(false);
  };

  const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => setName(ev.target.value || "")
  const handleDescriptionChange = (ev: ChangeEvent<HTMLTextAreaElement>) => setDescription(ev.target.value || "")
  const handleClose = () => {
    setOpen(false)
  }

  const canSave = (name && name !== categoryValue.value) || description != (categoryValue.description || "")
  return (
    <>
      <Tooltip title="Edit">
        <IconButton
          size="small"
          onClick={() => setOpen(true)}
        >
          <Iconify icon="eva:edit-outline" />
        </IconButton>
      </Tooltip>


      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth={true}
        onClose={handleClose}
      >

        <Box p={7} sx={{ textAlign: "center" }}>
          <Box sx={{ position: "absolute", right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={handleClose}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Box>

          <Iconify sx={{ mb: 2, fontSize: 48 }} icon="eva:alert-circle-outline" color="primary.main" />

          <Typography variant="h2" mb={4}>
            How would you like to edit your tag?
          </Typography>

          <FormControl fullWidth sx={{ mb: 4 }} size="small">
            <Stack spacing={3}>
            <TextField
              id="select"
              value={name}
              label="Name"
              onChange={handleNameChange}
            />

            <TextField
              multiline
              id="description"
              value={description}
              label="Description"
              minRows={3}
              maxRows={5}
              onChange={handleDescriptionChange}
            />
            </Stack>
          </FormControl>

          <Stack spacing={2}>
            <LoadingButton
              loadingPosition="start"
              loading={loading}
              fullWidth={true}
              variant="contained"
              color="primary"
              size="large"
              onClick={onConfirmClick}
              disabled={!canSave}
            >
              Update tag
            </LoadingButton>
            <Button
              fullWidth={true}
              variant="outlined"
              size="large"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  );
}