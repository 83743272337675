import { Box, Checkbox, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { SearchContext } from '../../../contexts/SearchContext.tsx'
import { useContext } from 'react'
import { SearchAssetResult } from '../../../@types/search.ts'
import { alpha, styled, useTheme } from '@mui/material/styles'
import Image from '../../Image.tsx'
import { srcForPreviewItem } from '../images.tsx'
import { AssetPreviewType } from '../../../@types/asset.ts'
import { fDateTime } from '../../../utils/formatTime.ts'
import { Link as RouterLink } from 'react-router-dom'
import { AssetConsumeIconButton } from './AssetConsumeIconButton.tsx'
import { useSearchSelectedAssetsContext } from '../SearchSelectedAssetsContext.tsx'
import { RequestNewAssetButton } from '../RequestNewAssetButton.tsx'
import { useAssetRequestSettings } from '../../../hooks/useBootstrap.ts'
import { AssetRequestMode } from '../../../@types/auth.ts'
import { VisibleForRole } from '../../../guards/VisibleForRole.tsx'
import { UserType } from '../../../@types/user.ts'

export function SearchListViewTable() {
  const { results } = useContext(SearchContext)
  const { selectedAssetIds, setSelectedAssets } = useSearchSelectedAssetsContext()
  const handleHeaderCheckboxClick = () => {
    const isHeaderCheckboxActive = selectedAssetIds.size > 0

    // if everything is selected, then deselect everything
    if (isHeaderCheckboxActive) {
      setSelectedAssets(new Set())
    }
    // otherwise, select everything
    else {
      setSelectedAssets(new Set(results.map(it => it.assetId)))
    }
  }
  const isHeaderCheckboxIndeterminate = selectedAssetIds.size > 0 && selectedAssetIds.size != results.length
  const isHeaderCheckboxSelected = selectedAssetIds.size > 0 && selectedAssetIds.size == results.length
  return (
    <Table stickyHeader size='small'>
      <TableHead>
        <TableRow>
          <VisibleForRole roles={[UserType.ADMIN]}>
            <TableCell>
              <Checkbox
                size='small'
                sx={{ p: 0 }}
                indeterminate={isHeaderCheckboxIndeterminate}
                checked={isHeaderCheckboxSelected}
                onClick={handleHeaderCheckboxClick}
              />
            </TableCell>
          </VisibleForRole>
          <TableCell></TableCell>
          <TableCell>Name</TableCell>
          <TableCell sx={{ minWidth: 150, textAlign: 'right' }}>Uploaded</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {results.map(it => <SearchListViewTableRow key={it.assetId} result={it} />)}
        <RequestAssetTableRow />
      </TableBody>
    </Table>
  )
}


function SearchListViewTableRow({ result }: { result: SearchAssetResult }) {
  // context
  const { selectedAssetIds, setAssetSelected } = useSearchSelectedAssetsContext()

  // handlers
  const handleCheckboxClick = () => {
    const selected = selectedAssetIds.has(result.assetId)
    setAssetSelected(result.assetId, !selected)
  }

  // calculated props
  const imgSrc = srcForPreviewItem(result.assetPreviewId, AssetPreviewType.THUMBNAIL_SM)
  const isSelected = selectedAssetIds.has(result.assetId)
  return (
    <TableRow>
      <VisibleForRole roles={[UserType.ADMIN]}>
        <CheckboxTableCell
          onClick={handleCheckboxClick}
        >
          <UnpaddedCheckbox
            size='small'
            checked={isSelected}
          />
        </CheckboxTableCell>
      </VisibleForRole>
      <PreviewTableCell>
        <UndecoratedLink to={`/assets/detail/${result.assetId}`}>
          <PreviewContainer>
            <Image alt={result.name} src={imgSrc} ratio='1/1' backgroundType='contain' />
          </PreviewContainer>
        </UndecoratedLink>
      </PreviewTableCell>
      <NameTableCell>
        <UndecoratedLink to={`/assets/detail/${result.assetId}`}>
          <Stack direction='column' spacing={.5}>
            <Typography variant='standardHighlight'>{result.name}</Typography>
            <TwoLineTypography
              variant='small'
              color='text.deemphasized'
            >{result.description}</TwoLineTypography>
          </Stack>
        </UndecoratedLink>
      </NameTableCell>
      <UploadedAtTableCell>
        <Typography
          variant='small'
          color='text.deemphasized'>
          {fDateTime(result.createdAt)}
        </Typography>
      </UploadedAtTableCell>
      <TableCell><AssetConsumeIconButton asset={result} color='primary' /></TableCell>
    </TableRow>
  )
}

function RequestAssetTableRow() {
  const theme = useTheme()
  const { assetRequestMode } = useAssetRequestSettings()
  if (assetRequestMode == AssetRequestMode.DISABLED) return <></>
  return (
    <TableRow sx={{
      backgroundColor: `${alpha(theme.palette.primary.main, .125)} !important`,
    }}>
      <VisibleForRole roles={[UserType.ADMIN]}>
        <CheckboxTableCell />
      </VisibleForRole>
      <PreviewTableCell>
        <PreviewContainer>
          <Image
            disabledEffect
            visibleByDefault
            alt='empty content'
            src={'/assets/illustrations/illustration_empty_content.svg'}
            sx={{ mt: 1, mb: 3 }}
          />
        </PreviewContainer>
      </PreviewTableCell>
      <NameTableCell>
        <Typography variant='h4'>
          Not finding that specific thing you need?
        </Typography>
      </NameTableCell>
      <TableCell colSpan={2}><RequestNewAssetButton /></TableCell>
    </TableRow>
  )
}

const CheckboxTableCell = styled(TableCell)(() => ({
  cursor: 'pointer',
}))

const UnpaddedCheckbox = styled(Checkbox)(() => ({
  padding: 0,
}))

const PreviewTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}))

const NameTableCell = styled(TableCell)(() => ({}))

const UploadedAtTableCell = styled(TableCell)(() => ({
  textAlign: 'right',
}))

const UndecoratedLink = styled(RouterLink)(() => ({
  textDecoration: 'none!important',
  color: 'inherit',
}))

const TwoLineTypography = styled(Typography)(() => ({
  display: '-webkit-box',
  WebkitLineClamp: 2,              // Limit to 2 lines
  WebkitBoxOrient: 'vertical',     // Vertical orientation for line clamp
  overflow: 'hidden',              // Hide overflow
  textOverflow: 'ellipsis',        // Add ellipsis

  // Fallback for Firefox
  lineHeight: '1.5',
  maxHeight: '3em',                // 1.5em * 2 lines = 3em
}))

const PreviewContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.bg.warmGreyLight : theme.palette.background.neutral,
  borderRadius: 8,
  overflow: 'hidden',
  height: 48,
  width: 48,
}))
