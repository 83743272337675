import { useDownload } from '../../../hooks/useDownload.tsx'
import { AssetStorageType, AssetType } from '../../../@types/asset.ts'
import { Box, IconButton, Tooltip } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { SearchAssetResult } from '../../../@types/search.ts'
import { IconButtonOwnProps } from '@mui/material/IconButton/IconButton'
import { UserType } from '../../../@types/user.ts'
import useAuth from '../../../hooks/useAuth.ts'
import { useRef, useState } from 'react'
import { CustomFormatPopper } from '../../asset-detail/actions/download/CustomFormatPopper.tsx'
import { useImageFormatOptions } from '../../../hooks/useBootstrap.ts'

export function AssetConsumeIconButton({ asset, size = 'small', color = undefined }: {
  asset: SearchAssetResult,
  size?: IconButtonOwnProps['size'],
  color?: IconButtonOwnProps['color']
}) {
  const { user } = useAuth()
  const { consumeAsset } = useDownload()
  const { enabled: customFormatsEnabled } = useImageFormatOptions()

  const handleConsumeClick = () => consumeAsset(asset)

  const isAdmin = user?.userType == UserType.ADMIN
  const downloadDisabled = asset?.isInternal && !isAdmin
  const isImage = asset.assetType == AssetType.IMAGE
  const tooltip =
    downloadDisabled ? 'This asset is for internal use only. To prevent accidental distribution, downloading has been disabled.' :
      asset.storageType == AssetStorageType.INTERNAL_S3 ? 'Download' :
        'Open Link'
  const icon =
    downloadDisabled ? 'ph:lock' :
      asset.storageType == AssetStorageType.INTERNAL_S3 ? 'ph:download' :
        'ph:link-simple-horizontal'

  if (isImage && customFormatsEnabled) return <AssetConsumeCustomFormatIconButton asset={asset} color={color} size={size} />

  return (
    <Tooltip title={tooltip}>
      <Box>
        <IconButton
          onClick={handleConsumeClick}
          size={size}
          color={color}
          disabled={downloadDisabled}
        >
          <Iconify icon={icon} />
        </IconButton>
      </Box>
    </Tooltip>
  )
}

export function AssetConsumeCustomFormatIconButton({ asset, size = 'small', color = undefined }: {
  asset: SearchAssetResult,
  size?: IconButtonOwnProps['size'],
  color?: IconButtonOwnProps['color']
}) {
  const [open, setOpen] = useState(false)

  const anchorRef = useRef<HTMLButtonElement>(null)
  const { user } = useAuth()

  const handleToggle = () => setOpen(prevOpen => !prevOpen)
  const handlePopperClose = () => setOpen(false)

  const isAdmin = user?.userType == UserType.ADMIN
  const downloadDisabled = asset?.isInternal && !isAdmin
  const tooltip = downloadDisabled ?
    'This asset is for internal use only. To prevent accidental distribution, downloading has been disabled.' :
    'Download'
  const icon = downloadDisabled ? 'ph:lock' : 'ph:download'
  return (
    <>
      <>
        <Tooltip title={tooltip}>
          <Box>
            <IconButton
              ref={anchorRef}
              onClick={handleToggle}
              size={size}
              color={color}
              disabled={downloadDisabled}
            >
              <Iconify icon={icon} />
            </IconButton>
          </Box>
        </Tooltip>

        <CustomFormatPopper
          asset={asset}
          open={open}
          anchorRef={anchorRef}
          onClose={handlePopperClose}
          includeOriginal
        />
      </>
    </>
  )
}