import { Stack } from '@mui/material'
import { useContext } from 'react'
import { Range } from 'react-date-range'
import { DateRangeDropdown } from '../../date-range/DateRangeDropdown'
import { ShareAnalyticsContext } from '../../../contexts/ShareAnalyticsContext.tsx'
import ShareAnalyticsPagination from './ShareAnalyticsPagination.tsx'
import { ShareAnalyticsOwnerPicker } from './ShareAnalyticsOwnerPicker.tsx'
import { VisibleForRole } from '../../../guards/VisibleForRole.tsx'
import { UserType } from '../../../@types/user.ts'

export default function ShareAnalyticsFilterBar() {
  return (
    <Stack direction='column' spacing={1}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <DefaultFilters />
        <Stack direction='row' spacing={2}>
          <ShareAnalyticsPagination />
        </Stack>
      </Stack>
    </Stack>
  )
}


function DefaultFilters() {
  // context
  const {
    setDateRange,
    setOwners,
    filters: { boundingStartDate, boundingEndDate, ownerIds },
  } = useContext(ShareAnalyticsContext)

  // handlers
  const handleRangeChange = (range: Range) => setDateRange({ startDate: range.startDate, endDate: range.endDate })
  const handleOwnerChange = (userIds: string[]) => setOwners(userIds)

  // calculated properties
  const defaultRange = { startDate: boundingStartDate, endDate: boundingEndDate, key: 'selection' } as Range

  return (
    <Stack
      direction='row'
      spacing={3}
      alignItems='center'
    >
      <DateRangeDropdown
        onChange={handleRangeChange}
        defaultRange={defaultRange}
      />

      <VisibleForRole roles={[UserType.ADMIN]}>
        <ShareAnalyticsOwnerPicker
          ownerIds={ownerIds}
          onChange={handleOwnerChange}
        />
      </VisibleForRole>
      {/*<AddFilterButton />*/}
    </Stack>
  )
}


