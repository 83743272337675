import { Timeline, timelineContentClasses, timelineItemClasses } from '@mui/lab'
import { Box, Button, Card, CardContent } from '@mui/material'
import { useState } from 'react'
import { TimelineEvent } from '../../../../@types/analytics/timeline-events'
import { ContentRoomTimelineEvent } from './ContentRoomTimelineEvent.tsx'
import { useContentRoomTimeline } from '../../../../hooks/analytics/useShareAnalytics.ts'
import { ContentRoomTimelineDialog } from './ContentRoomTimelineDialog.tsx'

export function ContentRoomTimeline({ contentRoomId }: { contentRoomId: string }) {
  const { data } = useContentRoomTimeline(contentRoomId, 4)
  const [open, setOpen] = useState<boolean>(false)
  const handleSeeMore = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const topEvents = data?.events.slice(0, 4) || []
  return (
    <Card elevation={4}>
      <CardContent>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            [`& .${timelineContentClasses.root}`]: {
              paddingBottom: 1,
            },
          }}
        >
          {
            topEvents.map((it: TimelineEvent) => {
              return (
                <ContentRoomTimelineEvent key={it.createdAt + ''} event={it} />
              )
            })
          }
        </Timeline>
        <Box textAlign='center'>
          <Button variant='text' onClick={handleSeeMore}>See more events...</Button>
        </Box>
        <ContentRoomTimelineDialog contentRoomId={contentRoomId} open={open} onClose={handleDialogClose} />
      </CardContent>
    </Card>
  )
}
