import { createContext, ReactNode, useContext, useState } from 'react'

type State = {
  selectedGroupIds: Set<string>,
}

type Actions = {
  setGroupSelected: (groupId: string, selected: boolean) => void,
  setSelectedGroups: (groupIds: Set<string>) => void,
}

type ContextType = State & Actions

const GroupsTableSelectedItemsContext = createContext<ContextType>({} as ContextType)


export function GroupsTableSelectedItemsContextProvider({ children }: { children: ReactNode }) {
  const [selectedGroupIds, setSelectedGroupIds] = useState<Set<string>>(new Set<string>())

  const addToSet = (set: Set<string>, groupId: string) => new Set([...set, groupId])
  const removeFromSet = (set: Set<string>, groupId: string) => new Set(Array.from(set).filter(it => it != groupId))

  function setGroupSelected(groupId: string, selected: boolean) {
    if (selected) {
      setSelectedGroupIds(prevState => addToSet(prevState, groupId))
    } else {
      setSelectedGroupIds(prevState => removeFromSet(prevState, groupId))
    }
  }

  const setSelectedGroups = (groupIds: Set<string>) => setSelectedGroupIds(groupIds)

  return (
    <GroupsTableSelectedItemsContext.Provider
      value={{
        selectedGroupIds,
        setGroupSelected,
        setSelectedGroups
      }}
    >
      {children}
    </GroupsTableSelectedItemsContext.Provider>
  )
}

export function useGroupsTableSelectedItemsContext() {
  return useContext(GroupsTableSelectedItemsContext)
}
