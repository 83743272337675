import { createContext, useContext, useState } from 'react'

type TaskTableContextType = {
  detailsTaskId: string | null,
  setDetailsTaskId: (taskId: string | null) => void
}

const TaskTableContext = createContext<TaskTableContextType>({} as TaskTableContextType)
export const TaskTableProvider = ({ children }: { children: React.ReactNode }) => {
  const [detailsTaskId, setDetailsTaskId] = useState<string | null>(null)
  return (
    <TaskTableContext.Provider value={{
      detailsTaskId,
      setDetailsTaskId
    }}>
      {children}
    </TaskTableContext.Provider>
  )
}

export function useTaskTableContext(){
  return useContext(TaskTableContext)
}