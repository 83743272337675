import { Asset, AssetStorageType, AssetType } from '../../@types/asset'
import { Box, Button, Divider, Tab } from '@mui/material'
import Iconify from '../Iconify'
import { useDownload } from '../../hooks/useDownload'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import * as React from 'react'
import { useState } from 'react'
import { AssetDetailMetadataTab } from './tabs/AssetDetailMetadataTab'
import { AssetDetailContentGPSTab } from './tabs/AssetDetailContentGPSTab'
import { AssetDetailAITab } from './tabs/AssetDetailAITab'
import AssetDetailAnalyticsTab from './tabs/AssetDetailAnalyticsTab'
import { AssetDetailInformationActions } from './actions/AssetDetailInformationActions'
import useAuth from '../../hooks/useAuth'
import { UserType } from '../../@types/user'
import { AssetDetailAIReadinessTab } from './tabs/AssetDetailAiReadinessTab'
import { useIsFeatureEnabled } from '../../hooks/useFeatures'
import { MassetBotTab } from './tabs/MassetBotTab'
import { ThirdPartyPostfix } from './actions/ThirdPartyPostfix.tsx'
import { AssetDownloadButton } from './actions/download/AssetDownloadButton.tsx'


type AssetDetailInformationProps = {
  asset: Asset
}

export function AssetDetailInformation({ asset }: AssetDetailInformationProps) {
  const { user } = useAuth()
  const [selectedTab, setSelectedTab] = useState('details')
  const { consumeAsset } = useDownload()
  const massetBotEnabled = useIsFeatureEnabled("asset-massetbot")
  const newAITabEnabled = useIsFeatureEnabled("content-extraction")

  // handlers
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue)
  }

  // calculated props
  const isAdmin = user?.userType == UserType.ADMIN
  const downloadDisabled = asset?.isInternal && !isAdmin
  const isExternal = asset.storageType === AssetStorageType.EXTERNAL_HTTP
  const isImage = asset.assetType === AssetType.IMAGE
  return (
    <Box>

      <AssetDownloadButton asset={asset} />

      {isExternal && (
        <Button
          variant='contained'
          startIcon={<Iconify icon={'eva:link-fill'} />}
          fullWidth
          onClick={() => consumeAsset(asset)}
          disabled={downloadDisabled}
        >
          Go to linked Asset
          <ThirdPartyPostfix asset={asset} />
        </Button>
      )}

      <AssetDetailInformationActions asset={asset} />

      <TabContext value={selectedTab}>
        <TabList
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons='auto'
          sx={{ mt: 2 }}
        >
          <Tab label='Details' value='details' />
          <Tab label='Analytics' value='analytics' />
          {isImage && !newAITabEnabled && (<Tab label='AI' value='ai' />)}
          {newAITabEnabled && (<Tab label='Analysis' value='ai' />)}
          {isImage && (<Tab label="Content GPS" value='gps' />)}
          { massetBotEnabled && <Tab label='MassetBot' value='massetbot' /> }
        </TabList>

        <Divider sx={{ mb: 2 }} />

        <TabPanel value='details'>
          <AssetDetailMetadataTab asset={asset} />
        </TabPanel>

        <TabPanel value='analytics'>
          <AssetDetailAnalyticsTab asset={asset} />
        </TabPanel>

        <TabPanel value='ai'>
          { !newAITabEnabled && (<AssetDetailAITab asset={asset} />) }
          { newAITabEnabled && (<AssetDetailAIReadinessTab asset={asset} />) }
        </TabPanel>

        <TabPanel value='massetbot'>
          <MassetBotTab asset={asset} />
        </TabPanel>

        <TabPanel value='gps'>
          <AssetDetailContentGPSTab asset={asset} />
        </TabPanel>
      </TabContext>

      <Box mb={4} />
    </Box>
  )
}
