import { Asset, AssetPermission, AssetStorageType } from '../../../@types/asset'
import { Button, Grid } from '@mui/material'
import Iconify from '../../Iconify'
import { RequestChangeToFileButton } from '../RequestChangeToFileButton'
import { AssetGiveFeedbackButton } from '../AssetGiveFeedbackButton'
import { AssetFlagOutdatedButton } from '../AssetFlagOutdatedButton'
import { useNavigate } from 'react-router-dom'
import { AssetUploadNewVersionButton } from '../AssetUploadNewVersionButton'
import { VisibleForAnyPermission, VisibleForPermission } from '../../../guards/VisibleForPermission'
import { AssetShareButtonV3 } from '../sharing/AssetShareButtonV3.tsx'

export function AssetDetailInformationActions({ asset }: { asset: Asset }) {
  const navigate = useNavigate()
  const isExternal = asset.storageType === AssetStorageType.EXTERNAL_HTTP
  return (
    <Grid
      container
      spacing={1}
      sx={{ mt: 2 }}
    >
      <VisibleForAnyPermission permissions={[AssetPermission.SHARE, AssetPermission.EXTERNAL_SHARE]} level={asset.accessLevel}>
        <Grid item xs={6}>
          <AssetShareButtonV3 asset={asset} />
        </Grid>
      </VisibleForAnyPermission>

      <VisibleForPermission permission={AssetPermission.REQUEST_CHANGE} level={asset.accessLevel}>
        <Grid item xs={6}> <RequestChangeToFileButton asset={asset} /> </Grid>
      </VisibleForPermission>

      <VisibleForPermission permission={AssetPermission.GIVE_FEEDBACK} level={asset.accessLevel}>
        <Grid item xs={6}> <AssetGiveFeedbackButton asset={asset} /> </Grid>
      </VisibleForPermission>

      <VisibleForPermission permission={AssetPermission.FLAG} level={asset.accessLevel}>
        <Grid item xs={6}> <AssetFlagOutdatedButton asset={asset} /> </Grid>
      </VisibleForPermission>

      {/* edit asset button */}
      <VisibleForPermission permission={AssetPermission.EDIT_METADATA} level={asset.accessLevel}>
        <Grid item xs={6}>
          <Button
            variant='outlined'
            fullWidth
            size='small'
            onClick={() => navigate(`/assets/edit/${asset.assetId}`)}
            startIcon={<Iconify icon='eva:edit-outline' />}
          >
            Edit Metadata
          </Button>
        </Grid>
      </VisibleForPermission>

      {!isExternal && (
        <VisibleForPermission permission={AssetPermission.UPLOAD_NEW_VERSION} level={asset.accessLevel}>
          <Grid item xs={6}>
            <AssetUploadNewVersionButton asset={asset} />
          </Grid>
        </VisibleForPermission>
      )}
    </Grid>
  )
}