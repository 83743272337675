import { Avatar, Box, Drawer, Stack, Typography } from '@mui/material'
import { useTaskTableContext } from '../list/TaskTableContext.tsx'
import { alpha, useTheme } from '@mui/material/styles'
import { useTask } from '../../../hooks/useTasks.ts'
import { InlineDescriptionUpdateField } from './InlineDescriptionUpdateField.tsx'
import { InlineNameUpdateField } from './InlineNameUpdateField.tsx'
import { TaskDetailsDrawerHeader } from './TaskDetailsDrawerHeader.tsx'
import { DrawerAssetTag } from './DrawerAssetTag.tsx'
import { DrawerTaskComments } from './DrawerTaskComments.tsx'
import { DrawerAddCommentSection } from './DrawerAddCommentSection.tsx'

export function TaskDetailsDrawer() {
  const theme = useTheme()
  const { detailsTaskId, setDetailsTaskId } = useTaskTableContext()
  const { data: task } = useTask(detailsTaskId)
  const handleClose = () => setDetailsTaskId(null)

  // calculated props
  const open = Boolean(detailsTaskId)
  const createdByName = task?.createdByName || 'Unknown'

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
      variant='temporary'
      PaperProps={{
        sx: {
          background: theme.palette.background.default,
          borderLeft: `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      <Stack
        direction="column"
        sx={{
          height: "100%",
          width: 640,
        }}
      >
        {/* app bar */}
        <TaskDetailsDrawerHeader task={task} />

        {/* scrollable section */}
        <Box sx={{flex: 1, overflowY: "auto"}}>
          <Stack
            direction='column'
            spacing={2}
            p={2}
          >
            <InlineNameUpdateField task={task} />

            {/* Created by */}
            <Stack direction='row' alignItems='center' pl={2}>
              <HorizontalFormLabel label='Created By' />
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Avatar sx={{ height: 24, width: 24, bgcolor: alpha(theme.palette.info.main, .35) }}>
                  <Typography variant="small">{createdByName[0]}</Typography>
                </Avatar>
                <Typography variant='small' noWrap>{createdByName}</Typography>
              </Stack>
            </Stack>

            {/* Asset */}
            <Stack direction='row' alignItems='center' pl={2}>
              <HorizontalFormLabel label='Associated Asset' />
              <Box>
                <DrawerAssetTag taskAsset={task?.assets[0]} />
              </Box>
            </Stack>

          </Stack>

          {/* description */}
          <Box p={2} mt={2}>
            <Box pl={2}><HorizontalFormLabel label='Description' /></Box>
            <InlineDescriptionUpdateField task={task} />
          </Box>

          {/* comments */}
          <Box sx={{px:4, py:2, borderRadius: 0, mt: 4 }}>
            <Typography variant="smallHighlight" mb={2} paragraph>Comments</Typography>
            <DrawerTaskComments task={task} />
          </Box>
        </Box>

        {/* bottom section */}
        <Box sx={{borderTop: `1px solid ${theme.palette.divider}`}}>
          <DrawerAddCommentSection task={task} />
        </Box>
      </Stack>
    </Drawer>
  )
}

function HorizontalFormLabel({ label }: { label: string }) {
  return (
    <Box sx={{ width: 150 }}>
      <Typography variant='smallHighlight'>{label}</Typography>
    </Box>
  )
}

