import {Category, CategoryValue} from "../@types/category";
import api from "@api";

export const createCategory = async (name: string, values: string[]) => {
    const response = await api.post<Category>("/api/v1/categories", { name, values })
    return response.data
}

export async function renameCategory(categoryId: string, name: string) {
    const response = await api.put<Category>(`/api/v1/categories/${categoryId}`, {name})
    return response
}

export const deleteCategory = async (categoryId: string) => {
    const response = await api.delete(`/api/v1/categories/${categoryId}`)
    return response
}

export const createCategoryValues = async (categoryId: string, values: string[]) => {
    const response = await api.post<CategoryValue[]>(`/api/v1/categories/${categoryId}/values/bulk`, { values })
    return response.data
}

export const getCategories = async () => {
    const response = await api.get<Category[]>("/api/v1/categories")
    return response.data
}

export const deleteCategoryValue = async(categoryId: string, categoryValueId: string) => {
    const response = await api.delete(`/api/v1/categories/${categoryId}/values/${categoryValueId}`)
    return response
}

export const reassignCategoryValue = async(categoryValueId: string, originCategoryId: string, destinationCategoryId: string) => {
    const response = await api.post<CategoryValue>(`/api/v1/categories/${originCategoryId}/values/${categoryValueId}/reassign`, {destinationCategoryId})
    return response
}

export async function editCategoryValue(categoryId: string, categoryValueId: string, name: string, description: string | null) {
    const response = await api.put<CategoryValue>(`/api/v1/categories/${categoryId}/values/${categoryValueId}`, {
        value: name,
        description: description,
    })
    return response
}
