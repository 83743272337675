// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
  Typography,
} from '@mui/material';
import { UserType } from "../../../@types/user";
import Iconify from "../../Iconify";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
};

type Props = IProps & RadioGroupProps;

export default function RHFUserTypeSelector({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup {...field} row {...other}>
            <Stack direction="column" spacing={3}>
              <Box>
                <FormControlLabel
                  value={UserType.VIEWER}
                  control={<Radio/>}
                  label="View & Download"
                  componentsProps={{ typography: { color: "primary.main" } }}
                />
                <Permission description="Can view and download assets"/>
              </Box>

              <Box>
                <FormControlLabel
                  value={UserType.CREATOR}
                  control={<Radio/>}
                  label="Upload"
                  componentsProps={{ typography: { color: "primary.main" } }}
                />
                <Box>
                  <Permission description="Can view and download assets"/>
                  <Permission description="Can upload and tag assets"/>
                </Box>
              </Box>

              <Box>
                <FormControlLabel
                  value={UserType.ADMIN}
                  control={<Radio/>}
                  label="Admin"
                  componentsProps={{ typography: { color: "primary.main" } }}
                />
                <Box>
                  <Permission description="Can view and download assets"/>
                  <Permission description="Can upload and tag assets"/>
                  <Permission description="Can manage user roles"/>
                  <Permission description="Can create and delete users"/>
                </Box>
              </Box>
            </Stack>

          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

function Permission({ description }: { description: string }) {
  return (
    <Stack direction="row" spacing={1} ml={4}>
      <Iconify icon={"eva:checkmark-fill"}/>
      <Typography variant="small" color="text.secondary">{description}</Typography>
    </Stack>
  )
}