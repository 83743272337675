import { styled } from '@mui/material/styles'
import { DateRangePicker } from 'react-date-range'
import { darken } from '@mui/material'

export const StyledDateRangePicker = styled(DateRangePicker)(({ theme }) => {
  const isLight = theme.palette.mode === 'light'
  const borderColor = isLight ? 'transparent' : theme.palette.grey[500_24]
  const baseBackgroundColor = 'transparent'
  const fontColor = theme.palette.text.primary
  // return {}
  return {
    backgroundColor: baseBackgroundColor,

    // ranges wrapper
    '.rdrDefinedRangesWrapper': {
      backgroundColor: baseBackgroundColor,
      borderColor: borderColor
    },
    '.rdrStaticRangeSelected': {
      'color': `${theme.palette.primary.main}!important`
    },
    // range item
    '.rdrStaticRange': {
      backgroundColor: baseBackgroundColor,
      borderColor: borderColor,
      color: fontColor,

      '&:hover .rdrStaticRangeLabel': {
        background: theme.palette.action.hover
      }
    },

    // calendar wrapper
    '.rdrCalendarWrapper': {
      backgroundColor: baseBackgroundColor
    },

    // header date select
    '.rdrMonthAndYearPickers': {
      'select': {
        background: `url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='${theme.palette.text.primary}' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;`,
        backgroundPosition: 'right 8px center',
        color: theme.palette.text.primary,
      },
    },

    // prev/next buttons
    '.rdrNextPrevButton': {
      background: baseBackgroundColor,
      border: 'none',
      borderRadius: '50%',
      '&:hover': {
        background: theme.palette.action.hover
      },
      '&.rdrPprevButton i': {
        // chevrons
        borderRightColor: theme.palette.action.active
      },
      '&.rdrNextButton i': {
        // chevrons
        borderLeftColor: theme.palette.action.active
      }
    },

    // passive days (other month days at start/end of focus)
    '.rdrDayPassive .rdrDayNumber span':{
      color: darken(theme.palette.text.deemphasized, .2)
    },

    // disabled days (not selectable)
    '.rdrDayDisabled': {
      backgroundColor: darken(theme.palette.background.paper, .1),
      '.rdrDayNumber span': {
        color: theme.palette.text.deemphasized
      }
    },

    // standard days
    '.rdrDayNumber span': {
      color: theme.palette.text.primary
    }
  }
})
