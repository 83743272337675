import { Select, SelectChangeEvent, Stack } from '@mui/material'
import { ShareLevel } from '../../../../@types/sharing'
import Iconify from '../../../Iconify'
import { useShareField } from './AssetShareFieldHooks'
import { InternalFormShareItem } from './AssetShareField'
import { ShareItemDisplayInfo } from './ShareItemDisplayInfo'
import { SharingHoverableItem } from './SharingHoverableItem'
import { useAssetPermissionsDefaults } from './AssetPermissionsDefaultsContext.tsx'
import { useState } from 'react'

export function ShareItem({ item }: { item: InternalFormShareItem }) {
  const { removeItem, changeShareLevel } = useShareField()
  const { originalSharesById } = useAssetPermissionsDefaults()

  // mixed is determined by whether the initial server-provided value was mixed
  const originalShare = originalSharesById[item.uniqueId]
  const allowMixed = originalShare?.shareLevel == ShareLevel.MIXED
  const [canSelectMixed, setCanSelectMixed] = useState<boolean>(allowMixed)

  // handlers
  const handleRemove = () => removeItem(item)
  const handleShareLevelChange = (ev: SelectChangeEvent<ShareLevel>) => {
    setCanSelectMixed(false)
    changeShareLevel(item, parseInt(ev.target.value + '', 10))
  }

  return (
    <SharingHoverableItem>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <ShareItemDisplayInfo share={item.share} />
        <Stack direction='row' spacing={1} alignItems='center'>
          <Select
            size='small'
            native={true}
            value={item.shareLevel}
            onChange={handleShareLevelChange}
            inputProps={{
              sx: {
                fontSize: 12,
                lineHeight: 1,
              },
            }}
          >
            { canSelectMixed && (<option value={ShareLevel.MIXED}>Mixed Roles</option>) }
            <option value={ShareLevel.OWNER}>Owner</option>
            <option value={ShareLevel.EDIT}>Editor</option>
            <option value={ShareLevel.VIEW}>Viewer</option>
          </Select>
          <Iconify
            icon='eva:close-outline'
            onClick={handleRemove}
            sx={{ cursor: 'pointer' }}
          />
        </Stack>

      </Stack>
    </SharingHoverableItem>
  )
}

