import { createContext, ReactNode, useContext, useState } from 'react'

type State = {
  selectionModeEnabled: boolean,
  selectedAssetIds: Set<string>,
}


type Actions = {
  setSelectionModeEnabled: (enabled: boolean) => void
  setAssetSelected: (assetId: string, selected: boolean) => void
  setSelectedAssets: (assetIds: Set<string>) => void
}

type ContextType = State & Actions

const SearchSelectedAssetsContext = createContext<ContextType>({} as ContextType)


export function SearchSelectedAssetsContextProvider({ children }: { children: ReactNode }) {
  const [selectionModeEnabled, setSelectionModeEnabled] = useState<boolean>(false)
  const [selectedAssetIds, setSelectedAssetIds] = useState<Set<string>>(new Set<string>())

  const addToSet = (set: Set<string>, groupId: string) => new Set([...set, groupId])
  const removeFromSet = (set: Set<string>, groupId: string) => new Set(Array.from(set).filter(it => it != groupId))

  function setAssetSelected(assetId: string, selected: boolean) {
    if (selected) {
      setSelectedAssetIds(prevState => addToSet(prevState, assetId))
    } else {
      setSelectedAssetIds(prevState => removeFromSet(prevState, assetId))
    }
  }

  const setSelectedAssets = (groupIds: Set<string>) => setSelectedAssetIds(groupIds)

  return (
    <SearchSelectedAssetsContext.Provider
      value={{
        selectionModeEnabled,
        selectedAssetIds,
        setAssetSelected,
        setSelectionModeEnabled,
        setSelectedAssets
      }}
    >
      {children}
    </SearchSelectedAssetsContext.Provider>
  )
}

export function useSearchSelectedAssetsContext() {
  return useContext(SearchSelectedAssetsContext)
}
