// form
import { Controller, useFormContext } from 'react-hook-form'
// @mui
import {
  Box,
  darken,
  InputAdornment, lighten,
  Popover,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material'
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import * as React from 'react';
import { HexColorPicker } from 'react-colorful'

// ----------------------------------------------------------------------

type IProps = {
  name: string;
};

type Props = IProps & TextFieldProps;

export default function RHFColorField({ name, ...other }: Props) {
  // hooks
  const { control } = useFormContext()

  // render comp
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            {...field}
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            error={!!error}
            helperText={error?.message}
            {...other}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Color color={field.value} onChange={field.onChange} />
                    <Typography variant='h4'>#</Typography>
                  </Stack>
                </InputAdornment>
              ),
              inputComponent: ColorInput
            }}
          />
        )}
      }
    />
  )
}



function Color({ color, onChange }: { color: string, onChange: (...event: any[]) => void; }) {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  // handlers
  const handleOpen = (e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)

  // calculated props
  const open = Boolean(anchorEl)
  const id = open ? `color-picker` : undefined
  const borderColor = theme.palette.mode == 'light' ? darken(theme.palette.divider, .5) : lighten(theme.palette.divider, .5)

  return (
    <>
      <Box
        sx={{
          cursor: 'pointer',
          width: '36px',
          height: '18px',
          border: `1px solid ${borderColor}`,
          borderRadius: .5,
          background: color,
        }}
        onClick={handleOpen}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <HexColorPicker
          color={color}
          onChange={onChange}
        />
      </Popover>
    </>
  )
}

const escape =
  (value: string) => value.replace(/([^0-9A-F]+)/gi, '').substring(0, 6)

const validHex = (value: string): boolean => {
  const matcher = /^#?([0-9A-F]{3,8})$/i
  const match = matcher.exec(value)
  const length = match ? match[1].length : 0
  return (
    length === 3 || // '#rgb' format
    length === 6  // '#rrggbb' format
  )
}

export const ColorInput = forwardRef((props: React.InputHTMLAttributes<HTMLInputElement>, ref: ForwardedRef<any>) => {
  const { value = '', onChange, onBlur } = props
  const [innerValue, setInnerValue] = useState(() => escape(value + ''));

  // Trigger `onChange` handler only if the input value is a valid color
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = escape(e.target.value)
    setInnerValue(inputValue);
    if (validHex(inputValue) && onChange) {
      e.target.value = "#" + inputValue
      onChange(e)
    }
  }

  // Take the color from props if the last typed color (in local state) is not valid
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!validHex(e.target.value)) setInnerValue(escape(value + ""))
    onBlur && onBlur(e)
  }

  useEffect(() => {
    setInnerValue(escape(value + ""));
  }, [value, escape]);

  return (
    <input
      {...props}
      ref={ref}
      spellCheck='false'
      onChange={handleChange}
      onBlur={handleBlur}
      value={innerValue}
    />
  )
})