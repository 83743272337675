import { createContext, ReactNode, useContext, useMemo } from 'react'
import { InternalFormShareItem } from './AssetShareField.tsx'
import keyBy from 'lodash/keyBy'
import { GeneralAccessType, ShareLevel } from '../../../../@types/sharing.ts'

type IAssetShareFieldContext = {
  originalShares: InternalFormShareItem[],
  originalSharesById: Dictionary<InternalFormShareItem>,
  originalAccessType: GeneralAccessType,
  originalAccessShareLevel: ShareLevel
}

const AssetPermissionsDefaultsContext = createContext<IAssetShareFieldContext>({} as IAssetShareFieldContext)

export function AssetPermissionsDefaultsContextProvider({
                                                          shares,
                                                          generalAccessType,
                                                          generalAccessShareLevel,
                                                          children,
                                                        }: {
  shares: InternalFormShareItem[],
  generalAccessType: GeneralAccessType,
  generalAccessShareLevel: ShareLevel,
  children: ReactNode
}) {
  const originalSharesById = useMemo(() => keyBy(shares, 'uniqueId'), [shares])
  return (
    <AssetPermissionsDefaultsContext.Provider
      value={{
        originalShares: shares,
        originalSharesById,
        originalAccessType: generalAccessType,
        originalAccessShareLevel: generalAccessShareLevel,
      }}
    >
      {children}
    </AssetPermissionsDefaultsContext.Provider>
  )
}

export function useAssetPermissionsDefaults() {
  return useContext(AssetPermissionsDefaultsContext)
}