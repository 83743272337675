import { Stack } from '@mui/material'
import { TaskListTopBar } from '../../components/tasks/list/TaskListTopBar.tsx'
import { TaskListTable } from '../../components/tasks/list/TaskListTable.tsx'

export function TasksList() {

  return (
    <Stack spacing={2}>
      <Stack
        direction='row'
        justifyContent='space-between'
      >
        <TaskListTopBar />
      </Stack>

      <TaskListTable />
    </Stack>
  )
} 