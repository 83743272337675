import { Box, Button, Card, CardHeader, Dialog, Stack, Typography } from '@mui/material'
import orderBy from 'lodash/orderBy'
import Image from '../../../Image'
import { useState } from 'react';
import { fShortenNumber } from '../../../../utils/formatNumber'
import { alpha, styled } from '@mui/material/styles'
import { srcForPreviewItem } from '../../../asset-search/images'
import { AssetPreviewType } from '../../../../@types/asset'
import Iconify from '../../../Iconify'
import { TopAssetForUser } from '../../../../@types/analytics/analytics-user'
import { useUserTopAssets } from '../../../../hooks/analytics/useUserAnalytics'
import { Link } from 'react-router-dom'


export function UserTopAssets({ userId }: { userId: string }) {
  const { data } = useUserTopAssets(userId)
  const [open, setOpen] = useState<boolean>(false)

  const handleSeeAllClick = () => setOpen(true)
  const handleOnDialogClose = () => setOpen(false)

  const allResults = data?.results || []
  const moreResults = allResults.length >= 4
  const list = allResults.slice(0, 6)

  return (
    <Card elevation={4}>
      <CardHeader title='Top Assets' subheader='With which assets did this user most frequently interact?' />
      <Stack spacing={2} sx={{ p: 2 }}>
        {
          orderBy(list, ['totalInteractions'], ['desc']).map((it, idx) => {
            return (
              <AsestItem key={it.assetId} assetItem={it} index={idx} />
            )
          })
        }
        {
          list && !Boolean(list.length) && (
            <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
              <Image
                disabledEffect
                visibleByDefault
                alt='empty content'
                src='/assets/illustrations/illustration_empty_content.svg'
                sx={{ height: 48, my: 1 }}
              />
              <Typography>No Results</Typography>
            </Stack>
          )
        }
        {moreResults && (
          <Box textAlign='center'>
            <Button
              variant='text'
              onClick={handleSeeAllClick}
              fullWidth={false}
            >
              See all
            </Button>
            <UserTopAssetsDialog topAssets={allResults} open={open} onClose={handleOnDialogClose} />
          </Box>
        )}
      </Stack>
    </Card>
  )
}

function AsestItem({ assetItem, index }: { assetItem: TopAssetForUser, index: number }) {
  const imgSrc = srcForPreviewItem(assetItem.assetPreviewId, AssetPreviewType.THUMBNAIL_SM)
  return (
    <Link to={`/assets/detail/${assetItem.assetId}`} style={{textDecoration: "none", color: "inherit"}} >
      <Stack direction='row' alignItems='center' spacing={2}>
        <PreviewContainer>
          <Image alt={assetItem.name} src={imgSrc} ratio='1/1' backgroundType='contain' />
        </PreviewContainer>

        <Box sx={{ flexGrow: 1 }}>
          <Typography variant='subtitle2' color="inherit">{assetItem.name}</Typography>
        </Box>

        <Typography
          variant='caption'
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'text.secondary',
          }}
        >
          {fShortenNumber(assetItem.totalInteractions)}&nbsp;interactions
        </Typography>

        <PlaceIcon place={index + 1} />
      </Stack>
    </Link>
  )
}

const PreviewContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.bg.warmGreyLight : theme.palette.background.neutral,
  borderRadius: 8,
  overflow: 'hidden',
  height: 36,
  width: 36,
}))

const DEFAULT_TROPHY_COLOR = '#555'
const TROPHY_COLORS = {
  1: '#F7BA00',
  2: '#D5D5D5',
  3: '#CD7F32',
} as { [key: number]: string }


function PlaceIcon({ place }: { place: number }) {
  const color = TROPHY_COLORS[place] || DEFAULT_TROPHY_COLOR
  return (
    <Iconify
      icon='solar:cup-star-bold'
      sx={{
        p: 1,
        width: 32,
        height: 32,
        borderRadius: '50%',
        color: color,
        bgcolor: alpha(color, 0.08),
      }}
    />
  )
}

export function UserTopAssetsDialog({ topAssets, open, onClose }: {
  topAssets: TopAssetForUser[],
  open: boolean,
  onClose: () => void
}) {
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullWidth={true}
      onClose={onClose}
    >
      <Stack spacing={2} sx={{ p: 4 }}>
        {
          orderBy(topAssets, ['totalInteractions'], ['desc']).map((it, idx) => {
            return (
              <AsestItem key={it.assetId} assetItem={it} index={idx} />
            )
          })
        }
      </Stack>
    </Dialog>
  )
}