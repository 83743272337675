import { Button } from '@mui/material'
import { Task, TaskClosedReason } from '../../../@types/task.ts'
import Iconify from '../../Iconify.tsx'
import { useSetTaskClosed } from '../../../hooks/useTasks.ts'

export function CompleteTaskButton({ task }: { task?: Task }) {
  // hooks
  const { mutateAsync: setTaskClosed } = useSetTaskClosed()

  // handlers
  const handleOnClick = () => {
    const currentlyClosed = task!!.isClosed
    const reason = currentlyClosed ? null : TaskClosedReason.COMPLETE
    setTaskClosed({ taskId: task!!.taskId, isClosed: !currentlyClosed, closedReason: reason })
  }

  // calculated props
  const color = task?.isClosed ? 'success' : 'inherit'
  const text = task?.isClosed ? 'Completed' : 'Mark Completed'


  return (
    <Button
      variant='outlined'
      size='small'
      color={color}
      startIcon={<Iconify icon='ph:check' />}
      onClick={handleOnClick}
    >
      {text}
    </Button>
  )
}
