import { useFieldArray, useFormContext } from 'react-hook-form'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import Iconify from '../../Iconify.tsx'
import { CSS } from '@dnd-kit/utilities'
import { RHFTextField } from '../../hook-form'
import type { DragEndEvent } from '@dnd-kit/core/dist/types'

export function RHFSizesField({ name }: { name: string }) {
  const { control } = useFormContext()
  const { fields, append, remove, move } = useFieldArray({ name, control })
  const sensors = useSensors(useSensor(PointerSensor))

  const handleAddSize = () => append({ name: '', height: '', width: '' })
  const handleRemove = (idx: number) => remove(idx)
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (active.id !== over?.id) {
      const oldIndex = fields.findIndex((item) => item.id === active.id)
      const newIndex = fields.findIndex((item) => item.id === over?.id)
      move(oldIndex, newIndex)
    }
  }

  return (
    <Stack direction='column' spacing={1}>
      <Table size='small'>
        <TableHead>
          <TableCell></TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Height</TableCell>
          <TableCell>Width</TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody>
          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={fields.map(field => field.id)} strategy={verticalListSortingStrategy}>
              {fields.map((field, idx) => {
                return (
                  <DraggableRow
                    key={field.id}
                    index={idx}
                    name={name}
                    row={field}
                    onRemove={handleRemove}
                  />
                )
              })}
            </SortableContext>
          </DndContext>
        </TableBody>
      </Table>

      <Button
        variant='text'
        startIcon={<Iconify icon='ph:plus' />}
        onClick={handleAddSize}
      >
        Add new size
      </Button>
    </Stack>
  )
}

type DraggableRowProps = {
  name: string,
  row: Record<'id', string>,
  index: number,
  onRemove?: (idx: number) => void
}

function DraggableRow({ name, row, index, onRemove }: DraggableRowProps) {
  const { listeners, setNodeRef, transform, transition } =
    useSortable({ id: row.id })
  const handleRemove = () => onRemove && onRemove(index)
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <TableRow ref={setNodeRef} style={style}>
      <TableCell sx={{p: 0, verticalAlign: "center"}}>
        <Iconify icon='ph:dots-six-vertical' {...listeners}  sx={{cursor: "grab"}} width={16} height={16} />
      </TableCell>
      <TableCell>
        <RHFTextField
          name={`${name}.${index}.name`}
          placeholder={'Enter a name...'}
          size='small'
        />
      </TableCell>
      <TableCell>
        <RHFTextField
          name={`${name}.${index}.height`}
          placeholder={'Enter a height...'}
          type='number'
          size='small'
        />
      </TableCell>
      <TableCell>
        <RHFTextField
          name={`${name}.${index}.width`} type='number'
          placeholder={'Enter a width...'}
          size='small'
        />
      </TableCell>
      <TableCell>
        <IconButton
          size='small'
          onClick={handleRemove}
        >
          <Iconify icon='ph:x' />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}