import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { USER_TYPE_DISPLAY_VALUE, UserSortBy, UserStatus } from '../../@types/user'
import Iconify from '../../components/Iconify'
import { UserDeleteButton } from '../../components/admin/user/UserDeleteButton'
import { useNavigate } from 'react-router-dom'
import Page from '../../components/Page'
import { DEFAULT_USER_SEARCH_DATA, useAdminUserSearch } from '../../hooks/useUsers'
import { ChangeEvent, useState } from 'react'
import Pagination from '../../components/pagination/Pagination'
import { visuallyHidden } from '@mui/utils'
import { useDebounceValue } from 'usehooks-ts'
import { ManualSCIMSyncButton } from '../../components/admin/user/ManualSCIMSyncButton.tsx'
import { UserListScimAlertBanner } from '../../components/admin/user/UserListScimAlertBanner.tsx'
import { useIsScimEnabled } from '../../hooks/useScim.ts'
import { AdminUsersActionBar } from '../../components/admin/user/AdminUsersActionBar.tsx'
import { UserTableRowUserGroups } from '../../components/admin/user/table/UserTableRowUserGroups.tsx'

const ADMIN_USERS_PAGE_SIZE = 50
const DEFAULT_SORT = {
  sortBy: UserSortBy.NAME,
  direction: 'asc',
} as Sort

export default function AdminUsers() {
  const [page, setPage] = useState<number>(1)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [sort, setSort] = useState<Sort>(DEFAULT_SORT)
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set())
  const navigate = useNavigate()
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 200)
  const scimEnabled = useIsScimEnabled()
  const { data } = useAdminUserSearch({
    term: debouncedSearchTerm,
    sortBy: sort.sortBy,
    sortDirection: sort.direction,
    limit: ADMIN_USERS_PAGE_SIZE,
    page,
  })
  const results = (data || DEFAULT_USER_SEARCH_DATA)
  const users = results.users
  const totalResultCount = results.totalResultCount

  //handlers
  const handleSearchTermChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setPage(1)
    setSearchTerm(ev.target.value)
    setSelectedItems(new Set())
  }
  const handleSearchTermClear = () => {
    setPage(1)
    setSearchTerm('')
    setSelectedItems(new Set())
  }
  const handlePageChange = (pageNum: number) => {
    setPage(pageNum + 1)
    setSelectedItems(new Set())
  }
  const handleSortChange = (sortBy: UserSortBy, direction: 'asc' | 'desc') => {
    setPage(1)
    setSort({ sortBy, direction })
    setSelectedItems(new Set())
  }
  const handleIndividualUserSelect = (userId: string) => {
    setSelectedItems((prevState) => {
      const oldItems = Array.from(prevState)
      const alreadyPresent = prevState.has(userId)
      if(alreadyPresent){
        return new Set(oldItems.filter(it => it != userId))
      }else{
        return new Set([...oldItems, userId])
      }
    })
  }
  const handleHeaderCheckboxClick = () => {
    const isHeaderCheckboxActive = selectedItems.size > 0

    // if everything is selected, then deselect everything
    if(isHeaderCheckboxActive){
      setSelectedItems(new Set())
    }
    // otherwise, select everything
    else{
      setSelectedItems(new Set(users.map(it => it.userId)))
    }
  }
  const handleBulkActionCompleted = () => {
    setSelectedItems(new Set())
  }

  const createAllowed = !scimEnabled
  const disabledButtonTooltip = scimEnabled ? 'Blocked because external user syncing is enabled.' : null
  const isHeaderCheckboxIndeterminate = selectedItems.size > 0 && selectedItems.size != users.length
  const isHeaderCheckboxSelected = selectedItems.size > 0 && selectedItems.size == users.length

  return (
    <Page title='Users'>
      <Stack direction='column' spacing={2}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='h2'>User Management</Typography>
          <Stack direction="row" spacing={1}>
            <Tooltip title={disabledButtonTooltip}>
              <Box>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  startIcon={<Iconify icon={'eva:plus-outline'} />}
                  onClick={() => navigate('/admin/users/new')}
                  disabled={!createAllowed}
                >
                  Add New User
                </Button>
              </Box>
            </Tooltip>
            <ManualSCIMSyncButton />
          </Stack>

        </Stack>
        <UserListScimAlertBanner />
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <TextField
            size='small'
            onChange={handleSearchTermChange}
            placeholder='Search...'
            value={searchTerm}
            sx={{ width: 360 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Iconify icon='eva:search-outline' />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position='end'>
                  <Iconify
                    icon='eva:close-fill'
                    onClick={handleSearchTermClear}
                    sx={{ cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Pagination
            currentPage={page - 1}
            pageSize={ADMIN_USERS_PAGE_SIZE}
            totalResultCount={totalResultCount}
            onPageChange={handlePageChange}
            scrollOnPageChange={false}
          />
        </Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{width: 50}}>
                <Checkbox
                  size="small"
                  sx={{p: 0}}
                  indeterminate={isHeaderCheckboxIndeterminate}
                  checked={isHeaderCheckboxSelected}
                  onClick={handleHeaderCheckboxClick}
                />
              </TableCell>
              <SortableTableHeader
                label='Name'
                col={UserSortBy.NAME}
                curSort={sort}
                onSortChange={handleSortChange}
              />
              <TableCell>Groups</TableCell>
              <SortableTableHeader
                label='Role'
                col={UserSortBy.ROLE}
                curSort={sort}
                onSortChange={handleSortChange}
                cellSx={{ width: 125 }}
              />
              <SortableTableHeader
                label='Status'
                col={UserSortBy.STATUS}
                curSort={sort}
                onSortChange={handleSortChange}
                cellSx={{ width: 75 }}
              />
              <TableCell sx={{width: 100}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              users.map(user => {
                return (
                  <TableRow key={user.userId}>
                    <TableCell
                      sx={{cursor: "pointer"}}
                      onClick={() => handleIndividualUserSelect(user.userId)}
                    >
                      <Checkbox
                          size="small"
                          sx={{p: 0}}
                          checked={selectedItems.has(user.userId)}
                        />
                    </TableCell>
                    <TableCell>
                      <Stack>
                        <Typography
                          variant='smallHighlight'>{user.firstName} {user.lastName}</Typography>
                        <Typography variant="small" color="text.deemphasized">{user.email}</Typography>
                      </Stack>
                      </TableCell>
                    <TableCell><UserTableRowUserGroups user={user} /></TableCell>
                    <TableCell>{USER_TYPE_DISPLAY_VALUE[user.userType]}</TableCell>
                    <TableCell><UserStatusCmp status={user.status} /></TableCell>
                    <TableCell>
                      <Stack
                        spacing={1}
                        direction='row'
                        justifyContent='end'
                      >
                        <IconButton
                          size='small'
                          color='primary'
                          onClick={() => navigate(`/admin/users/${user.userId}`)}
                        >
                          <Iconify color='primary.main' icon='eva:edit-outline' />
                        </IconButton>
                        <UserDeleteButton user={user} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
        <Pagination
          currentPage={page - 1}
          pageSize={ADMIN_USERS_PAGE_SIZE}
          totalResultCount={totalResultCount}
          onPageChange={handlePageChange}
          scrollOnPageChange={false}
        />
      </Stack>
      <AdminUsersActionBar
        selectedItems={selectedItems}
        onActionCompleted={handleBulkActionCompleted}
      />
    </Page>
  )
}


function UserStatusCmp({ status }: { status: UserStatus }) {
  if (status === UserStatus.INVITED) {
    return (
      <Stack direction='row' spacing={1}>
        <Typography variant='small' color='text.deemphasized'>Invited</Typography>
      </Stack>
    )
  }

  if (status === UserStatus.ACTIVE) {
    return (
      <Typography variant='small' color='text.deemphasized'>Active</Typography>
    )
  }

  return <></>
}


interface Sort {
  sortBy: UserSortBy
  direction: 'asc' | 'desc'
}

interface SortableTableCellProps {
  label: string,
  col: UserSortBy,
  curSort: Sort,
  onSortChange: (sortBy: UserSortBy, direction: 'asc' | 'desc') => void,
  cellSx?: object
}

function SortableTableHeader({ label, col, curSort, onSortChange, cellSx }: SortableTableCellProps) {
  const isActive = curSort.sortBy === col

  const handleSortChange = () => {
    const opposite = curSort.direction === 'asc' ? 'desc' : 'asc'
    const newDirection = curSort.sortBy === col ? opposite : 'asc'
    onSortChange(col, newDirection)
  }

  return (
    <TableCell sx={cellSx}>
      <TableSortLabel
        active={isActive}
        direction={isActive ? curSort.direction : 'asc'}
        onClick={handleSortChange}
      >
        {isActive ? (
          <Box component='span' sx={visuallyHidden}>
            {curSort.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
        <Typography variant='small'>{label}</Typography>
      </TableSortLabel>
    </TableCell>
  )
}