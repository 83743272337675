import { useBootstrap } from '../../hooks/useBootstrap.ts'
import MassetLogoFull from '../MassetLogoFull.tsx'
import { Box, Stack, Typography } from '@mui/material'
import { useMemo } from 'react'

export function CompanyLogo() {
  const { data: bootstrap, isLoading } = useBootstrap()
  const logoId = bootstrap?.branding.logo.logoId
  const company = useMemo(() => {
    const availableCompanies = bootstrap?.companies || []
    return availableCompanies.length === 1 ? availableCompanies[0] :
      availableCompanies.find(cmp => cmp.companyId === bootstrap?.activeCompanyId)
  }, [bootstrap])
  if (isLoading) return <></>
  if (!logoId) return <MassetLogoFull sx={{ height: 24 }} />
  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Box
        sx={{ width: 24, height: 24, borderRadius: '15%' }}
        component='img'
        src={`/api/v1/bootstrap/logo/${logoId}`}
      />
      <Typography variant='standard' >{company?.name || ""}</Typography>
    </Stack>
  )
}