import { GeneralAccessType, ShareLevel } from './sharing'

export type Department = {
  departmentId: string,
  name: string
}

export const HARD_CODED_DEPARTMENTS = [
  { departmentId: '1', name: 'Marketing' },
  { departmentId: '2', name: 'Sales' },
  { departmentId: '3', name: 'Engineering' },
  { departmentId: '4', name: 'Product' },
  { departmentId: '5', name: 'Customer Success' },
]

export type CompanyInfoResponse = {
  name: string,
  websiteUrl: string | null,
  facebookUrl: string | null,
  instagramUrl: string | null,
  linkedInUrl: string | null,
  twitterUrl: string | null,
  domains: DomainClaim[]
}

export type UpdateCompanyInfoResponse = CompanyInfoResponse
export type UpdateCompanyInfoRequest = {
  name: string,
  websiteUrl: string | null,
  facebookUrl: string | null,
  instagramUrl: string | null,
  linkedInUrl: string | null,
  twitterUrl: string | null,
}

export type DomainClaim = {
  domainClaimId: string,
  domain: string,
  autoRegisterEnabled: boolean,
  claimStatus: DomainClaimStatus
}

export enum DomainClaimStatus {
  VERIFIED = 1,
  UNVERIFIED = 2,
  PENDING = 3,
  FAILED = 4
}

export type CompanyBrandResponse = {
  primary: string | null,
  secondary: string | null,
  applyToInternal: boolean,
  applyToContentRooms: boolean
}

export type UpdateCompanyBrandRequest = {
  primary: string | null,
  secondary: string | null,
  applyToInternal: boolean,
  applyToContentRooms: boolean
}

export type CompanySharingSettingsResponse = {
  defaultAccessType: GeneralAccessType
  defaultShareLevel: ShareLevel
}

export type ImageFormatSettingsResponse = {
  applyToInternal: boolean,
  permittedFormats: ImageDownloadFileType[],
  permittedSizes:  ImageDownloadSize[]
}

export type UpdateImageFormatSettingsRequest = {
  applyToInternal: boolean,
  permittedFormats: ImageDownloadFileType[],
  permittedSizes:  ImageDownloadSize[]
}

export type UpdateImageFormatSettingsResponse = ImageFormatSettingsResponse


export enum ImageDownloadFileType {
  WEBP = 1,
  PNG = 2,
  JPG = 3
}

export type ImageDownloadSize = {
  imageFormatSizeId: string,
  name: string
  height: number,
  width: number,
  displayOrder: number
}

export const DOWNLOAD_FORMAT_LABELS = {
 [ImageDownloadFileType.JPG] : "JPG",
 [ImageDownloadFileType.PNG] : "PNG",
 [ImageDownloadFileType.WEBP] : "WebP",
}