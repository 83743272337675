import { DOWNLOAD_FORMAT_LABELS, ImageDownloadFileType } from '../../../../@types/company.ts'
import { useImageFormatOptions } from '../../../../hooks/useBootstrap.ts'
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'

type ImageFormatFileTypeSelectProps = {
  value: ImageDownloadFileType,
  labelId?: string,
  onChange?: (fileType: ImageDownloadFileType) => void
  onOpen?: () => void
  onClose?: () => void
}

export function ImageFormatFileTypeSelect({
                                            value,
                                            labelId,
                                            onChange,
                                            onOpen,
                                            onClose,
                                          }: ImageFormatFileTypeSelectProps) {
  const { fileTypes } = useImageFormatOptions()

  const handleFileTypeChange = (ev: SelectChangeEvent) => {
    onChange && onChange(parseInt(ev.target.value, 10) as ImageDownloadFileType)
  }
  const handleSelectOpen = () => onOpen && onOpen()
  const handleSelectClose = () => onClose && onClose()

  return (
    <Select
      id='file-type'
      labelId={labelId}
      size='xs'
      variant='outlined'
      sx={{ minWidth: 100 }}
      value={value + ""}
      onChange={handleFileTypeChange}
      onOpen={handleSelectOpen}
      onClose={handleSelectClose}
      renderValue={(selected: string) => {
        const format = parseInt(selected, 10)
        return <Typography
          variant='inherit'>{DOWNLOAD_FORMAT_LABELS[format as ImageDownloadFileType]}</Typography>
      }}
    >
      {fileTypes.map(it => {
        return (
          <MenuItem key={it} value={it}>{DOWNLOAD_FORMAT_LABELS[it]}</MenuItem>
        )
      })}
    </Select>
  )
}